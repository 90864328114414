<template>
	<div class="box is-paddingless">
		<neko-data-list ref="dataList" :data="getData" :options="dataListOptions">
			<template #default="{ element }">
				<td>{{ element.inseeCode }}</td>
				<td>{{ element.name }}</td>
				<td>{{ element.postalCode }}</td>
				<td>{{ element.line5 }}</td>
				<td>{{ element.routingLabel }}</td>
				<td><neko-coordinates :coordinates="element.coordinates" /></td>
				<td>{{ element.dateFrom | date }}</td>
			</template>
		</neko-data-list>
	</div>
</template>

<script lang="ts">
import { DataListOptions, DataListParams } from "@emasofts/common-vuejs-datalist";
import { Component, Ref, Vue } from "vue-property-decorator";

import { ListResponseSimpleCityRepresentation, SimpleCityRepresentation } from "@/lib/apis/models";

import CitiesApi from "@/lib/apis/operations/CitiesResourceApi";

import { Coordinates as NekoCoordinates } from "@/components/coordinates";
import { DataList as NekoDataList } from "@/components/dataList";

@Component({
	components: {
		NekoCoordinates,
		NekoDataList
	}
})
export default class CityList extends Vue {
	@Ref()
	private readonly dataList!: NekoDataList<SimpleCityRepresentation>;

	private dataListOptions: DataListOptions = {
		columns: [
			{
				name: this.$t("column.inseeCode") as string,
				data: "inseeCode",
				orderable: true,
				dir: "asc"
			},
			{
				name: this.$t("column.name") as string,
				data: "name",
				orderable: true
			},
			{
				name: this.$t("column.postalCode") as string,
				data: "postalCode",
				orderable: true
			},
			{
				name: this.$t("column.line5") as string,
				data: "line5",
				orderable: true
			},
			{
				name: this.$t("column.routingLabel") as string,
				data: "routingLabel",
				orderable: true
			},
			{
				name: this.$t("column.coordinates") as string,
				data: "coordinates"
			},
			{
				name: this.$t("column.dateFrom") as string,
				data: "dateFrom",
				orderable: true
			}
		],
		displayLength: [50, 100, 500, 1000, 5000],
		displayLengthDefault: 100
	};

	private async getData(params: DataListParams): Promise<ListResponseSimpleCityRepresentation> {
		return CitiesApi.list(
			params.draw,
			params.start,
			params.length,
			params.search,
			params.order,
			params.filter);
	}
}
</script>

<i18n>
{
	"fr": {
		"column.inseeCode": "Code INSEE",
		"column.name": "Nom",
		"column.postalCode": "Code postal",
		"column.line5": "Ligne 5",
		"column.routingLabel": "Libellé d'acheminement",
		"column.coordinates": "Coordonnées",
		"column.dateFrom": "Mise en vigueur"
	}
}
</i18n>
