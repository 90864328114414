import router from "@/lib/router";

export default {
	install() {
		router.addRoutes([
			// {
			// 	name: "errors.notFound",
			// 	path: "*",
			// 	component: NotFoundVue
			// },
			{
				name: "errors.notFound",
				path: "/errors/notFound",
				component: () => import("./vues/NotFound.vue")
			},
			{
				name: "errors.notAuthorized",
				path: "/errors/notAuthorized",
				component: () => import("./vues/NotAuthorized.vue")
			}
		]);
	}
};
