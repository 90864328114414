import breadcrumbInstance from "@/lib/breadcrumb";

export class Breadcrumb {
	public iParentsName: string[] = [];
	public name: string;
	public pathPattern: RegExp;
	public replacePath: string;

	constructor(name: string, pathPattern: RegExp, replacePath: string, parentsName: string) {
		this.name = name;
		this.pathPattern = pathPattern;
		this.replacePath = replacePath;
		this.iParentsName = parentsName.split(">");
	}

	public get code(): string {
		return "breadcrumb." + this.name;
	}

	public get parents() {
		const parents: Breadcrumb[] = [];

		for (const parentPart of this.iParentsName) {
			for (const breadcrumb of breadcrumbInstance.breadcrumbs) {
				if (breadcrumb.name === parentPart) {
					parents.push(breadcrumb);
				}
			}
		}

		return parents;
	}

	public getPath(path: string): string {
		let finalPath: string = this.replacePath;

		if (path) {
			const matches = path.match(this.pathPattern);

			if (matches && matches.length > 1) {
				for (let i = 1; i < matches.length; i++) {
					finalPath = finalPath.replace("{" + i + "}", matches[i]);
				}
			}
		}

		return finalPath;
	}
}
