<template>
	<div class="field">
		<label v-if="label" :for="id" class="label">{{ label }}</label>

		<div v-for="(item, index) in items" :key="index" class="field is-horizontal">
			<div class="field-body">
				<div class="field">
					<slot :item="item" :index="index"></slot>
				</div>

				<div class="field is-narrow">
					<button
						:data-tooltip="deleteLabel || $t('default.label.delete')"
						@click.prevent="$emit('delete', index, item)"
						class="button is-danger is-outlined has-tooltip-left"
					>
						<span class="icon is-small"><span class="fas fa-trash"/></span>
					</button>
				</div>
			</div>
		</div>

		<div class="field" v-if="!items.length">
			<input class="input is-static" :value="emptyLabel || $t('default.label.empty')" />
		</div>

		<button @click.prevent="$emit('add')" class="button is-outlined is-success is-small">
			<span class="icon">
				<span class="fas fa-plus"></span>
			</span>
			<span>{{ addLabel || $t("default.label.add") }}</span>
		</button>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ValueListComponent<T> extends Vue {
	@Prop()
	public readonly addLabel!: string;

	@Prop()
	public readonly deleteLabel!: string;

	@Prop()
	public readonly emptyLabel!: string;

	@Prop()
	public readonly id!: string;

	@Prop({
		default: (): T[] => [],
		required: true
	})
	public readonly items!: T[];

	@Prop()
	public readonly label!: string;
}
</script>

<i18n>
{
	"fr": {
		"default.label.add": "Ajouter",
		"default.label.delete": "Supprimer",
		"default.label.empty": "Aucune donnée"
	}
}
</i18n>
