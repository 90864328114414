import Vue from "vue";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

export default {
	install() {
		library.add(fas);
		library.add(far);
		Vue.component("fa", FontAwesomeIcon);
		Vue.component("fa-layers", FontAwesomeLayers);
	}
};
