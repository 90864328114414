import Vue from "vue";
import Component from "vue-class-component";
import * as _ from "lodash";
import { Prop, Ref, Watch } from "vue-property-decorator";

import { Focusable } from "@/components/focusable/typings";

import { SuggestionRepresentation } from "@/lib/apis/models";

import { Select as NekoSelect, Input as NekoInput } from "@/components/form";

@Component({
	components: {
		NekoInput,
		NekoSelect
	}
})
export class SuggestionSelectComponent extends Vue implements Focusable<HTMLInputElement | HTMLSelectElement | null> {
	@Prop()
	public readonly suggestions!: Suggestions;

	@Prop({ default: false })
	public readonly showKey!: boolean;

	@Prop()
	public readonly value!: string;

	@Prop({ default: false })
	public readonly loading!: boolean;

	@Ref()
	public readonly select?: Focusable<HTMLInputElement | HTMLSelectElement | null>;

	public valuePart: { [key: string]: string } = {};

	private internValue: string | null = null;

	public get activeElement(): HTMLInputElement | HTMLSelectElement | null {
		return this.select?.activeElement || null;
	}

	public get suggestionSelected(): Suggestion | undefined {
		let suggestionSelected: Suggestion | undefined;

		if (isStringSuggestions(this.suggestions)) {
			suggestionSelected = this.suggestions.find(suggestion => suggestion === this.internValue);
		} else if (isKeyValueSuggestions(this.suggestions)) {
			suggestionSelected = this.suggestions.find(suggestion => suggestion.key === this.internValue);
		} else if (this.internValue) {
			// Contient les différentes parties d'une position hiérarchique niveau, position, coefficient
			const partsValue: { [key: string]: string } = {};

			this.internValue.split(";").forEach(s => {
				const keyValue = s.split("=");
				partsValue[keyValue[0]] = keyValue[1];
			});

			suggestionSelected = this.suggestions.find(suggestion => {
				let found = true;

				for (const partSuggestion of _.entries(suggestion)) {
					if (
						!partsValue.hasOwnProperty(partSuggestion[0]) ||
						(partSuggestion[1].value && partsValue[partSuggestion[0]] !== partSuggestion[1].value.toString()) ||
						(partSuggestion[1].min && parseInt(partsValue[partSuggestion[0]], 10) < partSuggestion[1].min) ||
						(partSuggestion[1].max && parseInt(partsValue[partSuggestion[0]], 10) > partSuggestion[1].max)
					) {
						found = false;
					}
				}

				return found;
			});
		}

		return suggestionSelected;
	}

	public set suggestionSelected(suggestion: Suggestion | undefined) {
		if ("string" === typeof suggestion) {
			this.internValue = suggestion;
		} else if (suggestion?.hasOwnProperty("key")) {
			this.internValue = (suggestion as any).key;
		} else {
			this.valuePart = {};

			for (const code in suggestion) {
				if (suggestion.hasOwnProperty(code)) {
					let predefinedValuePart = null;

					if ((suggestion as any)[code].value) {
						predefinedValuePart = (suggestion as any)[code].value;
					}

					this.$set(this.valuePart, code, predefinedValuePart);
				}
			}

			this.internValue = _.entries(this.valuePart)
				.map(entry => `${entry[0]}=${entry[1] || ""}`)
				.join(";");
		}
	}

	public get suggestionsSorted() {
		if (isStringSuggestions(this.suggestions)) {
			return _.sortedUniq(this.suggestions);
		} else if (isKeyValueSuggestions(this.suggestions)) {
			return _.sortedUniqBy(this.suggestions, suggestion => suggestion.value);
		} else {
			return _.sortedUniqBy(this.suggestions, suggestion => this.$options.filters?.suggestion(suggestion));
		}
	}

	public focus(options?: FocusOptions): void {
		this.select?.focus(options);
	}

	public isComplexSuggestion(suggestion: Suggestion): suggestion is ComplexSuggestion {
		return !isStringSuggestion(suggestion) && !isKeyValueSuggestion(suggestion);
	}

	public setValuePart(code: string, value: any, canValidate = false) {
		this.$set(this.valuePart, code, value);

		this.internValue = _.entries(this.valuePart)
			.map(entry => `${entry[0]}=${entry[1] || ""}`)
			.join(";");

		if (canValidate && this.shouldValidate(code)) {
			this.validateValue();
		}
	}

	public shouldValidate(code: string): boolean {
		if (!this.suggestionSelected) {
			return false;
		}

		if (this.isComplexSuggestion(this.suggestionSelected)) {
			const keys = Object.keys(this.suggestionSelected);
			const lastKey = keys[keys.length - 1];
			return code === lastKey;
		}

		return true;
	}

	public validateValue() {
		if (this.internValue == null) {
			return;
		}

		for (const code in this.valuePart) {
			if (!this.valuePart[code] || this.valuePart[code].length === 0) {
				return;
			}
		}

		this.$emit("input", this.internValue);
	}

	private created() {
		this.updateValuePart();
	}

	@Watch("value")
	private updateValuePart() {
		this.internValue = this.value;
		this.valuePart = {};

		if (this.internValue?.search(";")) {
			this.internValue.split(";").forEach(part => {
				const keyValue = part.split("=");

				if (keyValue.length === 2) {
					this.$set(this.valuePart, keyValue[0], keyValue[1]);
				}
			});
		}
	}
}

interface ComplexSuggestion extends SuggestionRepresentation {
	[key: string]: {
		min?: number;
		max?: number;
		value?: number;
	};
}

interface KeyValueSuggestion extends SuggestionRepresentation {
	key: string;
	value: string;
}

function isKeyValueSuggestion(suggestion: Suggestion): suggestion is string {
	return "string" === typeof suggestion;
}

function isKeyValueSuggestions(suggestions: Suggestions): suggestions is KeyValueSuggestion[] {
	return (suggestions as KeyValueSuggestion[]).every(suggestion => suggestion.hasOwnProperty("key"));
}

function isStringSuggestion(suggestion: Suggestion): suggestion is KeyValueSuggestion {
	return (suggestion as KeyValueSuggestion).key !== undefined;
}

function isStringSuggestions(suggestions: Suggestions): suggestions is string[] {
	return (suggestions as string[]).every(suggestion => "string" === typeof suggestion);
}

type Suggestion = string | ComplexSuggestion | KeyValueSuggestion;
type Suggestions = string[] | ComplexSuggestion[] | KeyValueSuggestion[];
