/* tslint:disable */

import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { axios } from "@/lib/axios";
import { keycloak } from "@/lib/keycloak";
import message from "@/lib/message";
import i18n from "@/lib/i18n";
import router from "@/lib/router";
import app from "@/main";


import {
	SuggestionRepresentation
} from "../models";

class DetailsSuggestionsResourceApi { 
	public async get(
		id: number /* Format : int64 */,
		paySlipId: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<SuggestionRepresentation[]> {
		return (await this.getWrap(id, paySlipId, requestConfig, errorManagement)).data;
	}

	public async getWrap(
		id: number /* Format : int64 */,
		paySlipId: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<SuggestionRepresentation[]>> {
		let url = "/suggestions/details/{id}";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());

		const params: {[key: string] : any} = {
			'paySlipId': paySlipId
		};

		for (const key in params) {
			if (params.hasOwnProperty(key) && key.search(/\[\]/) !== -1) {
				params[key.replace(/\[|\]/gi, "")] = params[key];
				delete params[key];
			}
		}
		requestConfig.params = requestConfig.params || params;
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

}
export default new DetailsSuggestionsResourceApi();
