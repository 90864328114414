import { NekoState } from "@/lib/vuex/typings";
import { Module } from "vuex";

import { UserPreference, UserPreferenceEntry, UserPreferencesState } from "./typings";

let initialState: UserPreferencesState = {};
const storageState = localStorage.getItem("userPreferences");

if (storageState) {
	initialState = JSON.parse(storageState);
}

function persistState(state: UserPreference<any>) {
	localStorage.setItem("userPreferences", JSON.stringify(state));
}

export default {
	namespaced: true,

	state: initialState,

	getters: {
		get: state => (preferenceKey: string) => {
			return state[preferenceKey];
		}
	},

	mutations: {
		setEntry(state, entry: UserPreferenceEntry) {
			if (entry.version) {
				state[entry.key] = {
					value: entry.value,
					version: entry.version
				};
			} else {
				state[entry.key] = entry.value;
			}

			persistState(state);
		},

		resetAll(state) {
			for (const key in state) {
				if (state.hasOwnProperty(key)) {
					delete state[key];
				}
			}
			persistState(state);
		}
	}
} as Module<UserPreferencesState, NekoState>;
