import Vue from "vue";
import VueProgressBar from "vue-progressbar";
import * as Highcharts from "highcharts";
import CKEditor from "@ckeditor/ckeditor5-vue2";

import apis from "@/lib/apis";
import router from "@/lib/router";
import breadcrumb from "@/lib/breadcrumb";
import message from "@/lib/message";
import store from "@/lib/vuex";
import keycloak from "@/lib/keycloak";
import i18n from "@/lib/i18n";
import menu from "@/lib/menu";
import fontawesome from "@/lib/fontAwesome";
import userPreferences from "@/lib/userPreferences";

import DateFilter from "@/filters/DateFilter";
import ReadableFilter from "@/filters/ReadableFilter";
import ReadableDetailsValueFilter from "@/filters/ReadableDetailsValue";
import UserFilter from "@/filters/UserFilter";
import TruncateFilter from "@/filters/TruncateFilter";
import NumberFilter from "@/filters/NumberFilter";
import RomanIntFilter from "@/filters/RomanIntFilter";
import DurationFilter from "@/filters/DurationFilter";
import SuggestionFilter from "@/filters/SuggestionFilter";
import EmployeeFilter from "@/filters/EmployeeFilter";
import InstitutionFilter from "@/filters/InstitutionFilter";
import ThousandsFilter from "@/filters/ThousandsFilter";
import CoordinatesFilter from "@/filters/CoordinatesFilter";
import DsnFilter from "@/filters/DsnFilter";

import ContextSelector from "@/components/contextSelector";

import ControlsViews from "@/views/controls";
import ErrorsViews from "@/views/errors";
import ExtractorsViews from "@/views/extractors";
// import HomeViews from "@/views/home";
import InstitutionsViews from "@/views/institutions";
import ReferentialViews from "@/views/referentialPay";
import TemplatesPayViews from "@/views/templatesPay";
import TemplatesMappingViews from "@/views/templatesMapping";
import DetailsViews from "@/views/details";
import EmployeesViews from "@/views/employees";
import AdditionalDataViews from "@/views/additionalData";
import ReportsViews from "@/views/reports";
import CabinetPreferencesViews from "@/views/cabinetPreferences";
import ControlResultsViews from "@/views/controlResults";
import ComplexSearchesViews from "@/views/complexSearches";
import DatabaseViews from "@/views/database";
import BillingViews from "@/views/billing";
import TasksViews from "@/views/tasks";
import TestExtractViews from "@/views/testExtract";
import ControlExcludeProfileViews from "@/views/controlExcludeProfile";
import CityViews from "@/views/cities";
import DsnViews from "@/views/dsn";
import DsnDefinitionsViews from "@/views/controls/dsn/definitions";
import ConsistencyControlsViews from "@/views/controls/consistency";
import AuditsViews from "@/views/audits";

import App from "./App.vue";

// @ts-ignore j'ai pas trouvé d'autre moyen pour importer leur worker pourri
require("pdfjs-dist/build/pdf.worker.entry");

Vue.config.productionTip = false;

Vue.use(apis);
Vue.use(keycloak);
Vue.use(VueProgressBar, {
	// color: "",
	// failedColor: "",
	thickness: "1px",
	transition: {
		speed: "0.3s",
		opacity: "0.5s",
		termination: 300
	},
	location: "top",
	position: "absolute"
});
Vue.use(fontawesome);
Vue.use(userPreferences);
Vue.use(CKEditor);

Vue.use(DateFilter);
Vue.use(ReadableFilter);
Vue.use(ReadableDetailsValueFilter);
Vue.use(UserFilter);
Vue.use(TruncateFilter);
Vue.use(NumberFilter);
Vue.use(RomanIntFilter);
Vue.use(DurationFilter);
Vue.use(SuggestionFilter);
Vue.use(EmployeeFilter);
Vue.use(InstitutionFilter);
Vue.use(ThousandsFilter);
Vue.use(CoordinatesFilter);
Vue.use(DsnFilter);

Vue.use(ContextSelector);

Vue.use(InstitutionsViews);
Vue.use(ReferentialViews);
Vue.use(ErrorsViews);
Vue.use(TemplatesPayViews);
Vue.use(TemplatesMappingViews);
Vue.use(ExtractorsViews);
Vue.use(ControlsViews);
Vue.use(DetailsViews);
Vue.use(EmployeesViews);
Vue.use(AdditionalDataViews);
Vue.use(ReportsViews);
Vue.use(CabinetPreferencesViews);
Vue.use(ControlResultsViews);
Vue.use(ComplexSearchesViews);
Vue.use(BillingViews);
Vue.use(TestExtractViews);
Vue.use(TasksViews);
Vue.use(DatabaseViews);
Vue.use(ControlExcludeProfileViews);
Vue.use(CityViews);
Vue.use(DsnViews);
Vue.use(DsnDefinitionsViews);
Vue.use(ConsistencyControlsViews);
Vue.use(AuditsViews);

router.afterEach(() => {
	window.document.title = "Paie by Cobham";
});

Vue.mixin({
	computed: {
		pageTitle: {
			set: (title: string) => {
				if (title && "function" !== typeof title) {
					window.document.title = `${title} - Paie by Cobham`;
				}
			},
			get: () => {
				return window.document.title;
			}
		}
	}
});

const app = new Vue({
	router,
	i18n,
	store,
	breadcrumb,
	message,
	menu,
	render: h => h(App)
}).$mount("#app");

Highcharts.setOptions({
	credits: { enabled: false },
	plotOptions: {
		pie: {
			dataLabels: { enabled: false }
		}
	},
	title: { text: "" },
	tooltip: {
		backgroundColor: "rgba(0, 0, 0, .75)",
		borderRadius: 8,
		borderWidth: 0,
		outside: true,
		shadow: false,
		style: { color: "white" }
	}
});

export default app;
