import { AxiosRequestConfig } from "axios";
import Keycloak from "keycloak-js";

import BaseEndpoint from "./BaseEndpoint";
import UserRepresentation from "../models/UserRepresentation";

export default class UsersEndpoint extends BaseEndpoint {
	protected readonly url: string = "/users";

	constructor(keycloak: Keycloak) {
		super(keycloak);
	}

	public async get(id: string): Promise<UserRepresentation> {
		const url = `${this.url}/${id}`;
		const config: AxiosRequestConfig = {
			headers: { Authorization: `Bearer ${this.keycloak.token}` }
		};
		const response = await this.axios.get(url, config);

		return response.data;
	}
}
