import router from "@/lib/router";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.additionalDatas": "Données annexes",
			"breadcrumb.additionalDatas.create": "Créer une donnée annexe",
			"breadcrumb.additionalDatas.edit": "Edition de donnée annexe",
			"menu.additional.data": "Données annexes"
		});

		breadcrumb.add([
			{
				path: "/database/additionalDatas",
				name: "home>database>additionalDatas"
			},
			{
				path: "/database/additionalDatas/create",
				name: "home>database>additionalDatas>additionalDatas.create"
			},
			{
				path: "/database/additionalDatas/([0-9]+)/edit",
				name: "home>database>additionalDatas>additionalDatas.edit",
				replacePath: "/database/additionalDatas/{1}/edit"
			}
		]);
		router.addRoutes([
			{
				path: "/database/additionalDatas",
				component: () => import("./vues/List.vue"),
				name: "additionalDatas",
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/additionalDatas/create",
				component: () => import("./vues/AddEdit.vue"),
				name: "additionalDatas.create",
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/additionalDatas/:id/edit",
				component: () => import("./vues/AddEdit.vue"),
				name: "additionalDatas.edit",
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			}
		]);
	}
};
