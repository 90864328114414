import { render, staticRenderFns } from "./Submit.vue?vue&type=template&id=549e6278"
import script from "./Submit.vue?vue&type=script&lang=ts"
export * from "./Submit.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../i18n/Submit.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fgitlab-runner%2Fbuilds%2FLy7TLJoH%2F0%2Femasofts%2Faudit-paie%2Fneko-paie%2Ffrontend%2Fsrc%2Fcomponents%2Fform%2Fvues%2FSubmit.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports