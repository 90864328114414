import router from "@/lib/router";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.employees.edit": "Edition du salarié",
			"breadcrumb.employees.view": "Page du salarié"
		});

		breadcrumb.add([
			{
				path: "/institutions/([0-9]+)/employees/([0-9]+)/edit",
				replacePath: "/institutions/{1}/employees/{2}/edit",
				name: "home>institutions>institutions.view>employees.view>employees.edit"
			},
			{
				path: "/institutions/([0-9]+)/employees/([0-9]+)",
				replacePath: "/institutions/{1}/employees/{2}",
				name: "home>institutions>institutions.view>employees.view"
			}
		]);

		router.addRoutes([
			{
				name: "employees.edit",
				path: "/institutions/:institutionId/employees/:id/edit",
				component: () => import("./vues/Edit.vue"),
				props(route) {
					return {
						institutionId: parseInt(route.params.institutionId, 10),
						id: parseInt(route.params.id, 10)
					};
				},
				meta: {
					secured: true,
					roles: ["USER"],
					needContext: true
				}
			},
			{
				name: "employees.get",
				path: "/institutions/:institutionId/employees/:id",
				component: () => import("./vues/View.vue"),
				props(route) {
					return {
						institutionId: parseInt(route.params.institutionId, 10),
						id: parseInt(route.params.id, 10)
					};
				},
				meta: {
					secured: true,
					roles: ["USER"],
					needContext: true
				}
			}
		]);
	}
};
