import Vue from "vue";

function filter(value?: number | null) {
	if (value == null || Number.isNaN(value)) {
		return "";
	}

	const suffixes: { [key: number]: string } = {
		1E3: "k",
		1E6: "M",
		1E9: "G",
		1E12: "T"
	};

	const keys = Object.keys(suffixes);

	for (let keyIndex = keys.length - 1; keyIndex >= 0; keyIndex--) {
		const index = parseInt(keys[keyIndex], 10);

		if (value >= index) {
			return `${Math.round((value / index))} ${suffixes[index]}`;
		}
	}

	return value;
}

export default {
	install() {
		Vue.filter("thousands", filter);
	}
};

export { filter };
