<i18n src="../i18n/Input.json"></i18n>

<template>
	<input v-if="type === 'hidden'" type="hidden" :name="name" :value="value" />

	<neko-field
		v-else
		v-bind="{ ...$props, ...$attrs }"
		:for="id"
		:loading="sending && type !== 'checkbox'"
		:reset="defaultValue !== undefined"
		@reset="changeToDefaultValue"
		@toggle="$emit('toggle', $event)"
	>
		<template #default>
			<div v-if="type === 'checkbox'" class="checkbox">
				<label>
					<input
						ref="input"
						:id="id"
						:type="type"
						:name="name"
						:disabled="disabled || sending"
						:required="required"
						:autofocus="autofocus"
						:checked="value === true"
						@change="onValueChange($event)"
						@input="onValueInput($event)"
					/>
				</label>
			</div>
			<input
				v-else-if="type === 'number'"
				ref="input"
				:id="id"
				:name="name"
				:type="type"
				:value="valueInput"
				v-on="{
					...$listeners,
					change: onValueChange,
					input: onValueInput,
					'keypress.enter': onValueInput
				}"
				:min="min"
				:max="max"
				:step="step"
				:placeholder="placeholder"
				:autocomplete="autocomplete ? 'on' : 'off'"
				:required="required"
				:autofocus="autofocus"
				:disabled="disabled || sending"
				:class="{ 'is-danger': error || isValid === false, 'is-success': isValid === true }"
				class="input"
			/>
			<div v-else-if="type === 'file'" class="file" :disabled="disabled || sending">
				<label class="file-label">
					<input
						ref="input"
						:id="id"
						class="file-input"
						:accept="accept"
						type="file"
						:multiple="multiple"
						:required="required"
						v-on="{
							...$listeners,
							change: onValueInput,
							input: onValueInput
						}"
					/>
					<span class="file-cta">
						<span class="file-icon"><span class="fas fa-upload"></span></span>
						<span v-if="countFiles === 1" v-t="{ path: 'file.chosen' }"></span>
						<span v-else-if="countFiles > 1" v-t="{ path: 'files.chosen', args: { countFiles } }"></span>
						<span v-else-if="multiple" class="file-label" v-t="'choose.files'"></span>
						<span v-else class="file-label" v-t="'choose.file'"></span>
					</span>
				</label>
			</div>
			<template v-else-if="type === 'month' && isMozillaBrowser()">
				<div class="field has-addons">
					<p class="control">
						<span class="select" :class="{ 'is-danger': error || isValid === false, 'is-success': isValid === true }">
							<select
								:id="id"
								:name="name"
								v-model="monthValue"
								@change="onUpdatePeriod"
								:autocomplete="autocomplete ? 'on' : 'off'"
								:required="required"
								:autofocus="autofocus"
								:disabled="disabled || sending"
							>
								<option value=""></option>
								<option value="01">Janvier</option>
								<option value="02">Février</option>
								<option value="03">Mars</option>
								<option value="04">Avril</option>
								<option value="05">Mai</option>
								<option value="06">Juin</option>
								<option value="07">Juillet</option>
								<option value="08">Août</option>
								<option value="09">Septembre</option>
								<option value="10">Octobre</option>
								<option value="11">Novembre</option>
								<option value="12">Décembre</option>
							</select>
						</span>
					</p>
					<p class="control">
						<input
							:autocomplete="autocomplete ? 'on' : 'off'"
							type="number"
							:required="required"
							v-model="yearValue"
							:disabled="disabled || sending"
							:class="{ 'is-danger': error || isValid === false, 'is-success': isValid === true }"
							class="input"
							@input="onUpdatePeriod"
						/>
					</p>
				</div>
			</template>
			<template v-else>
				<input
					ref="input"
					:id="id"
					:name="name"
					:type="type"
					:value="valueInput"
					v-on="{
						...$listeners,
						change: onValueChange,
						input: onValueInput,
						'keypress.enter': onValueInput
					}"
					:list="suggestionId"
					:min="min"
					:max="max"
					:maxlength="maxlength"
					:step="step"
					:placeholder="placeholder"
					:autocomplete="autocomplete ? 'on' : 'off'"
					:required="required"
					:autofocus="autofocus"
					:disabled="disabled || sending"
					:class="{ 'is-danger': error || isValid === false, 'is-success': isValid === true }"
					:pattern="pattern"
					class="input"
				/>
				<datalist v-if="suggestions.length > 0" :id="suggestionId">
					<option v-for="suggestion in suggestions" :key="suggestion" :value="suggestion" />
				</datalist>
			</template>
		</template>

		<template v-if="isValid !== undefined" #icons-left>
			<span v-if="isValid === true" class="icon has-text-success is-left">
				<span class="fa fa-check"></span>
			</span>
			<span v-if="isValid !== true" class="icon has-text-danger is-left">
				<span class="fa fa-times"></span>
			</span>
		</template>

		<slot v-for="(slot, name) in $slots" :name="name" :slot="name"></slot>
	</neko-field>
</template>

<script lang="ts">
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { InputComponent } from "@emasofts/common-vuejs-form";
import { inputtypes } from "modernizr";

import { NoCache } from "@/decorators/NoCache";

import NekoField from "./Field.vue";

const REGEXP_CONTROL_MONTH_TYPE = /(?<year>[0-9]{4})-(?<month>[0-9]{2})/;

@Component({
	components: {
		NekoField
	}
})
export default class Input extends InputComponent {
	@Prop({ default: false })
	public readonly addonsLeft!: boolean;

	@Prop({ default: false })
	public readonly addonsRight!: boolean;

	@Prop({ default: undefined })
	public readonly defaultValue!: any;

	@Prop({ default: (): string[] => [] })
	public readonly suggestions!: string[];

	@Prop({ default: undefined })
	public readonly checkValidity!: (value: any[] | number | string | boolean | File[] | FileList) => boolean;

	@Ref("input")
	public readonly inputElement!: HTMLInputElement;

	public monthValue: string | null = null;
	public yearValue: number | null = null;

	public get valueInput() {
		return this.value;
	}

	public get suggestionId() {
		return `${this.id}-suggestions`;
	}

	@NoCache
	public get isValid() {
		if (!this.checkValidity) {
			return undefined;
		}

		return this.checkValidity(this.value);
	}

	@NoCache
	public get countFiles() {
		if (this.type !== "file") {
			return null;
		}

		return this.value ? (this.value as FileList).length : 0;
	}

	public onValueChange(evt: Event) {
		const input = evt.target as HTMLInputElement;
		let value: boolean | number | string | FileList | null = input.value;

		if (this.type === "number") {
			value = input.valueAsNumber;
		} else if (this.type === "checkbox") {
			value = (evt.target as HTMLInputElement).checked;
		} else if (this.type === "file") {
			value = (evt.target as HTMLInputElement).files;
		}

		this.$emit("change", value);
	}

	public onValueInput(evt: Event) {
		const value = (evt.target as HTMLInputElement).value;

		if (value.length === 0) {
			this.$emit("input", null);
			return;
		}

		if (!inputtypes.month && this.type === "month" && !value.match(REGEXP_CONTROL_MONTH_TYPE)) {
			return;
		}

		this.onInput(evt);
		this.$forceUpdate();
	}

	public onUpdatePeriod() {
		if (this.yearValue?.toString().length === 0 && this.monthValue?.length === 0) {
			this.$emit("input", null);
		}

		const monthValueIntern = `${this.yearValue}-${this.monthValue}`;

		if (!monthValueIntern.match(REGEXP_CONTROL_MONTH_TYPE)) {
			return;
		}

		this.$emit("input", monthValueIntern);
	}

	public changeToDefaultValue() {
		this.$emit("input", this.defaultValue);
	}

	public isMozillaBrowser() {
		return navigator.userAgent.search(/Firefox/) !== -1;
	}

	private mounted() {
		this.onIsValidUpdated();
	}

	private created() {
		if (this.type === "month" && "string" === typeof this.value) {
			this.updatePropertiesMonthType(this.value as string);
		}
	}

	@Watch("isValid")
	private onIsValidUpdated() {
		if (this.isValid === true) {
			this.inputElement?.setCustomValidity("");
		} else if (this.isValid === false) {
			this.inputElement?.setCustomValidity("Erreur");
		}
	}

	@Watch("value")
	private onPropValueChange() {
		// Implémentation du type month pour Firefox car il n'est pas encore géré
		if (this.type === "month" && "string" === typeof this.value) {
			this.updatePropertiesMonthType(this.value as string);
		}
	}

	private updatePropertiesMonthType(value: string) {
		if (!value || value.length === 0) {
			this.monthValue = null;
			this.yearValue = null;
			return;
		}

		if (!value.match(REGEXP_CONTROL_MONTH_TYPE)) {
			return;
		}

		const matches: any | null = (value as string).match(REGEXP_CONTROL_MONTH_TYPE);

		if (matches) {
			this.monthValue = matches.groups.month;
			this.yearValue = parseInt(matches.groups.year, 10);
		}
	}
}
</script>
