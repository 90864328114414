<template>
	<neko-field v-bind="{ ...$props, ...$attrs }" :for="id" v-on="$listeners">
		<neko-field class="mb-0">
			<input
				v-bind="{ ...$props, ...$attrs }"
				ref="input"
				class="input"
				:id="id"
				v-model="entry"
				@blur="addTag"
				@keypress="onKeyPress"
			/>

			<slot v-for="(slot, name) in $slots" :name="name" :slot="name"></slot>
		</neko-field>

		<div v-if="value.length" class="tags mt-1">
			<span v-for="(tag, index) in value" :key="index" class="tag">
				{{ tag }}
				<button
					v-if="!disabled"
					class="delete is-small"
					@click.prevent="deleteTag(index)"
					:title="$t('delete')"
				></button>
			</span>
		</div>
	</neko-field>
</template>

<script lang="ts">
import { Component, Ref } from "vue-property-decorator";
import { InputComponent } from "@emasofts/common-vuejs-form";

import { Field as NekoField } from "@/components/form";

@Component({
	components: {
		NekoField
	}
})
export default class Tags extends InputComponent {
	declare public readonly disabled: boolean;
	declare public readonly pattern: string;
	declare public readonly sending: boolean;
	declare public readonly value: string[];

	@Ref()
	public readonly input?: HTMLInputElement;

	public entry: string | null = null;

	public addTag(): void {
		if (!this.entry || !this.input?.checkValidity()) {
			return;
		}

		if (this.value.every(value => value !== this.entry)) {
			(this.value as string[]).push(this.entry);
			this.$emit("input", this.value);
			this.entry = null;
		}
	}

	public deleteTag(index: number): void {
		if (!this.disabled && !this.sending) {
			this.value.splice(index, 1);
			this.$emit("input", this.value);
			this.input?.focus();
		}
	}

	public onKeyPress(event: KeyboardEvent): void {
		if (event.key === " " || event.key === "Enter") {
			event.preventDefault();
			this.addTag();
		}
	}
}
</script>

<i18n>
{
	"fr": {
		"delete": "Retirer"
	}
}
</i18n>
