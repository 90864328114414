export function matchWord(whereToMatch: string | null, wordToMatch: string | null) {
	if (!whereToMatch || !wordToMatch) {
		return false;
	}

	return normalize(whereToMatch).search(searchToRegExp(wordToMatch)) !== -1;
}

export function normalize(text: string): string {
	return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function searchToRegExp(search: string): RegExp {
	return new RegExp(`.*${normalize(search).trim()}.*`, "gi");
}
