<template functional>
	<div class="page-title level" :class="data.staticClass">
		<div class="level-left is-flex-shrink-1">
			<div class="level-item">
				<div>
					<h2 v-if="props.heading" class="heading">{{ props.heading }}</h2>
					<h1 class="title is-2" :class="{ 'is-flex': $slots['start-actions'] }">
						<span v-if="$slots['start-actions']" class="buttons has-addons mr-2 is-flex-shrink-0">
							<slot name="start-actions"></slot>
						</span>
						<span><slot name="title">{{ props.title }}</slot></span>
					</h1>
				</div>
			</div>
		</div>

		<div v-if="$slots['center-actions']" class="level-item">
			<slot name="center-actions"></slot>
		</div>

		<div v-if="$slots.actions" class="level-right">
			<div class="level-item">
				<div class="buttons">
					<slot name="actions"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass">
.page-title .level-left > .level-item
	max-width: 100%
</style>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PageTitle extends Vue {
	@Prop({ default: null })
	public readonly title!: string;

	@Prop({ default: null })
	public readonly heading!: string;
}
</script>
