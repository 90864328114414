import { Message, Type, MessageListener } from "./typings/message";

export default class VueMessage {
	private listeners: MessageListener[] = [];

	public success(message: string) {
		this.addMessage({
			content: message,
			id: Symbol(),
			type: Type.SUCCESS,
			closing: false
		});
	}

	public warning(message: string) {
		this.addMessage({
			content: message,
			id: Symbol(),
			type: Type.WARNING,
			closing: false
		});
	}

	public info(message: string) {
		this.addMessage({
			content: message,
			id: Symbol(),
			type: Type.INFO,
			closing: false
		});
	}

	public error(message: string) {
		this.addMessage({
			content: message,
			id: Symbol(),
			type: Type.ERROR,
			closing: false
		});
	}

	public registerListener(listener: MessageListener) {
		this.listeners.push(listener);
	}

	public unregisterListener(listener: MessageListener) {
		const index = this.listeners.findIndex(o => o === listener);

		if (index !== -1) {
			this.listeners.splice(index, 1);
		}
	}

	private addMessage(message: Message) {
		for (const listener of this.listeners) {
			listener.onMessage(message);
		}
	}
}
