<template>
	<transition-group tag="div" name="slide-up">
		<div
			v-for="message in messages"
			:key="message.id"
			class="notification clickable"
			:class="getClass(message.type)"
			v-show="!message.closing"
			@click.prevent="removeMessage(message)"
			@mouseenter="removeAutoCloseMessage(message)"
			@mouseleave="addAutoCloseMessage(message)"
		>
			<button class="delete" @click.prevent="removeMessage(message)"></button>
			<p v-html="message.content"></p>
		</div>
	</transition-group>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { MessageListener, Message, Type } from "../typings/message";
import VueMessage from "../";

@Component
export default class MessageComponent extends Vue implements MessageListener {
	@Prop({
		default() {
			return (this as Vue).$message;
		}
	})
	public messagerInstance!: VueMessage;

	@Prop({
		default: 5000
	})
	public autoClose!: number;

	public messages: Message[] = [];
	public timeouts: Map<symbol, number> = new Map();

	public onMessage(message: Message) {
		this.addAutoCloseMessage(message);

		if (this.messages.length >= 4) {
			this.messages.splice(0, 1);
		}

		this.messages.push(message);
	}

	public getClass(type: Type): string {
		let classCss = "";

		switch (type) {
			case Type.SUCCESS:
				classCss = "is-success";
				break;
			case Type.ERROR:
				classCss = "is-danger";
				break;
			case Type.INFO:
				classCss = "is-info";
				break;
			case Type.WARNING:
				classCss = "is-warning";
				break;
		}

		return classCss;
	}

	public removeMessage(message: Message) {
		const index = this.messages.findIndex(m => m.id === message.id);

		if (index !== -1) {
			this.messages[index].closing = true;
			this.removeAutoCloseMessage(message);

			setTimeout(() => {
				// Nouvelle recherche de l'index parce qu'il a pu changer entre temps
				const newIndex = this.messages.findIndex(m => m.id === message.id);
				this.messages.splice(newIndex, 1);
			}, 1000);
		}
	}

	public addAutoCloseMessage(message: Message) {
		// On supprime pour être sûr qu'on va pas laisser un timeout
		this.removeAutoCloseMessage(message);
		this.timeouts.set(message.id, setTimeout(() => {
			this.removeMessage(message);
			this.timeouts.delete(message.id);
		}, this.autoClose));
	}

	public removeAutoCloseMessage(message: Message) {
		const timeout = this.timeouts.get(message.id);
		if (timeout) {
			clearTimeout(timeout);
			this.timeouts.delete(message.id);
		}
	}

	private created() {
		this.messagerInstance.registerListener(this);
	}

	private destroyed() {
		this.messagerInstance.unregisterListener(this);
		this.messages = [];
		this.timeouts.forEach(clearTimeout);
		this.timeouts = new Map();
	}
}
</script>
