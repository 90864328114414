<script lang="ts">
import Component from "vue-class-component";
import { InputComponent } from "@emasofts/common-vuejs-form";

import NekoField from "./Field.vue";
import { Prop } from "vue-property-decorator";

@Component({
	components: {
		NekoField
	}
})
export default class ChooserComponent extends InputComponent {
	@Prop({ required: true })
	declare protected readonly type: "checkbox" | "radio";

	@Prop({ required: true })
	private readonly values!: any[] | {};

	protected onInput(evt: Event) {
		let value: any = (evt.target as HTMLInputElement).value;

		if ((evt.target as any)._value) {
			value = (evt.target as any)._value;
		}

		this.emitInput(value);
	}

	private created() {
		if (this.type === "radio" && this.value instanceof Array) {
			throw new Error("ChooserComponent can't accept value as Array when its type is radio");
		}

		if (this.type === "checkbox" && !(this.value instanceof Array)) {
			throw new Error("ChooserComponent accept only Array for value when its type is checkbox");
		}
	}

	private isArray() {
		return this.values instanceof Array;
	}

	private isChecked(value: any) {
		if (this.type === "radio") {
			return this.value === value;
		} else if (this.type === "checkbox" && this.value instanceof Array) {
			return (this.value as any[]).indexOf(value) !== -1;
		}
	}

	private emitInput(value: any) {
		if (this.disabled) {
			return;
		}

		if (this.type === "radio") {
			this.$emit("input", value);
		} else if (this.type === "checkbox" && this.value instanceof Array) {
			const newValue: any[] = [];
			let index = -1;

			if (this.value) {
				index = (this.value as any[]).indexOf(value);
				Array.prototype.push.apply(newValue, this.value as any[]);
			}

			if (index === -1) {
				newValue.push(value);
			} else if (index !== -1) {
				newValue.splice(index, 1);
			}

			this.$emit("input", newValue);
		}
	}
}
</script>

<template>
	<neko-field :label="label" :for="id" :required="required" :horizontal="horizontal" :error="error">
		<template v-if="isArray()">
			<template v-for="(val, index) in values">
				<label :key="id + '-' + index" :class="type" @click="emitInput(val)" :disabled="disabled">
					<slot :name="val" :checked="isChecked(val)">
						<input
							:type="type"
							:name="name ? name : id"
							:value="index"
							:checked="isChecked(val)"
							:disabled="disabled"
							:required="required"
							@input="onInput($event)"
							class="mr-2"
						/>{{ val }}
					</slot>
				</label>
			</template>
		</template>
		<template v-else>
			<template v-for="(val, key) in values">
				<label :key="id + '-' + key" :class="type" @click="emitInput(val)" :disabled="disabled">
					<slot :name="key" :checked="isChecked(val)">
						<input
							:type="type"
							:name="name"
							:value="val"
							:checked="isChecked(val)"
							:disabled="disabled"
							:required="required"
							@input="onInput($event)"
							class="mr-2"
						/>&nbsp;{{ key }}
					</slot>
				</label>
			</template>
		</template>

		<slot v-for="(slot, name) in $slots" :name="name" :slot="name"></slot>
	</neko-field>
</template>
