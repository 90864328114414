<template>
	<div>
		<div
			class="drop-zone box is-shadowless is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
			:class="dragOutClass"
			@dragenter.prevent="onDragEnter"
			@dragleave.prevent="onDragLeave"
			@dragover.prevent
			@drop.prevent="onDrop"
		>
			<p class="icon is-large has-text-grey mb-2">
				<span class="fa fa-2x fa-cloud-upload-alt"></span>
			</p>

			<button
				:disabled="disabled"
				@click.prevent="inputFile.click()"
				class="button is-default mb-4"
				v-t="'actions.select'"
			></button>

			<p class="is-size-7" v-t="'actions.drag.drop'"></p>

			<input v-show="false" ref="inputFile" type="file" multiple @change="onInputChange" />
		</div>

		<div v-if="files && files.length > 0" class="table-container">
			<table class="table is-fullwidth is-radiusless">
				<thead>
					<tr>
						<th v-t="'fileList.header'"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="file in files" :key="file.name">
						<td class="ellipsis ellipsis-table-cell">
							<span class="icon">
								<span v-if="file.type === 'application/pdf'" class="fa fa-file-invoice"></span>
								<span v-else-if="file.type === 'application/zip'" class="fa fa-archive"></span>
								<span v-else-if="file.type.startsWith('image')" class="fa fa-image"></span>
								<span v-else class="fa fa-file"></span>
							</span>
							<span :title="file.name">{{ file.name }}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

@Component
export default class DropZone extends Vue {
	@Prop({ type: Boolean, default: false })
	public readonly disabled!: boolean;

	@Ref()
	public readonly inputFile!: HTMLInputElement;

	public readonly dragOverClass = "has-background-white-ter";
	public readonly dragOutClass = "has-background-white-bis";
	public files: FileList | null = null;

	private dragCounter = 0;

	public clear(): void {
		this.inputFile.value = "";
		this.files = null;
	}

	public onDragEnter(event: DragEvent): void {
		if (this.disabled) {
			return;
		}

		this.dragCounter++;

		const target = event.currentTarget as HTMLDivElement;
		target.classList.remove(this.dragOutClass);
		target.classList.add(this.dragOverClass);
	}

	public onDragLeave(event: DragEvent): void {
		if (this.disabled) {
			return;
		}

		this.dragCounter--;

		if (!this.dragCounter) {
			const target = event.currentTarget as HTMLDivElement;
			target.classList.remove(this.dragOverClass);
			target.classList.add(this.dragOutClass);
		}
	}

	public onDrop(event: DragEvent): void {
		if (this.disabled) {
			return;
		}

		this.dragCounter = 0;
		const target = event.currentTarget as HTMLDivElement;
		target.classList.remove(this.dragOverClass);
		target.classList.add(this.dragOutClass);

		if (event.dataTransfer) {
			this.files = event.dataTransfer.files;
		}
	}

	public onInputChange(event: InputEvent) {
		this.files = (event.target as HTMLInputElement).files;
	}

	@Watch("files")
	private onFilesChanged(): void {
		this.$emit("change", this.files);
	}
}
</script>

<i18n>
{
	"fr": {
		"actions.select": "Sélectionner des fichiers",
		"actions.drag.drop": "Ou les glisser directement dans cette zone.",
		"fileList.header": "Fichiers sélectionnés"
	}
}
</i18n>
