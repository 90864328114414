<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, State } from "vuex-class";

import { GetClient } from "@/lib/keycloak/api/Store";
import { GroupRepresentation } from "@/lib/keycloak/api/models/GroupRepresentation";

import { getWarnings } from "@/views/audits/utils";

import { Warning } from "@/views/audits/typings";
import { AuditRepresentation } from "@/lib/apis/models";

import { ContextState } from "../typings";

@Component
export default class ContextSelectorComponent extends Vue {
	@State("context")
	public readonly context!: ContextState;

	@Getter("context/audit")
	public readonly audit!: AuditRepresentation;

	@Getter("context/year")
	public readonly year!: number;

	@Action("keycloak-api/getClient")
	public readonly getClient!: GetClient;

	@Action("context/refreshAudit")
	public readonly refreshAudit!: Function;

	public client: GroupRepresentation | string = "";
	public showDropdown: boolean = false;
	private timeoutUpdate: number | null = null;

	public get warningType() {
		return Warning;
	}

	public get hasWarnings() {
		return this.warnings.length > 0;
	}

	public get warnings() {
		if (!this.audit) {
			return [];
		}

		return getWarnings(this.audit);
	}

	public goToAudit() {
		if (!this.audit || this.$route.name === "audits.get") {
			return;
		}

		this.$router.push({ name: "audits.get", params: { id: this.audit.id.toString() } });
	}

	public clickNotification(type: Warning) {
		if (!this.audit) {
			return;
		}

		if (type === Warning.MissingInstitutions) {
			this.$router.push({ name: "audits.institutions", params: { id: this.audit.id.toString() } });
		} else if (type === Warning.MissingFiles) {
			this.$router.push({ name: "audits.missing.files", params: { id: this.audit.id.toString() } });
		} else if (type === Warning.MissingMapping) {
			this.$router.push({ name: "audits.payslip.lines", params: { id: this.audit.id.toString() } });
		} else if (type === Warning.MissingData) {
			this.$router.push({ name: "audits.missing.data", params: { id: this.audit.id.toString() } });
		} else if (type === Warning.MissingDateRestored) {
			this.$router.push({ name: "audits.edit", params: { id: this.audit.id.toString() } });
		} else if (type === Warning.MissingComment) {
			this.$router.push({ name: "audits.get", params: { id: this.audit.id.toString() }, query: { tabId: "comment" } });
		} else if (type === Warning.MissingReports) {
			this.$router.push({ name: "audits.get", params: { id: this.audit.id.toString() }, query: { tabId: "reports" } });
		}
	}

	private async created() {
		this.watchContextSelected();
		this.watchAudit();
	}

	private mounted() {
		document.addEventListener("click", this.hideDropdown);
	}

	private destroyed() {
		if (this.timeoutUpdate) {
			clearTimeout(this.timeoutUpdate);
		}

		document.removeEventListener("click", this.hideDropdown);
	}

	private hideDropdown() {
		this.showDropdown = false;
	}

	@Watch("context.selected")
	private async watchContextSelected() {
		if (this.audit) {
			this.client = await this.getClient(this.audit.clientId);
		}
	}

	@Watch("audit")
	private watchAudit() {
		if (!this.audit) {
			return;
		}

		if (this.audit.scanStatus === "EXTRACTING" || this.audit.scanStatus === "READY") {
			if (this.timeoutUpdate) {
				clearTimeout(this.timeoutUpdate);
				this.timeoutUpdate = null;
			}

			this.timeoutUpdate = setTimeout(async () => {
				await this.refreshAudit();
				this.watchAudit();
			}, 3000);
		}
	}
}
</script>

<template>
	<p v-if="!context.selected" v-t="'no.context.selected'"></p>
	<div
		v-else-if="audit"
		class="context"
		:class="{
			'is-success': audit.status === 'RESTORED' || !hasWarnings,
			'is-warning': audit.status !== 'RESTORED' && hasWarnings,
			'is-danger': audit.scanStatus === 'ERROR' || audit.closed,
			'is-info': audit.scanStatus === 'EXTRACTING' || audit.scanStatus === 'READY'
		}"
	>
		<div
			v-if="audit && hasWarnings"
			@click.stop="showDropdown = !showDropdown && audit.status !== 'RESTORED'"
			class="audit-status"
		>
			<div
				v-show="audit.scanStatus === 'EXTRACTING' || audit.scanStatus === 'READY'"
				class="has-tooltip-bottom"
				:data-tooltip="$t('notification.scanning.tooltip')"
			>
				<span class="icon"><span class="fas fa-sync-alt fa-spin"></span></span>
			</div>

			<div
				v-show="audit.scanStatus === 'ERROR'"
				class="has-tooltip-bottom"
				:data-tooltip="$t('notification.error.tooltip')"
			>
				<span class="icon"><span class="fas fa-times"></span></span>
			</div>

			<div v-show="audit.closed" class="has-tooltip-bottom" :data-tooltip="$t('notification.audit.closed')">
				<span class="icon"><span class="fas fa-times"></span></span>
			</div>

			<div
				v-show="audit.status === 'RESTORED'"
				class="has-tooltip-bottom"
				:data-tooltip="$t('notification.audit.status.restored')"
			>
				<span class="icon"><span class="fas fa-flag-checkered"></span></span>
			</div>

			<template v-if="audit.status !== 'RESTORED'">
				<div
					v-show="warnings.length === 0"
					class="has-tooltip-bottom"
					:data-tooltip="$t('notification.no.warnings.tooltip')"
				>
					<span class="icon"><span class="fas fa-check"></span></span>
				</div>

				<div v-show="warnings.length > 0" class="dropdown dropdown-notification" :class="{ 'is-active': showDropdown }">
					<div class="dropdown-trigger">
						<span class="icon">
							<span class="fas fa-exclamation-triangle"></span>
						</span>
						&nbsp;<b>{{ warnings.length }}</b>
					</div>

					<div class="dropdown-menu">
						<div class="dropdown-content">
							<div
								v-show="warnings.indexOf(warningType.MissingInstitutions) > -1"
								@click.prevent="clickNotification(warningType.MissingInstitutions)"
								class="dropdown-item"
							>
								<div class="notification is-warning">
									<span class="icon"><span class="fas fa-exclamation-triangle"></span></span>
									<span v-t="'notification.audit.status.missing.institutions'"></span>
								</div>
							</div>
							<div
								v-show="warnings.indexOf(warningType.MissingFiles) > -1"
								@click.prevent="clickNotification(warningType.MissingFiles)"
								class="dropdown-item"
							>
								<div class="notification is-warning">
									<span class="icon"><span class="fas fa-exclamation-triangle"></span></span>
									<span v-t="'notification.audit.status.missing.files'"></span>
								</div>
							</div>
							<div
								v-show="warnings.indexOf(warningType.MissingMapping) > -1"
								@click.prevent="clickNotification(warningType.MissingMapping)"
								class="dropdown-item"
							>
								<div class="notification is-warning">
									<span class="icon"><span class="fas fa-exclamation-triangle"></span></span>
									<span v-t="'notification.audit.status.missing.mapping'"></span>
								</div>
							</div>
							<div
								v-show="warnings.indexOf(warningType.MissingData) > -1"
								@click.prevent="clickNotification(warningType.MissingData)"
								class="dropdown-item"
							>
								<div class="notification is-warning">
									<span class="icon"><span class="fas fa-exclamation-triangle"></span></span>
									<span v-t="'notification.audit.status.missing.data'"></span>
								</div>
							</div>
							<div
								v-show="warnings.indexOf(warningType.MissingDateRestored) > -1"
								@click.prevent="clickNotification(warningType.MissingDateRestored)"
								class="dropdown-item"
							>
								<div class="notification is-warning">
									<span class="icon"><span class="fas fa-exclamation-triangle"></span></span>
									<span v-t="'notification.audit.date.restored.missing'"></span>
								</div>
							</div>
							<div
								v-show="warnings.indexOf(warningType.MissingComment) > -1"
								@click.prevent="clickNotification(warningType.MissingComment)"
								class="dropdown-item"
							>
								<div class="notification is-warning">
									<span class="icon"><span class="fas fa-exclamation-triangle"></span></span>
									<span v-t="'notification.audit.comment.missing'"></span>
								</div>
							</div>
							<div
								v-show="warnings.indexOf(warningType.HavePaySlipInErrorNotVisited) > -1"
								class="dropdown-item"
							>
								<div class="notification is-warning">
									<span class="icon"><span class="fas fa-exclamation-triangle"></span></span>
									<span v-t="'notification.audit.have.pay.slip.in.error.not.visited'"></span>
								</div>
							</div>
							<div
								v-show="warnings.indexOf(warningType.HavePaySlipMissingData) > -1"
								class="dropdown-item"
							>
								<div class="notification is-warning">
									<span class="icon"><span class="fas fa-exclamation-triangle"></span></span>
									<span v-t="'notification.audit.have.pay.slip.missing.data'"></span>
								</div>
							</div>
							<div
								v-show="warnings.indexOf(warningType.MissingReports) > -1"
								@click.prevent="clickNotification(warningType.MissingReports)"
								class="dropdown-item"
							>
								<div class="notification is-warning">
									<span class="icon"><span class="fas fa-exclamation-triangle"></span></span>
									<span v-t="'notification.audit.reports.missing'"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
		<router-link class="audit-details" :to="{ name: 'audits.get', params: { id: audit.id }}">
			<div class="audit-type">
				<span
					v-show="audit.type === 'COMPLETE'"
					class="tag is-white has-tooltip-left"
					:data-tooltip="$t('audit.type.COMPLETE')"
					>C</span
				>
				<span
					v-show="audit.type === 'PARTIAL'"
					class="tag is-white has-tooltip-left"
					:data-tooltip="$t('audit.type.PARTIAL')"
					>P</span
				>
			</div>
			<div class="audit-infos">
				<div class="audit-client-name">{{ client.name }}</div>
				<div>
					<b>{{ audit.name }}</b>
				</div>
			</div>
			<div class="audit-period">
				<div>{{ audit.currentCycleStart | date("yearmonth") }}</div>
				<div>{{ audit.currentCycleEnd | date("yearmonth") }}</div>
			</div>
		</router-link>
	</div>
</template>

<i18n>
{
	"fr": {
		"audit.type.COMPLETE": "Complet",
		"audit.type.PARTIAL": "Partiel",
		"no.context.selected": "Aucun contexte",
		"audit.has.warnings": "Préparation de l'audit non terminée",
		"notification.scanning.tootlip": "Extraction en cours",
		"notification.error.tooltip": "Erreur lors de l'extraction",
		"notification.no.warnings.tooltip": "Prêt pour l'audit",
		"notification.audit.closed": "Audit clôturé",
		"notification.scanning.tooltip": "Analyse des fichiers en cours...",
		"notification.audit.status.missing.institutions": "Aucun établissement associé à l'audit",
		"notification.audit.status.missing.files": "Fichiers manquants pour l'audit",
		"notification.audit.status.missing.mapping": "Lignes sans correspondances",
		"notification.audit.status.missing.data": "Bulletins avec données manquantes",
		"notification.audit.status.restored": "L'audit est terminé et restitué.",
		"notification.audit.date.restored.missing": "Date de restitution absente",
		"notification.audit.comment.missing": "Commentaire de restitution absent",
		"notification.audit.reports.missing": "Aucun rapport trouvé pour la période d'audit",
		"notification.audit.have.pay.slip.in.error.not.visited": "Des anomalies ne sont pas encore consultées",
		"notification.audit.have.pay.slip.missing.data": "Des bulletins sont en donnée manquante"
	}
}
</i18n>

<style lang="sass" scoped>
@use "sass:list"
@use "sass:map"
@import "@/styles/variables.sass"

@mixin context-colors($background-color: $primary, $color: $primary-invert)
	$status-background-color: darken($background-color, 15%)

	.audit-status,
	.audit-details
		color: $color

	.audit-status
		background-color: $status-background-color

		&:hover
			background-color: lighten($status-background-color, 10%)

		&:active
			background-color: darken($status-background-color, 5%)

	.audit-details
		background-color: $background-color
		color: $color

		&:hover
			background-color: lighten($background-color, 10%)

		&:active
			background-color: darken($background-color, 5%)

		.audit-type > .tag
			color: $background-color

.context
	display: flex
	background-color: $primary
	margin: -0.5rem -0.75rem
	cursor: pointer

	@include context-colors

	.audit-status
		display: flex
		align-items: center
		padding-left: 0
		padding-right: 0

		> *
			padding: 0.5rem
			font-size: 1.25rem

		.dropdown-notification
			.dropdown-trigger
				cursor: pointer
				display: flex
				align-items: center

			.dropdown-content .notification
				font-weight: bold
				display: flex
				margin: 0
				align-items: center
				cursor: pointer
				white-space: nowrap

	.audit-details
		display: flex
		gap: 0.7rem
		padding: 0.5rem 0.75rem

		.audit-type
			display: flex
			align-items: center

			> .tag
				font-size: 1rem
				font-weight: bold
				padding: 0.4rem

		.audit-infos, .audit-period
			display: flex
			flex-direction: column
			align-items: center
			justify-content: center

		.audit-client-name
			font-size: 0.85rem

		.audit-period
			margin: -0.25rem 0
			font-size: 1.1rem
			font-weight: bold

	$status-colors: ()
	$status-colors: map.set($status-colors, "warning", $warning $warning-invert)
	$status-colors: map.set($status-colors, "success", $success $success-invert)
	$status-colors: map.set($status-colors, "info", $info $info-invert)
	$status-colors: map.set($status-colors, "danger", $danger $danger-invert)

	@each $status, $colors in $status-colors
		$background-color: list.nth($colors, 1)
		$color: list.nth($colors, 2)

		&.is-#{$status}
			@include context-colors($background-color, $color)
</style>
