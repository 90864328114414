import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

export type ModalSize = "small" | "medium" | "large";

@Component
export class ModalComponent extends Vue {
	@Prop({ default: "small" })
	public readonly size!: ModalSize;

	@Prop({ default: false })
	public readonly fullHeight!: boolean;

	@Prop({ default: false, type: Boolean })
	public readonly paddingless!: boolean;

	public shown: boolean = false;

	public show() {
		this.shown = true;
		window.document.documentElement.classList.add("is-clipped");
		window.document.querySelector(".app-container")?.classList?.add("is-clipped");
		this.$emit("show");
	}

	public hide() {
		this.shown = false;
		window.document.documentElement.classList.remove("is-clipped");
		window.document.querySelector(".app-container")?.classList?.remove("is-clipped");
		this.$emit("close");
	}

	public onClose() {
		this.shown = false;
		window.document.documentElement.classList.remove("is-clipped");
		window.document.querySelector(".app-container")?.classList?.remove("is-clipped");
		this.$emit("close", true);
	}

	private destroyed() {
		window.document.documentElement.classList.remove("is-clipped");
		window.document.querySelector(".app-container")?.classList?.remove("is-clipped");
	}
}
