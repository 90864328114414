import { PluginObject } from "vue";
import Keycloak from "keycloak-js";

import router from "@/lib/router";
import store from "@/lib/vuex";

import KeycloakConfig from "@/config/keycloak.json";

import i18n from "../i18n";

import loginModule from "./store";
import apiModule from "./api/Store";
import { EmaSoftsToken } from "./typings/EmaSoftsToken";
import { axios } from "../axios";

i18n.mergeLocaleMessage("fr", {
	"page.not.authorized": "Vous n'êtes pas autorisé à visiter cette page"
});

let keycloakInit = false;
export const keycloak: Keycloak = new Keycloak(KeycloakConfig);

function saveTokens() {
	if (keycloak.token) {
		sessionStorage.setItem("token", keycloak.token);
	}

	if (keycloak.idToken) {
		sessionStorage.setItem("idToken", keycloak.idToken);
	}

	if (keycloak.refreshToken) {
		sessionStorage.setItem("refreshToken", keycloak.refreshToken);
	}

	store.commit("login/setAuthenticated", true);

	keycloak.loadUserProfile().then(profile => {
		profile.id = keycloak.subject;
		store.commit("login/setCurrentUser", profile);
	});

	// @ts-ignore
	const clientId = process.env.VUE_APP_API_CLIENT_ID;

	if (keycloak.resourceAccess && clientId && keycloak.resourceAccess.hasOwnProperty(clientId)) {
		store.commit("login/setRoles", keycloak.resourceAccess[clientId].roles);
		store.commit("login/setTokenRaw", keycloak.token as string);
		store.commit("login/setToken", keycloak.tokenParsed as EmaSoftsToken);
	}
}

function clearTokens() {
	sessionStorage.removeItem("token");
	sessionStorage.removeItem("idToken");
	sessionStorage.removeItem("refreshToken");

	store.commit("login/setCurrentUser", undefined);
	store.commit("login/setAuthenticated", false);
	store.commit("login/setRoles", []);
	store.commit("login/setTokenRaw", undefined);
	store.commit("login/setToken", undefined);
}

function buildCallbackUrl(login: boolean, path: string) {
	if (!path) {
		path = "/";
	}

	return (
		// @ts-ignore
		process.env.VUE_APP_URL_APPLICATION + `/${login ? "login" : "logout"}/callback?path=${encodeURIComponent(path)}`
	);
}

function authenticate(callbackPath: string) {
	return new Promise<void>(async resolve => {
		// On vérifie s'il est authentifié, ça n'est pas le cas à la première arrivée sur une page.
		if (!keycloak.authenticated) {
			store.commit("login/setAuthenticating", true);
			keycloak.login({
				redirectUri: buildCallbackUrl(true, callbackPath)
			});
			return;
		}

		// On vérifie que le token n'est pas expiré sinon on le renouvèle
		if (!keycloak.isTokenExpired(5)) {
			resolve();
			return;
		}

		try {
			const refreshed = await keycloak.updateToken(5);

			if (refreshed) {
				saveTokens();
				resolve();
			} else {
				clearTokens();
				keycloak.login({
					redirectUri: buildCallbackUrl(true, callbackPath)
				});
			}
		} catch (e) {
			clearTokens();
			keycloak.login({
				redirectUri: buildCallbackUrl(true, callbackPath)
			});
		}
	});
}

export default {
	install() {
		if (axios.defaults.transformRequest instanceof Array) {
			axios.defaults.transformRequest.push((data: any, headers: any) => {
				const cabinets = store.getters["login/cabinets"];

				if (cabinets?.length > 1) {
					const cabinetSelected = store.getters["login/cabinet"];
					headers["Cabinet-Id"] = cabinetSelected?.id || "";
				}

				return data;
			});
		}

		const initConfig = {
			checkLoginIframe: false
		} as Keycloak.KeycloakInitOptions;

		const token = sessionStorage.getItem("token");
		if (token) {
			initConfig.token = token;
		}
		const idToken = sessionStorage.getItem("idToken");
		if (idToken) {
			initConfig.idToken = idToken;
		}
		const refreshToken = sessionStorage.getItem("refreshToken");
		if (refreshToken) {
			initConfig.refreshToken = refreshToken;
		}

		keycloak.onAuthSuccess = saveTokens;
		keycloak.onAuthLogout = clearTokens;
		keycloak.onAuthError = clearTokens;
		keycloak.onTokenExpired = async () => {
			try {
				const refreshed = await keycloak.updateToken(5);

				if (!refreshed) {
					clearTokens();
					router.push({ path: "/login", query: { path: router.currentRoute.path } });
				}
			} catch (e) {
				clearTokens();
				router.push({ path: "/login", query: { path: router.currentRoute.path } });
			}
		};

		store.registerModule("login", loginModule);
		store.registerModule("keycloak-api", apiModule);

		/*
		 * Le but de cette route est de garantir que keycloak est bien initialisé
		 * Comme l'initialisation n'est pas la même en fonction de si c'est un callback ou une page classique
		 */
		router.beforeEach(async (to, from, next) => {
			if (keycloakInit === true) {
				next();
				return;
			}

			try {
				store.commit("login/setAuthenticating", true);
				await keycloak.init(initConfig);
				keycloakInit = true;
				next();
			} catch (e) {
				// tslint:disable-next-line
				console.error(e);
				keycloakInit = true;
				next("/");
			} finally {
				store.commit("login/setAuthenticating", false);
			}
		});

		/*
		 * Cette route permet de sécuriser les routes ayant comme meta.secured = true
		 */
		router.beforeEach(async (to, from, next) => {
			if (to.path === "/login") {
				let path = to.query.path;

				if (path === "/login/callback" || path === "/logout/callback") {
					path = "/";
				}

				store.commit("login/setAuthenticating", true);
				await keycloak.login({ redirectUri: buildCallbackUrl(true, path as string) });
			} else if (to.path === "/logout") {
				let path = to.query.path;

				if (path === "/login/callback" || path === "/logout/callback") {
					path = "/";
				}

				clearTokens();
			} else if (to.path === "/login/callback") {
				let route = "/";

				if (to.query.path) {
					const resolution = router.resolve(decodeURIComponent(to.query.path as string));

					if (resolution.resolved.matched.length > 0) {
						route = to.query.path as string;
					}
				}

				try {
					await authenticate(route);
				} finally {
					store.commit("login/setAuthenticating", false);
				}

				next(route);
			} else if (to.path === "/account") {
				await keycloak.accountManagement();
			} else if (to.meta && to.meta.secured) {
				try {
					await authenticate(to.fullPath);
				} finally {
					store.commit("login/setAuthenticating", false);
				}

				if (to.meta.roles) {
					if (store.getters["login/hasRoles"](to.meta.roles)) {
						next();
					} else {
						next("/errors/notAuthorized");
					}
				} else {
					next();
				}
			} else {
				next();
			}
		});
	}
} as PluginObject<any>;
