import Vue from "vue";

import { EmployeeSimpleRepresentation } from "@/lib/apis/models";

function filter(employee?: EmployeeSimpleRepresentation): string | undefined {
	if (!employee) {
		return;
	}

	const employeeTextPart = [];

	if (employee.registrationNumber) {
		employeeTextPart.push(`${employee.registrationNumber} -`);
	} else {
		employeeTextPart.push("øøø -");
	}

	if (employee.fullName) {
		employeeTextPart.push(employee.fullName);
	} else {
		employeeTextPart.push(`N°SS : ${employee.socialSecurityNumber}`);
	}

	return employeeTextPart.join(" ");
}

export default {
	install() {
		Vue.filter("employee", filter);
	}
};
export { filter };
