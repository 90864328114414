import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export class CardComponent extends Vue {
	@Prop({
		default: true
	})
	private readonly show!: boolean;

	@Prop({
		default: true
	})
	private readonly clickable!: boolean;

	private shown: boolean = true;

	private created() {
		if (this.clickable) {
			this.shown = this.show;
		}
	}

	private toggleShown() {
		if (this.clickable) {
			this.shown = !this.shown;
		}
	}
}
