import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";
import router from "@/lib/router";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.dsn.view": "Page DSN"
		});

		breadcrumb.add([
			{
				name: "home>institutions>institutions.view>dsn.view",
				path: "/institutions/([0-9]+)/dsn/([0-9]+)",
				replacePath: "/institutions/{1}/dsn/{2}"
			}
		]);

		router.addRoutes([{
			name: "dsn.view",
			path: "/institutions/:institutionId/dsn/:id",
			component: () => import("./vues/ViewDsn.vue"),
			props(route) {
				return {
					institutionId: parseInt(route.params.institutionId, 10),
					id: parseInt(route.params.id, 10),
				};
			},
			meta: {
				secured: true,
				roles: ["USER"],
				needContext: true
			}
		}]);
	}
};
