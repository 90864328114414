<script lang="ts">
import { BreadcrumbComponent } from "../controllers/Breadcrumb";

export default BreadcrumbComponent;
</script>

<template>
	<nav v-if="current" class="breadcrumb is-fixed-top">
		<ul>
			<li v-for="parent in current.parents" :key="parent.code">
				<router-link :to="parent.getPath($route.path)" v-t="parent.code"></router-link>
			</li>

			<li class="is-active">
				<a @click.prevent v-t="current.code"></a>
			</li>
		</ul>
	</nav>
</template>
