<i18n src="../i18n/Menu.json"></i18n>

<template>
	<nav class="navbar is-fixed-top is-radiusless is-hoverable">
		<div class="navbar-brand">
			<neko-navbar-search v-if="login.authenticated" />

			<div
				class="navbar-burger"
				@click="showNavbarMobile = !showNavbarMobile"
				:class="{ 'is-active': showNavbarMobile }"
			>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>

		<div class="navbar-menu" :class="{ 'is-active': showNavbarMobile }" @click="showNavbarMobile = false">
			<div class="navbar-middle">
				<div class="navbar-item">
					<context-selector v-if="login.authenticated" />
				</div>
			</div>

			<div class="navbar-end">
				<neko-navbar-item
					v-if="login.authenticated && cabinets.length"
					class="has-tooltip-bottom"
					:data-tooltip="cabinet && cabinets && cabinets.length > 1 && $t('menu.cabinet.change')"
					:link="cabinet && cabinets && cabinets.length > 1"
					@click="cabinetsModal.show()"
				>
					<span class="icon is-medium mr-2">
						<fa icon="building" class="fa-2x" transform="shrink-6" :mask="'circle'" />
					</span>
					{{ cabinetSelected ? cabinetSelected.name : $t("menu.cabinet.choose") }}
				</neko-navbar-item>

				<neko-navbar-item v-if="login.authenticated" dropdown="right">
					<template #default>
						<span class="icon is-medium mr-2">
							<span class="fas fa-user-circle fa-2x"></span>
						</span>
						{{ fullName }}
					</template>
					<template #dropdown>
						<neko-navbar-item to="/account">
							<span class="icon"><span class="fas fa-user-cog"/></span>
							<span v-t="'menu.my.account'"></span>
						</neko-navbar-item>

						<a @click.prevent="resetAllPreferences" class="navbar-item">
							<span class="icon"><span class="fas fa-eraser"></span></span>
							<span v-t="'menu.erase.user.preferences'"></span>
						</a>
						<neko-navbar-item to="/logout">
							<span class="icon"><span class="fas fa-sign-out-alt"/></span>
							<span v-t="'menu.disconnect'"></span>
						</neko-navbar-item>
					</template>
				</neko-navbar-item>
			</div>
		</div>

		<neko-modal ref="cabinets-modal" size="small" paddingless>
			<template #title>{{ $t("menu.cabinet.choose") }}</template>
			<neko-cabinet-selector />
		</neko-modal>
	</nav>
</template>

<style lang="sass">
@import "@/styles/variables.sass"

.navbar-middle
	margin: auto

.navbar-item .select
	select, .selected
		color: $primary
		background-color: transparent
		border: none
		box-shadow: none
		font-size: 1.75rem
		line-height: 2.50rem
		padding: 0 2.5rem 0 1rem
		height: 2.50rem
		border-radius: $radius-rounded
		text-align: center
		transition: background-color .1s ease-out

		&:not([multiple])
			padding-right: 2.5rem

		&:hover, &:active, &:focus
			border-color: transparent
			box-shadow: none

		&:hover
			background-color: $primary
			color: $background

			::placeholder
				color: $white
				opacity: .5

		&.is-focused
			box-shadow: none

		option
			background-color: $white !important
			color: $text

		input
			background: transparent

	&::after
		border-color: $primary !important

	&:hover::after
		border-color: $background !important

.navbar-middle + .navbar-end
	margin-left: 0
</style>

<script lang="ts">
import { Getter, Mutation, State } from "vuex-class";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";

import { NoCache } from "@/decorators/NoCache";

import { LoginState } from "@/lib/keycloak/typings/LoginState";
import { Cabinet } from "@/lib/keycloak/typings/EmaSoftsToken";

import { CabinetSelector as NekoCabinetSelector } from "@/components/cabinetSelector";
import { ContextSelector } from "@/components/contextSelector";
import { Select as NekoSelect } from "@/components/form";
import { Modal as NekoModal } from "@/components/modal";

import NekoNavbarItem from "./NavbarItem.vue";
import NekoNavbarSearch from "./NavbarSearch.vue";

@Component({
	components: {
		NekoCabinetSelector,
		NekoNavbarItem,
		NekoNavbarSearch,
		NekoModal,
		NekoSelect,
		ContextSelector
	}
})
export default class NavbarComponent extends Vue {
	@State("login")
	public login!: LoginState;

	@Getter("login/cabinets")
	public cabinets!: Cabinet[];

	@Getter("login/cabinet")
	public cabinet!: Cabinet;

	@Mutation("login/setCabinet")
	public setCabinet!: (cabinet: Cabinet) => void;

	@Mutation("user-preferences/resetAll")
	public resetAllUserPreferences!: () => void;

	@Ref("cabinets-modal")
	public readonly cabinetsModal!: NekoModal;

	public showNavbarMobile: boolean = false;

	@NoCache
	public get cabinetSelected() {
		return this.cabinet;
	}

	public set cabinetSelected(cabinet: Cabinet) {
		this.setCabinet(cabinet);
	}

	public get fullName() {
		if (!this.login.currentUser) {
			return "";
		}

		return this.login.currentUser.firstName + " " + this.login.currentUser.lastName;
	}

	public hasOneOfRole(roles: string[]) {
		let result = false;

		for (const role of roles) {
			if (this.login.roles?.indexOf(role) !== -1) {
				result = true;
				break;
			}
		}

		return result;
	}

	public resetAllPreferences() {
		this.resetAllUserPreferences();
		this.$message.success(this.$t("user.preferences.reset.success") as string);
	}

	@Watch("login.cabinet")
	private async watchCabinet() {
		this.cabinetsModal.hide();
	}
}
</script>
