import Vue from "vue";

interface Duration {
	millis?: number;
	seconds?: number;
	minutes?: number;
	hours?: number;
	days?: number;
}

function filter(timeInMillis: number) {
	let str = "";

	const ms = timeInMillis % 1000;
	timeInMillis = (timeInMillis - ms) / 1000;
	const secs = timeInMillis % 60;
	timeInMillis = (timeInMillis - secs) / 60;
	const mins = timeInMillis % 60;
	const hrs = (timeInMillis - mins) / 60;

	if (ms > 0) {
		str = `${ms}ms`;
	}
	if (secs > 0) {
		str = `${secs}s ${str}`;
	}
	if (mins > 0) {
		str = `${mins}m ${str}`;
	}
	if (hrs > 0) {
		str = `${hrs}h ${str}`;
	}

	return str;
}

export default {
	install() {
		Vue.filter("duration", filter);
	}
};

export { filter };
