import router from "@/lib/router";
import menu from "@/lib/menu";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";
import vuex from "@/lib/vuex";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.control.results": "Résultats de contrôle"
		});

		breadcrumb.add([
			{
				path: "/controlResults",
				name: "home>control.results"
			}
		]);

		router.addRoutes([
			{
				name: "control.results",
				path: "/controlResults",
				component: () => import("./vues/ControlResults.vue"),
				meta: {
					secured: true,
					roles: ["RESPONSIBLE"],
					needContext: true
				}
			}
		]);

		menu.addEntry({
			label: i18n.t("breadcrumb.control.results") as string,
			icon: "fa fa-list-alt",
			location: { name: "control.results" },
			roles: ["USER"],
			order: 2,
			get enabled() {
				return !!vuex.getters["context/audit"];
			}
		});
	}
};
