import * as moment from "moment";
import { AuditRepresentation } from "@/lib/apis/models";

export interface Context {
	year: number;
}

export class AuditContext implements Context {
	constructor(public audit: AuditRepresentation) { }

	get year() {
		return moment(this.audit.currentCycleStartPeriod, "YYYY-MM")
			.year();
	}
}

export interface ContextState {
	selected?: Context;
}

export type ChooseAudit = (audit: AuditRepresentation) => void;
export type ChooseYear = (year: number) => void;
export type DeleteContext = () => void;
