export enum AuditStatus {
	"WAITING_FILES" = 1,
	"WAITING_MAPPING" = 2,
	"WAITING_MISSING_DATA" = 3,
	"READY" = 4,
	"IN_PROGRESS" = 5,
	"RESTORABLE" = 6,
	"RESTORED" = 7
}

export enum Warning {
	MissingInstitutions,
	MissingFiles,
	MissingMapping,
	MissingData,
	MissingDateRestored,
	MissingComment,
	MissingReports,
	HavePaySlipInErrorNotVisited,
	HavePaySlipMissingData
}
