import Vue from "vue";
import * as _ from "lodash";

import { SuggestionRepresentation } from "@/lib/apis/models";
import i18n from "@/lib/i18n";

function filter(value: SuggestionRepresentation | string[], showKey: boolean = false) {
	if ("string" === typeof value) {
		return value;
	} else if (value.hasOwnProperty("key")) {
		let suggestion = "";

		if (showKey) {
			suggestion += `${(value as any).key} - `;
		}

		return `${suggestion}${(value as any).value}`;
	} else {
		return _.sortBy(_.entries(value), entry => entry[0])
			.map(codeAndSuggestion => {
				if ("undefined" !== typeof codeAndSuggestion[1].min && codeAndSuggestion[1].min != null) {
					return `${i18n.t(codeAndSuggestion[0])} : >= ${codeAndSuggestion[1].min}`;
				} else if ("undefined" !== typeof codeAndSuggestion[1].max && codeAndSuggestion[1].max != null) {
					return `${i18n.t(codeAndSuggestion[0])} : <= ${codeAndSuggestion[1].max}`;
				} else {
					return `${i18n.t(codeAndSuggestion[0])} : ${codeAndSuggestion[1].value}`;
				}
			})
			.join(", ");
	}
}

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			position: "Position",
			categorie: "Categorie",
			niveau: "Niveau",
			emploi: "Emploi",
			coefficient: "Coefficient",
			echelon: "Echelon",
			degres: "Degrés"
		});

		Vue.filter("suggestion", filter);
	}
};

export { filter };
