export enum Type {
	SUCCESS,
	WARNING,
	INFO,
	ERROR
}

export interface Message {
	id: symbol;
	content: string;
	type: Type;
	closing: boolean;
}

export interface MessageListener {
	onMessage(message: Message): void;
}
