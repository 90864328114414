<i18n>
{
	"fr": {
		"select.multiple": "Aucune sélection | 1 sélectionné | {count} séléctionnés",
		"search.no.data": "Aucune sélection correspondante",
		"select.check.all": "Tout",
		"select.uncheck.all": "Aucun"
	}
}
</i18n>

<script lang="tsx">
import { ScopedSlotChildren } from "vue/types/vnode";
import { SelectComponent } from "@emasofts/common-vuejs-form";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";

import { matchWord } from "@/utils/SearchUtilities";

import { NoCache } from "@/decorators/NoCache";

import { Focusable } from "@/components/focusable/typings";

import NekoField from "./Field.vue";

@Component({
	components: {
		NekoField
	}
})
export default class Select extends SelectComponent implements Focusable<HTMLInputElement | HTMLSelectElement | null> {
	@Prop({
		default: true
	})
	private readonly simple!: boolean;

	@Prop({
		default: 3
	})
	private readonly maxValueDisplayable!: number;

	@Prop({
		default: null
	})
	private readonly search!: (searchValue: string, value: any, option: HTMLOptionElement) => boolean;

	@Prop({
		default: false
	})
	private readonly nullOption!: boolean;

	@Prop({
		default: undefined
	})
	private readonly defaultValue!: any;

	@Ref()
	private readonly selectContainer!: HTMLDivElement;

	@Ref()
	private readonly select!: HTMLSelectElement;

	@Ref()
	private readonly optionsDropdown!: HTMLDivElement;

	@Ref()
	private readonly optionsElement!: HTMLDivElement;

	@Ref("search")
	private readonly searchElement!: HTMLInputElement;

	@Ref("selected")
	private readonly selectedElement!: HTMLDivElement;

	private optionPreselected: Option | null = null;
	private showOptions: boolean = false;
	private searchValue: string = "";
	private displayableValue: string = "";
	private focusedElement: HTMLInputElement | HTMLSelectElement | null = null;
	private flattenOptions: Option[] = [];
	private options: Array<OptGroup | Option> = [];

	private get eventHandlers(): Vue["$listeners"] {
		const handlers: Vue["$listeners"] = {
			...this.$listeners,
			focus: this.onFocus,
			keyup: this.onKeyup
		};

		delete handlers.input;

		return handlers;
	}

	private get model() {
		return this.value;
	}

	private set model(value) {
		this.$emit("input", value);
	}

	private get nextOptionPreselected(): Option {
		const currentIndex = this.getCurrentPreselectedIndex();
		const nextIndex = currentIndex + 1;

		if (currentIndex < 0 || nextIndex >= this.flattenOptions.length) {
			return this.flattenOptions[0];
		}

		return this.flattenOptions[nextIndex];
	}

	private get previousOptionPreselected(): Option {
		const currentIndex = this.getCurrentPreselectedIndex();
		if (currentIndex <= 0) {
			return this.flattenOptions[this.flattenOptions.length - 1];
		}

		return this.flattenOptions[currentIndex - 1];
	}

	@NoCache
	private get widthSelectContainer() {
		let width = "200px";
		const rects = this.selectContainer?.getClientRects();

		if (rects && rects.length > 0) {
			width = `${rects[0].width}px`;
		}

		return width;
	}

	public get activeElement(): HTMLInputElement | HTMLSelectElement | null {
		return this.focusedElement;
	}

	public focus(options?: FocusOptions): void {
		if (this.simple && this.select) {
			this.focusedElement = this.select;
			this.select.focus(options);
		} else if (!this.simple && this.searchElement) {
			this.onClick();
			this.focusedElement = this.searchElement;
			this.searchElement.focus(options);
		}
	}

	@NoCache
	private get searchCount() {
		if (!this.select) {
			return 0;
		}

		if (!this.searchValue || this.searchValue.trim().length === 0) {
			return this.select.options.length;
		}

		return Array.prototype.filter.call(this.select.options, (o: HTMLOptionElement) => this.isMatched(o)).length;
	}

	private getCurrentPreselectedIndex(): number {
		return this.flattenOptions.findIndex(option => option === this.optionPreselected);
	}

	private mounted() {
		this.updateDisplayableValue();
	}

	private updated() {
		this.updateDisplayableValue();
	}

	private updateDisplayableValue() {
		if (!this.select || (Array.isArray(this.value) && !this.value.length)) {
			if (this.label) {
				this.displayableValue = "";
			} else {
				this.displayableValue = this.placeholder;
			}
			return;
		}

		if (!this.multiple) {
			const selected: HTMLOptionElement[] = Array.prototype.filter.call(
				this.select.options,
				option => option.selected
			);

			this.displayableValue = selected.length > 0 ? selected[0].innerText.trim() : this.placeholder;
		} else {
			if ((this.value as any[])?.length > this.maxValueDisplayable) {
				this.displayableValue = this.$tc("select.multiple", (this.value as any[]).length, {
					count: (this.value as any[]).length
				});
			} else {
				const label = Array.prototype.filter
					.call(this.select.options, o => (this.value as any[])?.indexOf(o._value ? o._value : o.value) !== -1)
					.map(o => o.innerText.trim())
					.join(", ");

				if (label.length > 0) {
					this.displayableValue = label;
				}
			}
		}
	}

	@Watch("showOptions")
	@Watch("searchValue")
	@Watch("value")
	private updateOptions() {
		this.flattenOptions = [];

		if (!this.select) {
			return;
		}

		if (!this.simple) {
			this.options = Array.prototype.filter
				.call(this.select.children, (child: SelectChild) => isOptGroupElement(child) || isOptionElement(child))
				.reduce<OptionTree>((result: OptionTree, child: SelectChild) => {
					if (isOptionElement(child) && this.isMatched(child)) {
						const option: Option = {
							disabled: child.disabled,
							index: result.length,
							label: child.innerText,
							selected: child.selected,
							element: child,
							value: child.value
						};

						this.flattenOptions.push(option);
						return [...result, option];
					}

					if (isOptGroupElement(child)) {
						const group: OptGroup = {
							index: result.length,
							label: child.label,
							options: [],
							element: child
						};

						const options = Array.prototype.filter
							.call(child.children, (option: HTMLOptionElement) => isOptionElement(option) && this.isMatched(option))
							.map((option, optionIndex) => ({
								disabled: option.disabled,
								group,
								index: optionIndex,
								label: option.innerText,
								selected: option.selected,
								element: option,
								value: option.value
							}));

						group.options = options;

						if (options.length) {
							this.flattenOptions = this.flattenOptions.concat(options);
							return [...result, group];
						}
					}

					return result;
				}, []);
			this.updateDisplayableValue();
		}
	}

	private destroyed() {
		window.addEventListener("click", this.onClickout);
	}

	private isMatched(option: HTMLOptionElement) {
		if (!this.searchValue || this.searchValue.trim().length === 0) {
			return true;
		}

		if (this.search) {
			return this.search(this.searchValue, (option as any)._value ? (option as any)._value : option.value, option);
		}

		return !option ? false : matchWord(option.innerText, this.searchValue);
	}

	private onFocus(e: FocusEvent, click?: boolean) {
		this.$emit("focus", e);

		if (click) {
			this.onClick();
		}
	}

	private onKeydown(event: KeyboardEvent) {
		if (event.key === "ArrowUp") {
			this.optionPreselected = this.previousOptionPreselected;
		} else if (event.key === "ArrowDown") {
			this.optionPreselected = this.nextOptionPreselected;
		} else if (event.key === "Enter" && this.optionPreselected) {
			event.preventDefault();
			this.onClickOption(event, this.optionPreselected);
		} else if (event.key === "Tab") {
			this.onClickout();
		} else {
			this.optionPreselected = null;
		}

		if (this.optionPreselected) {
			const option = !this.optionPreselected.group
				? this.optionsElement.children[this.optionPreselected.index]
				: this.optionsElement.children[this.optionPreselected.group.index].children[this.optionPreselected.index];

			option?.scrollIntoView(false);
		}
	}

	private onKeyup(e: KeyboardEvent) {
		if (e.key === "Escape") {
			this.$emit("press-esc");
			this.onClickout();
		}
	}

	private onToggle(toggled: boolean) {
		this.$emit("toggle", toggled);
	}

	private onClick() {
		if (this.showOptions || this.disabled || this.sending) {
			return;
		}

		this.searchElement.value = "";
		this.showOptions = true;

		setTimeout(() => {
			this.searchElement.focus();
			window.addEventListener("click", this.onClickout);
		}, 50);
	}

	private onClickout(e?: MouseEvent) {
		if (!this.showOptions) {
			return;
		}

		if (!this.optionsDropdown) {
			window.removeEventListener("click", this.onClickout);
			return;
		}

		const optionsRect = this.optionsDropdown.getBoundingClientRect();
		const selectedRect = this.selectedElement.getBoundingClientRect();

		if (
			!e ||
			((e.y < optionsRect.y ||
				e.y > optionsRect.y + optionsRect.height ||
				e.x < optionsRect.x ||
				e.x > optionsRect.x + optionsRect.width) &&
				(e.y < selectedRect.y ||
					e.y > selectedRect.y + selectedRect.height ||
					e.x < selectedRect.x ||
					e.x > selectedRect.x + selectedRect.width))
		) {
			this.showOptions = false;
			this.searchValue = "";
			this.optionPreselected = null;
			window.removeEventListener("click", this.onClickout);
		}
	}

	private onClickOption(event: Event, option: Option) {
		if (!this.multiple) {
			this.showOptions = false;
			this.searchValue = "";
			window.removeEventListener("click", this.onClickout);
		}

		const selected = !option.selected;
		option.element.selected = selected;
		option.selected = selected;

		this.searchElement.select();
		this.select.dispatchEvent(new Event("change"));
	}

	private checkAll(e: MouseEvent) {
		e.preventDefault();

		Array.prototype.filter
			.call(this.select.options, (o: HTMLOptionElement) => this.isMatched(o))
			.forEach(o => (o.selected = true));

		this.select.dispatchEvent(new Event("change"));
	}

	private unCheckAll(e: MouseEvent) {
		e.preventDefault();

		Array.prototype.filter
			.call(this.select.options, (o: HTMLOptionElement) => this.isMatched(o))
			.forEach(o => (o.selected = false));

		this.select.dispatchEvent(new Event("change"));
	}

	private changeToDefaultValue() {
		this.$emit("input", this.defaultValue);
	}

	private render() {
		const containerClass = [
			"select is-fullwidth is-relative", {
				"is-multiple": this.multiple,
				"is-danger": this.error,
				"is-loading": this.sending
			}
		];

		const props = {
			...this.$props,
			...this.$attrs,
			for: this.id,
			reset: this.defaultValue !== undefined,
			on: this.$listeners,
			onReset: this.changeToDefaultValue
		};

		let displayedValue: string | ScopedSlotChildren;

		if (this.$scopedSlots.selected) {
			displayedValue = this.$scopedSlots.selected({ selected: this.model });
		} else if (this.displayableValue) {
			displayedValue = this.displayableValue;
		}

		return (
			<NekoField props={props} onToggle={this.onToggle}>
				<div ref="selectContainer" class={containerClass} title={displayedValue}>
					<select
						v-show={this.simple}
						ref="select"
						id={this.id}
						name={this.name}
						multiple={this.multiple}
						size={this.size}
						required={this.required}
						autofocus={this.autofocus}
						autocomplete={this.autocomplete ? "on" : "off"}
						disabled={this.disabled || this.sending}
						v-model={this.model}
						on={this.eventHandlers}
					>
						{!this.required && !this.multiple && this.nullOption && <option value={undefined}>N/A</option>}
						{this.$slots.default}
					</select>

					{!this.simple && (
						<div
							ref="selected"
							class={["selected", { "is-focused": this.showOptions, "is-disabled": this.disabled || this.sending }]}
							tabindex="0"
							onClick={this.onClick}
							onFocus={(e: FocusEvent) => this.onFocus(e, true)}
							onKeydown={this.onKeydown}
						>
							{/* Hack pour éviter les varations de largeur */}
							<span class="placeholder">
								{this.displayableValue.length > this.placeholder.length ? this.displayableValue : this.placeholder}
							</span>
							<span
								v-show={!this.showOptions}
								class={["information", { "is-placeholder": !this.label && !this.value }]}
							>
								{displayedValue}
							</span>
							<input
								ref="search"
								class="search"
								v-show={this.showOptions}
								v-model={this.searchValue}
								disabled={this.disabled || this.sending}
								type="text"
								size="1"
								placeholder={this.placeholder}
								on={this.eventHandlers}
							/>
						</div>
					)}

					{!this.simple && (
						<div
							ref="optionsDropdown"
							class={["options-dropdown", { "is-invisible": !this.showOptions }]}
							style={{ width: this.widthSelectContainer }}
						>
							<div key="no-data" v-show={this.searchCount === 0} class="has-text-centered" v-t="search.no.data"></div>
							<div class="is-unbreakable" v-show={this.multiple && this.searchCount > 0}>
								<a href="#" tabindex="-1" onClick={this.checkAll} v-t="select.check.all"></a>&nbsp;/
								<a href="#" tabindex="-1" onClick={this.unCheckAll} v-t="select.uncheck.all"></a>
							</div>
							{this.select && this.searchCount > 0 && (
								<div ref="optionsElement" tabindex="-1" class={["options", { "is-multiple": this.multiple }]}>
									{this.getOptionElements(this.options)}
								</div>
							)}
						</div>
					)}
				</div>

				{Object.keys(this.$slots).filter(name => name !== "default").map(name =>
					<template slot={name}>{this.$slots[name]}</template>
				)}
			</NekoField>
		);
	}

	private getOptionElements(options: Array<OptGroup | Option>, optGroupIndex?: number): JSX.Element[] {
		return options.map((option, index) => {
			if (isOptGroup(option)) {
				return (
					<div key={`optgroup-${option.label}`} class="optgroup" data-label={option.label}>
						{this.getOptionElements(option.options, index)}
					</div>
				);
			}

			const optionClass = [
				"option ellipsis ellipsis-12", {
					"is-selected": option.selected,
					"is-focused": option === this.optionPreselected,
					"is-disabled": option.disabled
				}
			];

			return (
				<div
					key={`option${optGroupIndex !== undefined ? `-${optGroupIndex}` : ""}-${index}-${option.value}`}
					class={optionClass}
					onClick={(e: MouseEvent) => this.onClickOption(e, option)}
					title={option.label}
				>
					{this.multiple && <input type="checkbox" checked={option.selected} disabled={option.disabled} />}
					&nbsp;<span domPropsInnerHTML={option.element.innerHTML}></span>
				</div>
			);
		});
	}
}

function isOptGroup(object: OptGroup | Option): object is OptGroup {
	return (object as OptGroup).options !== undefined;
}

function isOptGroupElement(element: SelectChild): element is HTMLOptGroupElement {
	return element.tagName === "OPTGROUP";
}

function isOptionElement(element: SelectChild): element is HTMLOptionElement {
	return element.tagName === "OPTION";
}

interface OptGroup {
	index: number;
	label: string;
	options: Option[];
	element: HTMLOptGroupElement;
}

interface Option {
	disabled: boolean;
	group?: OptGroup;
	index: number;
	label: string;
	selected: boolean;
	element: HTMLOptionElement;
	value: string;
}

type OptionTree = Array<OptGroup | Option>;
type SelectChild = HTMLOptGroupElement | HTMLOptionElement;
</script>
