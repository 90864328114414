import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";
import router from "@/lib/router";

import CityList from "./vues/CityList.vue";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.cities": "Villes"
		});

		breadcrumb.add([
			{
				path: "/cities",
				name: "home>database>cities"
			}
		]);

		router.addRoutes([
			{
				path: "/cities",
				name: "cities",
				component: CityList,
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			}
		]);
	}
};
