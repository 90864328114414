<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import * as _ from "lodash";

import { NoCache } from "@/decorators/NoCache";

import { Updatable } from "../typings";

@Component({
	components: {}
})
export default class MenuComponent extends Vue {
	@Getter("login/hasRoles")
	public hasRoles!: (roles: string[]) => Promise<boolean>;

	private intervalUpdater: number | null = null;

	@NoCache
	public get entries() {
		return _.sortBy(this.$menu.getEntries(), "order");
	}

	private created() {
		this.$router.afterEach(() => {
			this.$forceUpdate();
		});

		const countUpdatable = this.callUpdatableItem();

		if (countUpdatable > 0) {
			this.intervalUpdater = setInterval(this.callUpdatableItem, 15 * 1000);
		}
	}

	private destroyed() {
		if (this.intervalUpdater) {
			clearInterval(this.intervalUpdater);
		}
	}

	private callUpdatableItem() {
		const itemUpdatable: Updatable[] = [];

		for (const entry of this.entries) {
			if (entry.updater) {
				itemUpdatable.push(entry);
			}
		}

		for (const updatable of itemUpdatable) {
			updatable.updater?.call(updatable, this);
		}

		if (itemUpdatable.length > 0) {
			this.$forceUpdate();
		}

		return itemUpdatable.length;
	}
}
</script>

<template>
	<aside class="menu is-application">
		<ul class="menu-list">
			<template v-for="entry in entries">
				<template v-if="!entry.roles || hasRoles(entry.roles)">
					<li :key="entry.label">
						<template v-if="entry.location && (entry.enabled || 'undefined' === typeof entry.enabled)">
							<router-link
								:to="entry.location"
								:class="{ 'is-active': entry.active }"
								:data-tooltip="entry.label"
								class="has-tooltip-right"
							>
								<span v-show="entry.icon" class="icon"><span :class="entry.icon"></span></span>
							</router-link>
						</template>
						<template v-else>
							<a @click.prevent href="#" :data-tooltip="entry.label" class="is-disabled has-tooltip-right">
								<span v-show="entry.icon" class="icon"><span :class="entry.icon"></span></span>
							</a>
						</template>
					</li>
				</template>
			</template>
		</ul>
	</aside>
</template>
