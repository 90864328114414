<script lang="ts">
import { DataList } from "../controllers/DataList";

export default DataList;
</script>

<i18n src="../i18n/DataList.json"></i18n>

<template>
	<div ref="dataListContainer" class="datalist-container">
		<div v-show="!infiniteScroll && paginationPosition === 'top'" class="datalist-pagination">
			<neko-pagination :disabled="loading" :max="maxPage" v-model="currentPage" :size="options.paginationSize" />

			<div
				@click="onSelectAll(true)"
				v-show="resultsCountShown"
				class="has-text-centered"
				v-t="{
					path: 'data.list.results.info',
					args: { start: end === 0 ? start : start + 1, end: end, total: elementsTotal }
				}"
			/>

			<div v-show="displayLengthShown" class="has-text-right">
				<div class="select">
					<select :disabled="loading" v-model="limit">
						<option v-for="d in displayLength" :selected="d === limit" :value="d" :key="d">{{ d }}</option>
					</select>
				</div>
			</div>
		</div>

		<div v-if="optionsShown" class="datalist-options">
			<div v-if="filtersShown || ordersShown || actionsShown" class="datalist-options-start">
				<div v-if="filtersShown" class="datalist-filters">
					<neko-field>
						<button
							class="button is-default has-tooltip-right has-tooltip-top"
							:disabled="loading"
							@click.prevent="resetFilters()"
							:data-tooltip="$t('data.list.actions.reset.filters')"
						>
							<span class="icon">
								<fa-layers>
									<fa icon="slash" mask="filter" mask-transform="grow-5" transform="grow-10 up-1 right-2" />
									<fa icon="slash" transform="up-1 left-1" />
								</fa-layers>
							</span>
						</button>
					</neko-field>

					<template v-for="filter in filters">
						<template v-if="filter.visible !== false">
							<neko-select
								v-if="filter.options"
								:key="`filter-${filter.name}-options`"
								:simple="false"
								:multiple="filter.multiple"
								:label="filter.name"
								v-model="filter.value"
								:disabled="loading"
							>
								<option
									v-for="(option, index) in filter.options"
									:key="'filter-options' + filter.data + '-' + index"
									:value="option.value"
								>
									{{ option.name }}
								</option>
							</neko-select>

							<neko-select
								v-else-if="filter.existence"
								:key="`filter-${filter.name}-existence`"
								:simple="false"
								:multiple="filter.multiple"
								:label="filter.name"
								v-model="filter.value"
								:disabled="loading"
							>
								<option value="FALSE" v-t="'data.list.filters.existence.FALSE'"></option>
								<option value="TRUE" v-t="'data.list.filters.existence.TRUE'"></option>
							</neko-select>

							<neko-input
								v-else-if="filter.date === 'month'"
								:key="`filter-${filter.name}`"
								type="month"
								:label="filter.name"
								v-model="filter.value"
							/>

							<neko-input
								v-else
								:key="`filter-${filter.name}`"
								:label="filter.name"
								type="search"
								v-model="filter.value"
							/>
						</template>
					</template>
				</div>

				<div v-if="ordersShown" class="datalist-orders buttons">
					<template v-for="column in columns">
						<template v-if="column.orderable === true">
							<button
								v-show="column.visible !== false"
								:key="'column-' + column.data"
								@click="changeOrder(column)"
								class="button"
								:class="{ 'is-active': column.dir }"
								:disabled="loading"
							>
								<span v-show="column.orderable" class="icon"><span class="fas" :class="getOrderClass(column)" /></span>
								<slot :name="column.name" :loading="loading">
									<span v-html="column.name ? column.name : 'no name'"></span>
								</slot>
							</button>
						</template>
					</template>
				</div>

				<div class="datalist-actions" v-if="actionsShown">
					<button
						v-show="refreshShown"
						@click.prevent="refresh(true)"
						class="button is-small is-default"
						:class="{ 'is-loading': loading }"
					>
						<span class="icon">
							<span class="fa fa-sync-alt"></span>
						</span>
						<span v-t="'data.list.actions.refresh'"></span>
					</button>

					<slot name="actions" :elements="elements"></slot>

					<button
						v-show="selectShow"
						@click.prevent="resetSelection()"
						class="button is-small is-default"
						:disabled="elementsSelected.length === 0"
					>
						<span class="icon">
							<span class="fa fa-eraser"></span>
						</span>
						<span v-t="'data.list.actions.reset.selection'"></span>
					</button>

					<div v-show="selectShow" class="is-size-7">
						{{ $tc("data.list.selected.info", elementsSelected.length, { count: elementsSelected.length }) }}
					</div>
				</div>
			</div>

			<div v-show="searchShown" class="control has-icons-left">
				<input v-model="search" type="search" class="input" :placeholder="$t('data.list.search.placeholder')" />
				<span class="icon is-left">
					<span class="fas fa-search"></span>
				</span>
			</div>
		</div>

		<div
			v-if="table"
			class="has-background-white is-flex-grow-1"
			:class="{ 'is-fullwidth is-overflow-auto': options.tableContainer }"
		>
			<table class="table is-striped is-hoverable is-fullwidth">
				<thead>
					<tr>
						<th v-show="selectShow" class="is-narrow">
							<input
								:disabled="loading || elements.length === 0"
								type="checkbox"
								@change="onSelectAll($event.target.checked)"
								:checked="isAllSelected()"
							/>
						</th>
						<template v-for="column in columns">
							<th
								v-show="column.visible !== false"
								:key="column.data"
								@click="changeOrder(column)"
								class="order is-uppercase"
								style="vertical-align: middle"
								:class="{ 'is-active': column.dir, 'is-orderable': column.orderable }"
							>
								<slot :name="column.name" :loading="loading">
									<span v-html="column.name ? column.name : 'no name'"></span>
								</slot>
								<span v-show="column.orderable" class="icon fas" :class="getOrderClass(column)"></span>
							</th>
						</template>
					</tr>
				</thead>

				<tbody :class="{ 'is-loading': loading && !infiniteScroll }">
					<tr v-if="!loading && elementsTotal === 0">
						<td :colspan="columns.length + (selectShow ? 1 : 0)" class="has-text-centered">
							<em v-t="'data.list.empty'"></em>
						</td>
					</tr>
					<tr v-if="loading && elements.length === 0">
						<td :colspan="columns.length + (selectShow ? 1 : 0)" class="has-text-centered">
							<em v-t="'data.list.loading'"></em>
						</td>
					</tr>
					<tr
						v-for="(element, index) in elements"
						:key="index"
						ref="htmlElements"
						@mousedown="clickRow($event, element)"
						:class="{ 'is-clickable': options.callbackClickRow }"
					>
						<td v-show="selectShow" class="is-narrow has-text-centered" @mousedown.stop="onSelectElement(element)">
							<input
								type="checkbox"
								@change="onSelectElement(element)"
								@mousedown.stop
								:disabled="loading"
								:checked="isSelected(element)"
							/>
						</td>
						<slot :element="element" :view-index="index" :real-index="getRealIndex(index)" :total="elementsTotal">
						</slot>
					</tr>
				</tbody>
			</table>
		</div>

		<template v-else>
			<p v-show="!loading && elements.length === 0" class="has-text-centered is-italic" v-t="'data.list.empty'" />
			<p v-show="loading && elements.length === 0" class="has-text-centered is-italic">
				<span class="icon"><span class="fas fa-circle-notch fa-spin" /></span>
				{{ $t("data.list.loading") }}
			</p>
			<div class="columns is-multiline my-0 mx-0">
				<div
					v-for="(element, index) in elements"
					:class="'column is-' + 12 / elementsPerLine"
					:key="index"
					ref="htmlElements"
				>
					<slot :element="element" :view-index="index" :real-index="getRealIndex(index)"></slot>
				</div>
			</div>
		</template>

		<div v-show="infiniteScroll" class="datalist-infinite-scroll">
			<div v-show="elementsTotal > 0" class="has-text-centered">
				<p v-if="elements.length >= elementsTotal" v-t="'data.list.no.more.data'" />
				<button
					v-else
					@click="infiniteScrollLoadMore()"
					class="button is-primary is-outlined"
					:class="{ 'is-loading': loading }"
					v-t="'load.more'"
				></button>
			</div>
		</div>

		<div v-show="!infiniteScroll && paginationPosition === 'bottom'" class="datalist-pagination">
			<neko-pagination :disabled="loading" :max="maxPage" v-model="currentPage" :size="options.paginationSize" />

			<div
				@click="onSelectAll(true)"
				v-show="resultsCountShown"
				class="has-text-centered"
				v-t="{
					path: 'data.list.results.info',
					args: { start: end === 0 ? start : start + 1, end: end, total: elementsTotal }
				}"
			/>

			<div v-show="displayLengthShown" class="has-text-right">
				<div class="select">
					<select :disabled="loading" v-model="limit">
						<option v-for="d in displayLength" :selected="d === limit" :value="d" :key="d">{{ d }}</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>
@import "@/styles/variables.sass"

$order-border-color: $primary
$order-color: $text
$order-line-height: $size-2
$order-radius: $radius
$order-hover-background-color: lighten($order-border-color, 5%)
$order-hover-color: $primary-invert
$order-is-active-background-color: $order-border-color
$order-is-active-color: $primary-invert
$order-is-active-hover-background-color: darken($order-border-color, 5%)
$order-is-active-hover-color: $primary-invert
$spacing-horizontal: 0.75em
$spacing-vertical: 0.5em

.datalist-container
	background-color: $white
	border-radius: $radius
	display: flex
	flex-direction: column
	padding: 0

	.datalist-options
		display: flex
		padding: $spacing-vertical $spacing-horizontal

		.datalist-options-start
			display: flex
			flex-direction: column
			flex-grow: 1
			justify-content: center

			&:not(:last-child)
				margin-right: $spacing-horizontal

			.datalist-filters
				display: flex
				flex-flow: row wrap
				margin-bottom: $spacing-vertical

				> *
					margin-bottom: 0.75rem
					padding: 0 5px
					max-width: 210px
					display: flex
					flex-direction: column
					justify-content: flex-end

					&:first-child
						padding-left: 0

					&:last-child
						padding-right: 0

			.datalist-orders
				margin-bottom: $spacing-vertical

			.datalist-actions
				display: flex
				align-items: center

				> *:not(:last-child)
					margin-right: 0.5em

		table
			border-radius: 0

			tbody.is-loading
				opacity: 0.5

	.datalist-pagination
		padding: $spacing-vertical 0.75em
		display: flex
		align-items: center

		.pagination
			margin: 0

		> *
			flex-grow: 1
			flex-basis: 0

	.datalist-infinite-scroll
		border-radius: 0 0 $radius-small $radius-small
		padding: 0.5em 0.75em

.order.is-orderable
	cursor: pointer
	text-align: left
	color: $order-color
	white-space: nowrap
</style>
