import VueMessage from "@/layouts/message";
import Vue, { VueConstructor } from "vue";

Vue.use({
	install(constructor: VueConstructor) {
		Object.defineProperty(constructor.prototype, "$message", {
			// tslint:disable-next-line
			get: function() {
				return this.$root.$options.message;
			}
		});
	}
});

export default new VueMessage();
