import Vue from "vue";
import Component from "vue-class-component";

@Component
export class BreadcrumbComponent extends Vue {
	private get current() {
		const breadcrumbInstance = this.$root.$options.breadcrumb;

		if (!breadcrumbInstance) {
			return;
		}

		const currentBreadcrumb = breadcrumbInstance.fromPath(this.$route.path);

		if (currentBreadcrumb === null) {
			// tslint:disable-next-line
			console.warn("No breadcrumb found");
		}

		return currentBreadcrumb;
	}
}
