import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

import { Modal as NekoModal } from "@/components/modal";

enum AskModalType {
	Delete = "delete",
	Force = "force"
}

@Component({
	components: {
		NekoModal
	}
})
export default class AskModalComponent extends Vue {
	@Prop()
	public readonly validateWord!: string | null;

	@Prop({ default: "delete" })
	public readonly type!: AskModalType;

	@Ref()
	public readonly modal!: NekoModal;

	public input: string = "";
	public errorValidate: boolean = false;
	public elementArg: any = null;

	public get askModalType() {
		return AskModalType;
	}

	public get wordToType() {
		return this.validateWord ?? this.$t(`modal.ask.default.${this.type}.validate.word`);
	}

	public show(elementArg: any) {
		this.elementArg = elementArg;
		this.errorValidate = false;
		this.input = "";
		this.$nextTick(() => this.modal.show());
	}

	public hide() {
		this.modal.hide();
		this.elementArg = undefined;
	}

	public onValidate() {
		if (this.input.trim() === this.wordToType) {
			this.errorValidate = false;
			this.modal.hide();
			this.$emit("validate", this.elementArg);
			this.elementArg = null;
		} else {
			this.errorValidate = true;
		}
	}

	public onCloseError() {
		this.errorValidate = false;
	}
}
