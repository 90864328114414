import router from "@/lib/router";
import menu from "@/lib/menu";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.database": "Base de données"
		});

		breadcrumb.add([
			{
				path: "/database",
				name: "home>database"
			}
		]);

		router.addRoutes([
			{
				path: "/database",
				name: "database",
				component: () => import("./vues/Index.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			}
		]);

		menu.addEntry({
			label: i18n.t("breadcrumb.database") as string,
			icon: "fa fa-database",
			location: { name: "database" },
			roles: ["DATABASE_ADMINISTRATOR"],
			order: 110
		});
	}
};
