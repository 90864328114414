import Vue from "vue";
import Router from "vue-router";

import message from "@/lib/message";
import i18n from "@/lib/i18n";

Vue.use(Router);

const router = new Router();

i18n.mergeLocaleMessage("fr", {
	"router.error": "Impossible d'aller sur la page demandée"
});

router.onError(() => {
	message.error(i18n.t("router.error") as string);
});

router.afterEach((to, from) => {
	if (to.matched && to.matched.length > 0) {
		return;
	}

	router.push({ name: "errors.notFound" });
});

export default router;
