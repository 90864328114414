<template>
	<neko-modal ref="modal" @close="$emit('close', $event)" paddingless size="medium">
		<template #title>
			<p class="modal-card-title" v-t="'title'"></p>
		</template>

		<neko-data-list :data="getData" :options="options">
			<template #default="{element}">
				<td>{{ element.institution.name }}</td>
				<td>{{ element.employee.fullName }}</td>
				<td>{{ element.dateStart | date }}</td>
				<td>{{ element.dateEnd | date }}</td>
			</template>
		</neko-data-list>
	</neko-modal>
</template>

<script lang="ts">
import { Component, Ref, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import { DataListOptions, DataListParams } from "@emasofts/common-vuejs-datalist";

import PaySlipsApi from "@/lib/apis/operations/PaySlipsResourceApi";
import { ListResponsePaySlipRepresentation, PaySlipRepresentation } from "@/lib/apis/models";

import { Modal as NekoModal } from "@/components/modal";
import { ContextState} from "@/components/contextSelector";

@Component({
	components: {
		// L'import dynamique est requis à cause de la récursivité d'appels
		NekoDataList: () => import("@/components/dataList").then(module => module.DataList),
		NekoModal
	},
	name: "PaySlipSelectorModal"
})
export default class PaySlipSelectorModal extends Vue {
	@State("context")
	public readonly contextState!: ContextState;

	@Ref()
	public readonly modal?: NekoModal;

	public readonly options: DataListOptions = {
		columns: [
			{ name: this.$t("datalist.column.institution").toString(), data: "institution", orderable: true, dir: "asc" },
			{ name: this.$t("datalist.column.employee").toString(), data: "employee", orderable: true },
			{ name: this.$t("datalist.column.date.start").toString(), data: "dateStart", orderable: true },
			{ name: this.$t("datalist.column.date.end").toString(), data: "dateEnd", orderable: true }
		],
		displayLength: [20, 50, 100],
		displayLengthDefault: 20,
		callbackClickRow: (element: PaySlipRepresentation) => {
			this.$emit("validate", element.oldestScan.id);
			this.modal?.hide();
		}
	};

	public get yearSelected(): number | null {
		return this.contextState.selected?.year || null;
	}

	public async getData(params: DataListParams): Promise<ListResponsePaySlipRepresentation> {
		if (this.yearSelected) {
			return await PaySlipsApi.listFinished(
				this.yearSelected,
				params.draw,
				params.start,
				params.length,
				params.search,
				params.order,
				params.filter
			);
		}

		return {
			data: [],
			draw: params.draw,
			recordsTotal: 0
		};
	}

	public hide(): void {
		this.modal?.hide();
		this.$emit("close");
	}
	public show(): void {
		this.modal?.show();
	}
}
</script>

<i18n>
{
	"fr": {
		"datalist.column.institution": "Établissement",
		"datalist.column.employee": "Salarié",
		"datalist.column.date.start": "Date de début",
		"datalist.column.date.end": "Date de fin",
		"title": "Sélectionner un bulletin"
	}
}
</i18n>
