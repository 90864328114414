<template>
	<figure
		class="image box is-overflow-hidden is-paddingless is-shadowless"
		:class="dragOutClass"
		@dragenter.prevent="onDragEnter"
		@dragleave.prevent="onDragLeave"
		@dragover.prevent
		@drop.prevent="onDrop"
	>
		<img v-if="imgSrc" :src="imgSrc" />

		<figcaption class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center p-5">
			<p v-if="!imgSrc" class="icon is-large has-text-grey-lighter mb-2" key="icon">
				<span class="fas fa-2x fa-image"></span>
			</p>

			<button
				:disabled="disabled"
				class="button is-default mb-4"
				key="input-button"
				v-t="'actions.select'"
				@click.prevent="inputFile.click()"
			></button>

			<p class="is-size-7" key="help" v-t="'actions.drag.drop'"></p>

			<button
				v-if="imgSrc && imgSrc !== src"
				class="button is-warning is-outlined mt-4"
				key="reset-button"
				@click.prevent="onReset"
			>
				<span class="icon"><span class="fas fa-undo"></span></span>
				<span v-t="'actions.reset'"></span>
			</button>

			<input v-show="false" ref="inputFile" type="file" @change="onInputFiles" />
		</figcaption>
	</figure>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import { Field as NekoField, Input as NekoInput } from "@/components/form";

@Component({
	components: {
		NekoField,
		NekoInput
	}
})
export default class Picture extends Vue {
	@Prop({ type: Boolean, default: false })
	public readonly disabled!: boolean;

	@Prop({ default: null })
	public readonly src!: string | null;

	@Ref()
	public readonly inputFile!: HTMLInputElement;

	public readonly dragOverClass = "has-background-white-ter";
	public readonly dragOutClass = "has-background-white-bis";

	public imgSrc: string | null = this.src;

	private dragCounter = 0;

	public onDragEnter(event: DragEvent): void {
		if (this.disabled) {
			return;
		}

		this.dragCounter++;

		const target = event.currentTarget as HTMLDivElement;
		target.classList.remove(this.dragOutClass);
		target.classList.add(this.dragOverClass);
	}

	public onDragLeave(event: DragEvent): void {
		if (this.disabled) {
			return;
		}

		this.dragCounter--;

		if (!this.dragCounter) {
			const target = event.currentTarget as HTMLDivElement;
			target.classList.remove(this.dragOverClass);
			target.classList.add(this.dragOutClass);
		}
	}

	public onDrop(event: DragEvent): void {
		if (this.disabled) {
			return;
		}

		this.dragCounter = 0;
		const target = event.currentTarget as HTMLDivElement;
		target.classList.remove(this.dragOverClass);
		target.classList.add(this.dragOutClass);

		if (event.dataTransfer?.files) {
			this.updateImage(event.dataTransfer.files);
		}
	}

	public onInputFiles(event: InputEvent): void {
		const input = event.target as HTMLInputElement;

		if (input.files) {
			this.updateImage(input.files);
		}
	}

	public onReset(): void {
		this.imgSrc = this.src;
		this.$emit("reset");
	}

	@Watch("src")
	public onSrcChanged(src: string | null): void {
		this.imgSrc = src;
	}

	private updateImage(files: FileList) {
		if (files.length) {
			const file = files[0];
			const reader = new FileReader();
			reader.onload = loadEvent => (this.imgSrc = loadEvent.target?.result as string);
			reader.readAsDataURL(file);

			this.$emit("change", file);
		}
	}
}
</script>

<i18n>
{
	"fr": {
		"actions.drag.drop": "Ou la glisser directement dans cette zone.",
		"actions.reset": "Réinitialiser",
		"actions.select": "Sélectionner une image"
	}
}
</i18n>
