<template>
	<neko-field :label="label" :for="id" :required="required" :horizontal="horizontal" :error="error">
		<textarea
			class="textarea"
			:id="id"
			:name="name"
			:required="required"
			:autofocus="autofocus"
			:autocomplete="autocomplete ? 'on' : 'off'"
			:disabled="disabled || sending"
			:placeholder="placeholder"
			:value="value"
			@input="onInput($event)"
			:class="{ 'is-danger': error }"
			:rows="rows"
		></textarea>

		<slot v-for="(slot, name) in $slots" :name="name" :slot="name"></slot>
	</neko-field>
</template>

<script lang="ts">
import Component from "vue-class-component";
import { TextareaComponent } from "@emasofts/common-vuejs-form";
import NekoField from "./Field.vue";
import { Prop } from "vue-property-decorator";

@Component({
	components: {
		NekoField
	}
})
export default class Textarea extends TextareaComponent {
	@Prop()
	public readonly rows!: number | string;
}
</script>
