import { Module } from "vuex";
import { NekoState } from "@/lib/vuex/typings";
import { LoginState } from "@/lib/keycloak/typings/LoginState";
import { Cabinet } from "@/lib/keycloak/typings/EmaSoftsToken";

function setCabinet(state: LoginState, cabinet: Cabinet) {
	if (!state?.token?.cabinets?.length) {
		return;
	}

	localStorage.setItem("cabinet", JSON.stringify(cabinet));

	state.cabinet = cabinet;
}

export default {
	namespaced: true,

	state: {
		authenticated: false,
		authenticating: false,
		currentUser: null,
		roles: [],
		cabinet: null,
		token: null,
		tokenRaw: null
	},

	getters: {
		currentUser: state => state.currentUser,
		authenticated: state => state.authenticated,
		authenticating: state => state.authenticating,
		roles: state => state.roles,
		cabinets: state => state.token?.cabinets || [],
		cabinet: state => state?.cabinet,
		hasRoles: state => (rolesCheck: string[]) => {
			let canContinue = true;

			for (let i = 0; rolesCheck && canContinue && i < rolesCheck.length; i++) {
				canContinue = canContinue && state.roles?.findIndex(r => r === rolesCheck[i]) !== -1;
			}

			return canContinue;
		}
	},

	mutations: {
		setCurrentUser(state, currentUser) {
			state.currentUser = currentUser;
		},

		setAuthenticated(state, authenticated) {
			state.authenticated = authenticated;
		},

		setAuthenticating(state, authenticating) {
			state.authenticating = authenticating;
		},

		setRoles(state, roles) {
			state.roles = roles || [];
		},

		setTokenRaw(state, tokenRaw) {
			state.tokenRaw = tokenRaw;
		},

		setToken(state, token) {
			state.token = token;

			if (!state || !state.token) {
				localStorage.removeItem("cabinet");
				return;
			}

			if (state.token.cabinets?.length === 1) {
				setCabinet(state, state.token.cabinets[0]);
			} else {
				const oldCabinetString = localStorage.getItem("cabinet");

				if (!oldCabinetString) {
					return;
				}

				const cabinet: Cabinet = JSON.parse(oldCabinetString);

				if (cabinet && state.token.cabinets.findIndex(c => c.id === cabinet.id) !== -1) {
					state.cabinet = JSON.parse(oldCabinetString);
				} else {
					localStorage.removeItem("cabinet");
				}
			}
		},

		setCabinet
	}
} as Module<LoginState, NekoState>;
