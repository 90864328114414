import router from "@/lib/router";
import i18n from "@/lib/i18n";
import breadcrumb from "@/lib/breadcrumb";
import menu from "@/lib/menu";
import vuex from "@/lib/vuex";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.institutions": "Mes établissements",
			"breadcrumb.institutions.edit": "Edition d'établissement",
			"breadcrumb.institutions.view": "Page établissement",
			"breadcrumb.institutions.sharings": "Partage d'établissement",
			"breadcrumb.institutions.payslip.view": "Détails d'une analyse",
			"breadcrumb.institutions.detailsValues": "Données des salariés",
			"menu.institutions": "Mes établissements"
		});

		breadcrumb.add([
			{
				path: "/institutions",
				replacePath: "/institutions",
				name: "home>institutions"
			},
			{
				path: "/institutions/([0-9]+)",
				replacePath: "/institutions/{1}",
				name: "home>institutions>institutions.view"
			},
			{
				path: "/institutions/([0-9]+)/sharings",
				replacePath: "/institutions/{1}/sharings",
				name: "home>institutions>institutions.view>institutions.sharings"
			},
			{
				path: "/institutions/([0-9]+)/edit",
				replacePath: "/institutions/{1}/edit",
				name: "home>institutions>institutions.view>institutions.edit"
			},
			{
				path: "/institutions/([0-9]+)/payslip/([0-9]+)",
				replacePath: "/institutions/{1}/payslip/{2}",
				name: "home>institutions>institutions.view>institutions.payslip.view"
			},
			{
				path: "/institutions/([0-9]+)/detailsValues",
				replacePath: "/institutions/{1}/detailsValues",
				name: "home>institutions>institutions.view>institutions.detailsValues"
			}
		]);

		router.addRoutes([
			{
				path: "/institutions",
				name: "institutions",
				component: () => import("./vues/List.vue"),
				meta: {
					secured: true,
					roles: ["USER"],
					needContext: true
				}
			},
			{
				path: "/institutions/:id",
				name: "institutions.get",
				component: () => import("./vues/View.vue"),
				props: true,
				meta: {
					secured: true,
					roles: ["USER"],
					needContext: true
				}
			},
			{
				path: "/institutions/:id/sharings",
				name: "institutions.sharings",
				component: () => import("./vues/Sharings.vue"),
				props: true,
				meta: {
					secured: true,
					roles: ["USER"],
					needContext: true
				}
			},
			{
				path: "/institutions/:id/edit",
				name: "institutions.edit",
				component: () => import("./vues/Edit.vue"),
				props: true,
				meta: {
					secured: true,
					roles: ["USER"],
					needContext: true
				}
			},
			{
				path: "/institutions/:id/detailsValues",
				name: "institutions.detailsValues",
				component: () => import("./detailsValuesEditor/vues/DetailsValuesEditor.vue"),
				props: true,
				meta: {
					secured: true,
					roles: ["USER"],
					needContext: true
				}
			}
		]);

		menu.addEntry({
			label: i18n.t("menu.institutions") as string,
			icon: "fa fa-building",
			location: { name: "institutions" },
			roles: ["USER"],
			order: 1,
			get enabled() {
				return !!vuex.getters["context/audit"];
			}
		});
	}
};
