import Vue from "vue";

function filter(value: number, decimals: number = 0) {
	const pow = Math.pow(10, decimals);

	return Math.trunc(value * pow) / pow;
}

export default {
	install() {
		Vue.filter("trunc", filter);
	}
};

export { filter };
