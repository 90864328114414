import { ActionContext, Module } from "vuex";
import { NekoState } from "@/lib/vuex/typings";

import UsersEndpoint from "./endpoints/UsersEndpoint";
import GroupsEndpoint from "./endpoints/GroupsEndpoint";
import UserRepresentation from "./models/UserRepresentation";
import { ApiState } from "./typings/ApiState";
import { keycloak } from "..";
import { GroupRepresentation } from "./models/GroupRepresentation";

export type GetClients = () => Promise<GroupRepresentation[]>;
export type GetClient = (id: string) => Promise<GroupRepresentation>;

export default {
	namespaced: true,
	state: {
		users: new Map(),
		clients: new Map()
	},
	actions: {
		loadUserAsync,
		getClients,
		getClient
	},
	getters: {
		getUsers
	},
	mutations: {
		setUser,
		deleteUser,
		setClient
	}
} as Module<ApiState, NekoState>;

/**
 * Ajoute l'utilisateur `user` à la Map `state.users`.
 * @param state
 * @param user
 */
function setUser(state: ApiState, user: UserRepresentation) {
	if (user) {
		const users = new Map(state.users);
		users.set(user.id, user);
		state.users = users;
	}
}

/**
 * Obtient la Map `state.users`.
 * @param state
 */
function getUsers(state: ApiState): Map<string, UserRepresentation> {
	return state.users;
}

/**
 * Charge l'utilisateur avec l'id `id` et déclenche la mise à jour du state.
 * @param context
 * @param id
 */
async function loadUserAsync(
	context: ActionContext<ApiState, NekoState>,
	id: string
): Promise<UserRepresentation | undefined> {
	let user = context.state.users.get(id);

	if (!user) {
		const usersEndpoint = new UsersEndpoint(keycloak);

		context.commit("setUser", { id });

		try {
			user = await usersEndpoint.get(id);
			context.commit("setUser", user);
		} catch (error) {
			context.commit("deleteUser", id);
		}
	}

	return user;
}

/**
 * Supprime, s'il existe, l'utilisateur avec l'id `id` de la Map `state.users`.
 * @param state
 * @param id
 */
function deleteUser(state: ApiState, id: string) {
	if (state.users.has(id)) {
		const users = new Map(state.users);
		users.delete(id);
		state.users = users;
	}
}

/**
 * Récupère la totalité des clients dans Keycloak
 *
 * @param context Context pour récupérer le state
 * @returns La liste des clients que l'on a dans Keycloak
 */
async function getClients(context: ActionContext<ApiState, NekoState>) {
	if (context.state.clients.size === 0) {
		const clients = await new GroupsEndpoint(keycloak).search("cabinet:true");

		for (const client of clients) {
			if (!client.id) {
				continue;
			}

			context.commit("setClient", client);
		}
	}

	return convertMapValueAsList(context.state.clients);
}

/**
 * Récupère un Client en fonction d'un ID
 *
 * @param context Contexte pour récupérer le state
 * @param clientId id du client
 * @returns Le client dans Keycloak
 */
async function getClient(context: ActionContext<ApiState, NekoState>, clientId: string) {
	const clientState = context.state.clients.get(clientId);

	if (clientState) {
		return clientState;
	}

	const client = await new GroupsEndpoint(keycloak).get(clientId);

	context.commit("setClient", client);

	return client;
}

/**
 * Mets en cache le client donné
 *
 * @param state Le state pour l'api
 * @param client Le client à persister en cache
 */
function setClient(state: ApiState, client: GroupRepresentation) {
	if (!client.id) {
		return;
	}

	state.clients.set(client.id, client);
}

/**
 * Renvoie les values de la Map en tableau.
 *
 * @param map Une Map à convertir
 * @returns Les valeurs contenus dans la map en tableau
 */
function convertMapValueAsList<T>(map: Map<any, T>) {
	const accumulator: T[] = [];

	map.forEach((v: T) => accumulator.push(v));

	return accumulator;
}
