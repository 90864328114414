import router from "@/lib/router";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";
import menu from "@/lib/menu";

import ReportList from "./vues/ReportList.vue";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.reports": "Génération de rapport (archive)",
			"breadcrumb.audit": "Rapport d'audit (archive)",
			"menu.reports": "Génération de rapport (archive)"
		});

		breadcrumb.add([
			{
				path: "/reports",
				name: "home>reports"
			}
		]);

		router.addRoutes([
			{
				name: "reports",
				path: "/reports",
				component: ReportList,
				meta: {
					secured: true
				}
			}
		]);

		menu.addEntry({
			icon: "fa fa-file-pdf",
			label: i18n.t("menu.reports") as string,
			location: {
				name: "reports"
			},
			get active() {
				return router.currentRoute.name === "reports";
			},
			order: 6
		});
	}
};
