import router from "@/lib/router";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";
import menu from "@/lib/menu";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.test.extract": "Test d'extraction de bulletins"
		});

		breadcrumb.add([
			{
				path: "/test/extract",
				name: "home>test.extract"
			}
		]);

		router.addRoutes([
			{
				path: "/test/extract",
				name: "test.extract",
				component: () => import("./vues/Index.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			}
		]);

		menu.addEntry({
			label: i18n.t("breadcrumb.test.extract") as string,
			icon: "fa fa-file-export",
			location: { name: "test.extract" },
			roles: ["DATABASE_ADMINISTRATOR"],
			order: 100
		});
	}
};
