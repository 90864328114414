<template>
	<span class="buttons is-flex-wrap-nowrap">
		<button v-if="!fieldEditing" class="button is-small" @click="$emit('start-edit')">
			<span class="icon"><span class="fas fa-pencil-alt"></span></span>
		</button>

		<button v-if="fieldEditing" class="button is-primary is-small" @click="$emit('validate')">
			<span class="icon"><span class="fas fa-check"></span></span>
		</button>

		<button v-if="fieldEditing" class="button is-danger is-outlined is-small" @click="$emit('end-edit')">
			<span class="icon"><span class="fas fa-times"></span></span>
		</button>
	</span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class EditButtons extends Vue {
	@Prop({
		type: Boolean,
		required: true
	})
	public readonly fieldEditing!: boolean;
}
</script>
