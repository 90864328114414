<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

@Component
export default class DropdownComponent extends Vue {
	@Prop({
		default: "hover"
	})
	public readonly trigger!: "click" | "hover";

	@Prop({
		default: false
	})
	public readonly disabled!: boolean;

	@Prop({
		default: false,
		type: Boolean
	})
	public readonly closeOnChange!: boolean;

	@Ref()
	private readonly dropdownElement!: HTMLDivElement;

	@Ref()
	private readonly triggerElement!: HTMLDivElement;

	private show: boolean = false;

	public forceClose(): void {
		this.show = false;
		window.removeEventListener("click", this.onClickout);
	}

	private onClickout(e?: MouseEvent) {
		if (!this.show) {
			return;
		}

		if (!this.dropdownElement) {
			window.removeEventListener("click", this.onClickout);
			return;
		}

		const dropdownRect = this.dropdownElement.getBoundingClientRect();
		const triggerRect = this.triggerElement.getBoundingClientRect();

		const isOutOfElements = e && (
			e.y < dropdownRect.y ||
			e.y > dropdownRect.y + dropdownRect.height ||
			e.x < dropdownRect.x ||
			e.x > dropdownRect.x + dropdownRect.width
		);

		const isOutOfTrigger = e && (
			e.y < triggerRect.y ||
			e.y > triggerRect.y + triggerRect.height ||
			e.x < triggerRect.x ||
			e.x > triggerRect.x + triggerRect.width
		);

		if (isOutOfElements && isOutOfTrigger) {
			this.forceClose();
		}
	}

	private onClick() {
		if (this.trigger === "click") {
			this.show = !this.show;

			if (this.show) {
				window.addEventListener("click", this.onClickout);
			}
		}
	}
}
</script>

<template>
	<div
		class="dropdown"
		:class="{ 'is-hoverable': disabled !== true && trigger === 'hover', 'is-active': disabled !== true && show }"
	>
		<div ref="triggerElement" @click.prevent="onClick" class="dropdown-trigger">
			<slot name="trigger"></slot>
		</div>
		<div ref="dropdownElement" class="dropdown-menu">
			<div class="dropdown-content" @click="closeOnChange && forceClose()">
				<slot>
					<div class="dropdown-item">
						<p v-t="'no.item'"></p>
					</div>
				</slot>
			</div>
		</div>
	</div>
</template>
