import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";
import router from "@/lib/router";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.controls.content": "Contrôles de contenu",
			"breadcrumb.controls.content.create": "Création d'un contrôle de contenu",
			"breadcrumb.controls.content.edit": "Edition d'un contrôle de contenu",
			"breadcrumb.controls.content.not.validated": "Contrôles de contenu non validés",
			"breadcrumb.controls.context": "Contrôle de contexte",
			"breadcrumb.controls.context.create": "Création d'un contrôle de contexte",
			"breadcrumb.controls.context.edit": "Edition d'un contrôle de contexte",
			"breadcrumb.controls.context.not.validated": "Contrôles de contexte non validés",
			"breadcrumb.controls.presence": "Contrôle de présence",
			"breadcrumb.controls.presence.create": "Création d'un contrôle de présence",
			"breadcrumb.controls.presence.edit": "Edition d'un contrôle de présence",
			"breadcrumb.controls.presence.not.validated": "Contrôles de présence non validés",
			"menu.controls.content": "Contrôle de contenu",
			"menu.controls.content.notValidated": "Contrôles de contenu à valider",
			"menu.controls.context": "Contrôle de contexte",
			"menu.controls.context.notValidated": "Contrôle de contexte à valider",
			"menu.controls.presence": "Contrôle de présence",
			"menu.controls.presence.notValidated": "Contrôle de présence à valider"
		});
		breadcrumb.add([
			{
				path: "/database/controls/content",
				name: "home>database>controls.content"
			},
			{
				path: "/database/controls/content/create",
				name: "home>database>controls.content>controls.content.create"
			},
			{
				path: "/database/controls/content/([0-9]+)/edit",
				replacePath: "/database/controls/content/{1}/edit",
				name: "home>database>controls.content>controls.content.edit"
			},
			{
				path: "/database/controls/content/notValidated",
				name: "home>database>controls.content.not.validated"
			},
			{
				path: "/database/controls/context",
				name: "home>database>controls.context"
			},
			{
				path: "/database/controls/context/create",
				name: "home>database>controls.context>controls.context.create"
			},
			{
				path: "/database/controls/context/edit/([0-9]+)",
				replacePath: "/database/controls/context/edit/{0}",
				name: "home>database>controls.context>controls.context.edit"
			},
			{
				path: "/database/controls/context/notValidated",
				name: "home>database>controls.context.not.validated"
			},
			{
				path: "/database/controls/presence",
				name: "home>database>controls.presence"
			},
			{
				path: "/database/controls/presence/create",
				name: "home>database>controls.presence>controls.presence.create"
			},
			{
				path: "/database/controls/presence/edit/([0-9]+)",
				replacePath: "/database/controls/presence/edit/{0}",
				name: "home>database>controls.presence>controls.presence.edit"
			},
			{
				path: "/database/controls/presence/notValidated",
				name: "home>database>controls.presence.not.validated"
			}
		]);

		router.addRoutes([
			/*
			 * Contrôle de contenu
			 */
			{
				name: "controls.content",
				path: "/database/controls/content",
				component: () => import("./content/vues/List.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.content.create",
				path: "/database/controls/content/create",
				component: () => import("./content/vues/Add.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.content.edit",
				path: "/database/controls/content/:id/edit",
				component: () => import("./content/vues/Edit.vue"),
				props: true,
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.content.not.validated",
				path: "/database/controls/content/notValidated",
				component: () => import("./content/vues/ListNotValidated.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},

			/*
			 * Contrôle de contexte
			 */
			{
				name: "controls.context",
				path: "/database/controls/context",
				component: () => import("./context/vues/List.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.context.create",
				path: "/database/controls/context/create",
				component: () => import("./context/vues/Add.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.context.edit",
				path: "/database/controls/context/edit/:id",
				component: () => import("./context/vues/Edit.vue"),
				props: true,
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.context.not.validated",
				path: "/database/controls/context/notValidated",
				component: () => import("./context/vues/ListNotValidated.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},

			/*
			 * Contrôle de présence
			 */
			{
				name: "controls.presence",
				path: "/database/controls/presence",
				component: () => import("./presence/vues/List.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.presence.create",
				path: "/database/controls/presence/create",
				component: () => import("./presence/vues/Add.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.presence.edit",
				path: "/database/controls/presence/edit/:id",
				component: () => import("./presence/vues/Edit.vue"),
				props: true,
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.presence.not.validated",
				path: "/database/controls/presence/notValidated",
				component: () => import("./presence/vues/ListNotValidated.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			}
		]);
	}
};
