import { Component, Ref, Vue } from "vue-property-decorator";

import { Modal as NekoModal } from "@/components/modal";

@Component({
	components: {
		NekoModal
	}
})
export default class ErrorModal extends Vue {
	@Ref()
	public readonly modal?: NekoModal;

	public error: string | null = null;

	public show(error: string): void {
		this.error = error;
		this.modal?.show();
	}
}
