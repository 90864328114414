<template>
	<div class="field">
		<neko-field v-bind="{ ...$props, ...$attrs }" :error="error || testError" :for="id" v-on="$listeners">
			<template v-if="!helpDisabled" #label>
				<span  class="icon is-clickable has-tooltip-multi-line has-tooltip-top has-tooltip-right" :data-tooltip="helpTooltip">
					<span class="fa fa-question-circle"></span>
				</span>
				{{ label }}
			</template>
			<template>
				<div class="formula-editor">
					<textarea
						ref="textarea"
						v-bind="$attrs"
						class="textarea"
						:class="{ 'is-danger': error || testError, 'is-success': testSuccess }"
						contenteditable
						:disabled="disabled || sending || testing"
						:placeholder="placeholder"
						:required="required"
						:value="value"
						@focus="onFocus"
						@input="onInput"
						@keydown="onKeyDown"
					></textarea>
					<div class="textarea is-dummy">
						<span ref="dummy"></span>
					</div>
					<transition name="fade">
						<suggestions-provider
							v-if="loadingSuggestions || suggestions.length"
							ref="suggestionsProvider"
							:loading="loadingSuggestions"
							:suggestions="suggestions"
							:top="suggestionsProviderTop"
							:left="suggestionsProviderLeft"
							@focus="onFocus"
							@input="validSuggestion"
						/>
					</transition>
				</div>
			</template>

			<template #addons-right-test-button>
				<button
					class="button"
					:class="{ 'is-danger': testError, 'is-loading': testing, 'is-success': testSuccess }"
					:data-tooltip="buttonTooltip"
					type="button"
					@click.stop="test"
				>
					<template v-if="testError">
						<span class="icon" key="test-label-icon"><span class="fas fa-frown"></span></span>
						<span v-if="!iconButton" key="test-label-error" v-t="'action.test.error'"></span>
					</template>

					<template v-else-if="testSuccess">
						<span class="icon" key="test-label-icon"><span class="fas fa-smile"></span></span>
						<span v-if="!iconButton" key="test-label-success" v-t="'action.test.success'"></span>
					</template>

					<template v-else>
						<span class="icon" key="test-label-icon"><span class="fas fa-vial"></span></span>
						<span v-if="!iconButton" key="test-label-default" v-t="'action.test.default'"></span>
					</template>
				</button>
			</template>

			<slot v-for="(slot, name) in $slots" :name="name" :slot="name"></slot>

			<template #help>
				<slot name="help"></slot>
			</template>
		</neko-field>

		<pay-slip-selector-modal ref="paySlipSelectorModal" @close="onClose" @validate="onValidate" />
	</div>
</template>

<style lang="sass" scoped>
.formula-editor
	position: relative

	.textarea.is-dummy
		height: auto
		left: 0
		max-width: 100%
		min-height: 0
		min-width: 0
		position: absolute
		top: 0
		visibility: hidden
		width: auto
		white-space: pre-wrap
		z-index: 0

		> span
			word-break: break-word
</style>

<script lang="ts">
import { HtmlInputForm } from "@emasofts/common-vuejs-form";
import { Component, Emit, Prop, Ref } from "vue-property-decorator";

import AdditionalDataApi from "@/lib/apis/operations/AdditionalDataResourceApi";
import EmployeesDetailsApi from "@/lib/apis/operations/EmployeesDetailsResourceApi";
import FormulasApi from "@/lib/apis/operations/FormulasResourceApi";
import InstitutionDetailsApi from "@/lib/apis/operations/InstitutionsDetailsResourceApi";
import ReferentialPayApi from "@/lib/apis/operations/ReferentialPayResourceApi";

import { Suggestion } from "./typings";
import PaySlipSelectorModal from "./PaySlipSelectorModal.vue";
import SuggestionsProvider from "./SuggestionsProvider.vue";
import NekoField from "../Field.vue";

const VARIABLE_PREFIXES: VariableType[] = ["donneeAnnexe", "employee", "institution", "ligne"];
const VARIABLE_PARTS_SEPARATOR = ":";

@Component({
	components: {
		NekoField,
		PaySlipSelectorModal,
		SuggestionsProvider
	}
})
export default class FormulaEditor extends HtmlInputForm {
	public declare readonly disabled: boolean;
	public declare readonly error: string;
	public declare readonly sending: boolean;
	public declare readonly value: string;
	public declare readonly placeholder: string;
	public declare readonly label: string;

	@Prop({ type: Boolean })
	public readonly helpDisabled!: boolean;

	@Prop({ type: Boolean })
	public readonly iconButton!: boolean;

	@Ref()
	public readonly dummy!: HTMLSpanElement;

	@Ref()
	public readonly paySlipSelectorModal!: PaySlipSelectorModal;

	@Ref()
	public readonly suggestionsProvider!: SuggestionsProvider;

	@Ref()
	public readonly textarea!: HTMLTextAreaElement;

	public loadingSuggestions = false;
	public suggestions: Suggestion[] = [];
	public suggestionsProviderLeft: number = 0;
	public suggestionsProviderTop: number = 0;
	public testing = false;
	public testError = "";
	public testSuccess = false;

	private focusedSuggestion: Suggestion | null = null;
	private inputTimeout: number | null = null;

	public get buttonTooltip(): string | undefined {
		if (!this.iconButton) {
			return;
		}

		if (this.testError) {
			return this.$t("action.test.error").toString();
		} else if (this.testSuccess) {
			return this.$t("action.test.success").toString();
		} else {
			return this.$t("action.test.default").toString();
		}
	}

	public get helpTooltip() {
		if (this.helpDisabled) {
			return null;
		}

		return [
			this.$t("help.navigation", ["↑", "↓"]),
			this.$t("help.show", ["Ctrl", this.$t("key.space")]),
			this.$t("help.valid", [this.$t("key.enter"), "Tab", this.$t("key.end")])
		].join("\n");
	}

	public created(): void {
		document.addEventListener("click", this.clearSuggestions);
	}

	public destroyed(): void {
		document.removeEventListener("click", this.clearSuggestions);
	}

	public test(): void {
		this.testError = "";
		this.testing = true;
		this.paySlipSelectorModal.show();
	}

	public onClose(canceled: boolean): void {
		if (canceled) {
			this.testing = false;
			this.testError = "";
			this.testSuccess = false;
		}
	}

	public onFocus(suggestion: Suggestion): void {
		this.focusedSuggestion = suggestion;
	}

	public async onInput(): Promise<void> {
		this.testError = "";
		this.testSuccess = false;
		this.focusedSuggestion = null;
		this.input();
		const variable = this.getVariable();

		if (this.inputTimeout) {
			clearTimeout(this.inputTimeout);
		}

		if (!this.textarea.value) {
			this.suggestions = VARIABLE_PREFIXES.map(code => ({ code }));
		} else if (!variable.prefix && variable.code) {
			this.suggestions = VARIABLE_PREFIXES.filter(prefix =>
				prefix.toLowerCase().includes(variable.code.toLowerCase())
			).map(code => ({ code }));
		} else if (variable.code) {
			const LIMIT = 5;

			this.inputTimeout = setTimeout(async () => {
				try {
					this.loadingSuggestions = true;

					switch (variable.prefix) {
						case "donneeAnnexe":
							this.suggestions = (
								await AdditionalDataApi.list(null, null, LIMIT, variable.code, null, null)
							).data.map(data => ({ code: data.code, label: data.label }));
							break;
						case "employee":
							this.suggestions = (
								await EmployeesDetailsApi.list(null, null, LIMIT, variable.code, null, null)
							).data.map(details => ({ code: details.code, label: details.label }));
							break;
						case "institution":
							this.suggestions = (
								await InstitutionDetailsApi.list(null, null, LIMIT, variable.code, null, null)
							).data.map(details => ({ code: details.code, label: details.label }));
							break;
						case "ligne":
							const filters = ["code,EXIST,TRUE"];

							this.suggestions = (
								await ReferentialPayApi.list(null, null, LIMIT, variable.code, null, filters)
							).data.map(referentialPay => ({ code: referentialPay.code, label: referentialPay.label }));

							const endsWithColon = variable.code.endsWith(VARIABLE_PARTS_SEPARATOR);
							const columns = ["base", "taux:salarial", "taux:patronal", "montant:salarial", "montant:patronal"];
							const endsWithColumn = columns.some(column => variable.code.endsWith(column + VARIABLE_PARTS_SEPARATOR));
							const codeParts = variable.code.split(VARIABLE_PARTS_SEPARATOR);
							const lastPart = codeParts[codeParts.length - 1];
							const matchingColumns = columns.filter(column => column.toLowerCase().includes(lastPart.toLowerCase()));

							if (
								!this.suggestions.length &&
								((endsWithColon && !endsWithColumn) || (!endsWithColon && matchingColumns.length))
							) {
								this.suggestions.push(...matchingColumns.map(code => ({ code, append: true, last: true })));
							}

							break;
					}
				} finally {
					this.loadingSuggestions = false;
				}
			}, 250);
		} else {
			this.clearSuggestions();
		}
	}

	public onKeyDown(event: KeyboardEvent): void {
		const isValidationKey =
			event.key === "End" ||
			event.key === "Enter" ||
			event.key === "Tab";

		if (event.key === "Enter") {
			event.preventDefault();
		}

		if (isValidationKey && this.suggestions.length) {
			if (event.key === "Tab") {
				event.preventDefault();
			}

			let suggestion = this.suggestions[0];

			if (this.focusedSuggestion) {
				suggestion = this.focusedSuggestion;
			}

			this.validSuggestion(suggestion);
		} else if (event.key === "ArrowDown" && this.suggestionsProvider) {
			event.preventDefault();
			this.suggestionsProvider.focusNext();
		} else if (event.key === "ArrowUp" && this.suggestionsProvider) {
			event.preventDefault();
			this.suggestionsProvider.focusPrevious();
		} else if (event.key === "Escape") {
			this.clearSuggestions();
		} else if (event.key === " " && event.ctrlKey) {
			this.onInput();
		}
	}

	public async onValidate(scanSlip: number): Promise<void> {
		try {
			this.paySlipSelectorModal.hide();
			this.testing = true;
			await FormulasApi.test({ formula: this.value, scanSlip }, undefined, "none");
			this.testSuccess = true;
		} catch (error) {
			if (typeof error === "string") {
				this.testError = error;
			} else if (typeof error === "object") {
				this.testError = (error as any)["params.formula"];
			} else {
				this.testError = "unknown";
			}
		} finally {
			this.testing = false;
		}
	}

	public validSuggestion(suggestion: Suggestion, appendSeparator?: boolean): void {
		const variable = this.getVariable();
		const caretIndex = this.textarea.selectionEnd;

		if (this.textarea.value) {
			let replacement = suggestion.code;

			if (appendSeparator && !suggestion.last) {
				replacement += VARIABLE_PARTS_SEPARATOR;
			}

			if (suggestion.append) {
				let replacementStart = caretIndex;

				if (!variable.code.endsWith(VARIABLE_PARTS_SEPARATOR)) {
					const codeParts = variable.code.split(VARIABLE_PARTS_SEPARATOR);
					const lastPart = codeParts[codeParts.length - 1];

					replacementStart = caretIndex - lastPart.length;
				}

				this.textarea.setRangeText(replacement, replacementStart, caretIndex, "end");
			} else {
				this.textarea.setRangeText(replacement, caretIndex - variable.code.length, caretIndex, "end");
			}
		} else {
			this.textarea.value = suggestion.code;

			if (appendSeparator) {
				this.textarea.value += VARIABLE_PARTS_SEPARATOR;
			}
		}

		this.input();
		this.clearSuggestions();
	}

	private clearSuggestions(): void {
		this.suggestions = [];
	}

	private getVariable(): Variable {
		const caretIndex = this.textarea.selectionEnd;

		let prevSpaceIndex = -1;

		for (let i = 0; i > -1 && i < caretIndex; i = this.textarea.value.indexOf(" ", i + 1)) {
			prevSpaceIndex = i;
		}

		const rawVariable = this.textarea.value.substring(prevSpaceIndex, caretIndex).trim();
		const firstSeparatorIndex = rawVariable.search(VARIABLE_PARTS_SEPARATOR);
		const extractedPrefix = rawVariable.substr(0, firstSeparatorIndex);
		const code = rawVariable.substr(firstSeparatorIndex + 1);

		const variable: Variable = { code };

		if (VARIABLE_PREFIXES.some(prefix => prefix === extractedPrefix)) {
			variable.prefix = extractedPrefix as VariableType;
		}

		return variable;
	}

	@Emit()
	private input(): string {
		this.dummy.textContent = this.textarea.value.substring(0, this.textarea.selectionEnd);

		const dummyRects = this.dummy.getClientRects();
		const lastDummyRect = dummyRects[dummyRects.length - 1];
		const textareaRect = this.textarea.getBoundingClientRect();

		this.suggestionsProviderLeft = lastDummyRect.right - textareaRect.x + 5;
		this.suggestionsProviderTop = lastDummyRect.top - textareaRect.top - 5;

		return this.textarea.value;
	}
}

interface Variable {
	code: string;
	prefix?: VariableType;
}

type VariableType = "donneeAnnexe" | "employee" | "institution" | "ligne";
</script>

<i18n>
{
	"fr": {
		"action.test.default": "Tester la formule",
		"action.test.error": "Échec",
		"action.test.success": "Succès",
		"help.navigation": "Utilisez les touches {0} ou {1} de votre clavier pour naviguer dans les suggestions.",
		"help.show": "Utilisez les touches {0} + {1} de votre clavier pour afficher les suggestions.",
		"help.valid": "Utilisez les touches {0}, {1}, {2}, {3} ou {4} de votre clavier pour valider la suggestion.",
		"key.end": "Fin",
		"key.enter": "Entrée",
		"key.space": "Espace"
	}
}
</i18n>
