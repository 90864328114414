<template>
	<div>
		<form @submit.prevent="submit">
			<neko-card :clickable="false">
				<neko-input
					:error="errors['param.name']"
					:label="$t('label.name')"
					:required="true"
					:sending="sending"
					v-model="param.name"
				/>

				<neko-field :label="$t('table.title')">
					<template>
						<neko-data-list ref="dataList" :options="options" :data="getLines" class="mb-3">
							<template #actions>
								<button @click.prevent="addLine" class="button is-small is-primary is-outlined">
									<span class="icon">
										<span class="fa fa-plus"></span>
									</span>
									<span v-t="'actions.add'"></span>
								</button>
							</template>
							<template #default="{ element }">
								<td>
									<neko-select :simple="false" v-model="element.referentialPayId" :sending="sending">
										<option v-for="rp in referentialPays" :key="rp.id" :value="rp.id">{{ rp.label }}</option>
									</neko-select>
								</td>
								<td width="200" @click="toggle(element, 'presence')">
									<neko-input type="checkbox" v-model="element.presence" :sending="sending" />
								</td>
								<td width="200" @click="toggle(element, 'base')">
									<neko-input type="checkbox" v-model="element.base" :sending="sending" />
								</td>
								<td width="200" @click="toggle(element, 'rateEmployee')">
									<neko-input type="checkbox" v-model="element.rateEmployee" :sending="sending" />
								</td>
								<td width="200" @click="toggle(element, 'amountEmployee')">
									<neko-input type="checkbox" v-model="element.amountEmployee" :sending="sending" />
								</td>
								<td width="200" @click="toggle(element, 'rateEmployer')">
									<neko-input type="checkbox" v-model="element.rateEmployer" :sending="sending" />
								</td>
								<td width="200" @click="toggle(element, 'amountEmployer')">
									<neko-input type="checkbox" v-model="element.amountEmployer" :sending="sending" />
								</td>
								<td class="is-narrow">
									<button @click.prevent="removeLine(element)" class="button is-danger is-outlined">
										<span class="icon">
											<span class="fa fa-trash"></span>
										</span>
									</button>
								</td>
							</template>
						</neko-data-list>
					</template>

					<template #help>
						<p class="help" v-t="'help.referentials.1'"></p>
						<p class="help" v-t="'help.referentials.2'"></p>
					</template>
				</neko-field>

				<template #footer>
					<div class="card-footer-item">
						<neko-submit v-show="!profile" :sending="sending">
							<span class="icon">
								<span class="fa fa-plus"></span>
							</span>
							<span v-t="'actions.create'"></span>
						</neko-submit>

						<neko-submit v-show="profile" :sending="sending">
							<span class="icon">
								<span class="fa fa-pencil-alt"></span>
							</span>
							<span v-t="'actions.edit'"></span>
						</neko-submit>
					</div>
				</template>
			</neko-card>
		</form>
	</div>
	<!-- Fin des colonnes -->
</template>

<i18n>
{
	"fr": {
		"actions.add": "Ajouter",
		"actions.create": "Créer",
		"actions.edit": "Editer",
		"create.error": "Impossible de créer le profil.",
		"create.success": "Le profil a été créé avec succés.",
		"edit.error": "Impossible d'éditer le profil.",
		"edit.success": "Le profil a été édité avec succés.",
		"help.referentials.1": "Par défaut un profil d'analyse inclut tous les réferentiels de paie ainsi que tous les contrôles de présences.",
		"help.referentials.2": "Cette liste permet de préciser quelles colonnes de référentiels de paie et/ou quels contrôles de présences doivent être exclus d'une analyse effectuée avec ce profil.",
		"label.name": "Nom du profil",
		"table.columns.amount.employee": "Montant salarial",
		"table.columns.amount.employer": "Montant patronal",
		"table.columns.base": "Base",
		"table.columns.presence": "Contrôle de présence",
		"table.columns.rate.employee": "Taux salarial",
		"table.columns.rate.employer": "Taux patronal",
		"table.columns.referential.pay": "Référentiel de paie",
		"table.title": "Référentiels à exclure"
	}
}
</i18n>

<script lang="ts">
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { DataListOptions, DataListParams, DataListResponse } from "@emasofts/common-vuejs-datalist";
import * as _ from "lodash";

import {
	ControlExcludeProfileRepresentation,
	ControlExcludeProfileParam,
	LineExcludeProfileParam,
	ReferentialPayRepresentation
} from "@/lib/apis/models";
import ControlExcludeProfilesResourceApi from "@/lib/apis/operations/ControlExcludeProfilesResourceApi";
import ReferentialPayResourceApi from "@/lib/apis/operations/ReferentialPayResourceApi";

import { Card as NekoCard } from "@/components/card";
import { DataList as NekoDataList } from "@/components/dataList";
import { Field as NekoField, Input as NekoInput, Select as NekoSelect, Submit as NekoSubmit } from "@/components/form";

@Component({
	components: {
		NekoCard,
		NekoDataList,
		NekoField,
		NekoInput,
		NekoSelect,
		NekoSubmit
	}
})
export default class AddEditController extends Vue {
	@Prop()
	private id!: number;

	@Ref()
	private dataList!: NekoDataList<LineExcludeProfileParam>;

	private errors: any = {};
	private options: DataListOptions = {
		table: true,
		displayLengthDefault: 20,
		columns: [
			{ name: this.$t("table.columns.referential.pay") as string },
			{ name: this.$t("table.columns.presence") as string },
			{ name: this.$t("table.columns.base") as string },
			{ name: this.$t("table.columns.rate.employee") as string },
			{ name: this.$t("table.columns.amount.employee") as string },
			{ name: this.$t("table.columns.rate.employer") as string },
			{ name: this.$t("table.columns.amount.employer") as string },
			{ name: " " }
		]
	};
	private param: ControlExcludeProfileParam = {
		name: "",
		lines: []
	};
	private profile: ControlExcludeProfileRepresentation | null = null;
	private referentialPays: ReferentialPayRepresentation[] = [];
	private sending: boolean = false;

	private async created() {
		this.referentialPays = await ReferentialPayResourceApi.listAll();

		if (!this.id) {
			return;
		}

		this.profile = await ControlExcludeProfilesResourceApi.get(this.id);
		this.param = {
			name: this.profile.name,
			lines: []
		};

		for (const line of this.profile.lines || []) {
			if (!line.referentialPay) {
				continue;
			}

			this.param.lines.push({
				referentialPayId: line.referentialPay.id,
				presence: line.presence,
				base: line.base,
				rateEmployee: line.rateEmployee,
				amountEmployee: line.amountEmployee,
				rateEmployer: line.rateEmployer,
				amountEmployer: line.amountEmployer
			});
		}
		this.dataList.refresh();
	}

	private addLine() {
		this.param.lines.push({
			referentialPayId: -1,
			presence: false,
			base: false,
			rateEmployee: false,
			amountEmployee: false,
			rateEmployer: false,
			amountEmployer: false
		});
		this.dataList.refresh();
	}

	private removeLine(line: LineExcludeProfileParam) {
		const index = this.param.lines.findIndex(l => l.referentialPayId === line.referentialPayId);

		if (index !== -1) {
			this.param.lines.splice(index, 1);
			this.dataList.refresh();
		}
	}

	private async submit() {
		try {
			this.sending = true;
			if (this.profile) {
				await ControlExcludeProfilesResourceApi.update(this.profile.id, this.param);
				this.$message.success(this.$t("edit.success") as string);
			} else {
				await ControlExcludeProfilesResourceApi.create(this.param);
				this.$message.success(this.$t("create.success") as string);
			}
			this.$router.push({ name: "controlExcludeProfiles" });
		} catch (e) {
			this.errors = e;
			if (this.profile) {
				this.$message.error(this.$t("edit.error") as string);
			} else {
				this.$message.error(this.$t("create.error") as string);
			}
		} finally {
			this.sending = false;
		}
	}

	private async getLines(params: DataListParams) {
		let lines = _.sortBy(this.param.lines, "referentialPayId");

		if (params.search) {
			const idsReferentialSearched = this.referentialPays
				.filter(rp => rp.label.search(new RegExp(`.*${params.search}.*`, "gi")) !== -1)
				.map(rp => rp.id);

			lines = lines.filter(line => idsReferentialSearched.indexOf(line.referentialPayId) !== -1);
		}

		return {
			draw: params.draw,
			recordsTotal: lines.length,
			data: lines
		} as DataListResponse<LineExcludeProfileParam>;
	}

	private toggle(element: LineExcludeProfileParam, column: Exclude<keyof LineExcludeProfileParam, "referentialPayId">) {
		element[column] = !element[column];
	}
}
</script>
