import { VueConstructor } from "vue";
import {
	EmployeeDetailsValueRepresentation,
	InstitutionDetailsValueRepresentation,
	KeyValueSuggestionRepresentationString
} from "@/lib/apis/models";

import { filter as filterReadable } from "./ReadableFilter";
import { filter as filterNumber } from "./NumberFilter";

function filter(
	detailsValue?: EmployeeDetailsValueRepresentation | InstitutionDetailsValueRepresentation | null,
	type: "boolean" | "number" | "numeric" | null = null
): string {
	if (!detailsValue) {
		return "";
	}

	let valueReadable = detailsValue.value;

	if (detailsValue.suggestions && detailsValue.suggestions.length > 0) {
		const suggestion: KeyValueSuggestionRepresentationString | undefined = detailsValue.suggestions
			.filter(s => !!(s as KeyValueSuggestionRepresentationString).key)
			.map(s => s as KeyValueSuggestionRepresentationString)
			.find((s: KeyValueSuggestionRepresentationString) => s.key === valueReadable);

		if (suggestion) {
			valueReadable = suggestion.value;

			if (detailsValue.code === "idcc") {
				valueReadable = `${detailsValue.value} - ${valueReadable}`;
			}
		}
	}

	if (detailsValue.type === "NUMERIC" || detailsValue.type === "PERCENTAGE") {
		if (detailsValue.value) {
			valueReadable = filterNumber(parseFloat(detailsValue.value));

			if (detailsValue.type === "PERCENTAGE") {
				valueReadable += "%";
			}
		}
	} else if (detailsValue.type === "BOOLEAN" && detailsValue.value) {
		valueReadable = filterReadable(parseInt(detailsValue.value, 10) === 1, "boolean");
	} else if (!valueReadable || valueReadable.length === 0) {
		valueReadable = "-";
	} else {
		if (valueReadable.search("=") !== -1) {
			valueReadable = valueReadable
				.split(";")
				.map(part => {
					const keyValue = part.split("=");
					return `${keyValue[0][0].toUpperCase()}${keyValue[0].slice(1)}: ${keyValue[1] || ""}`;
				})
				.join(", ");
		}
	}

	return valueReadable || "";
}

export default {
	install(Vue: VueConstructor) {
		Vue.filter("readableDetailsValue", filter);
	}
};

export { filter };
