import AxiosLib from "axios";

import i18n from "@/lib/i18n";
import app from "@/main";

declare let process: any;

i18n.mergeLocaleMessage("fr", {
	"request.not.authorized": "Vous n'êtes pas autorisé à effectuer cette requête.",
	"request.internal.error": "Une erreur du serveur est survenue.",
	"request.network.error": "Impossible de contacter le serveur dû à une erreur réseau.",
	"request.bad.request": "Une erreur est survenue lors de la requête au serveur."
});

const axios = AxiosLib.create({
	baseURL: process.env.VUE_APP_API_URL
});

axios.interceptors.request.use(config => {
	app.$Progress.start();
	return config;
});

axios.interceptors.response.use(response => {
	app.$Progress.finish();
	return response;
});

export { axios };
