<template>
	<router-link v-if="to && !dropdown && hasAccess" :to="to" class="navbar-item" :class="{ 'is-active': isCurrent }">
		<slot></slot>
	</router-link>

	<a v-else-if="link && !dropdown && hasAccess" class="navbar-item" @click="$emit('click')">
		<slot></slot>
	</a>

	<div v-else-if="!dropdown && hasAccess" class="navbar-item">
		<slot></slot>
	</div>

	<neko-dropdown
		v-else-if="dropdown && hasAccess"
		trigger="click"
		class="navbar-item has-dropdown"
		:class="{ 'is-active': isCurrent, 'is-right': dropdown === 'right' }"
	>
		<template #trigger>
			<a class="navbar-link">
				<slot></slot>
			</a>
		</template>
		<template>
			<slot name="dropdown"></slot>
		</template>
	</neko-dropdown>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import { State, Getter } from "vuex-class";

import { LoginState } from "@/lib/keycloak/typings/LoginState";

import { Dropdown as NekoDropdown } from "@/components/dropdown";

@Component({
	components: {
		NekoDropdown
	}
})
export default class NavbarItemComponent extends Vue {
	@Prop({ default: undefined })
	public to!: string;

	@Prop({ default: undefined })
	public dropdown: "right" | "left" | undefined;

	@Prop({ default: false, type: Boolean })
	public link!: boolean;

	@State("login")
	public login!: LoginState;

	@Getter("login/hasRoles")
	public hasRoles!: (roles: string[]) => boolean;

	public hasAccess: boolean = true;
	public authenticatedNeeded: boolean = false;
	public rolesNeeded: string[] = [];
	public showDropdown = false;

	public get dropdownOrientation() {
		let orientation = "";

		if (this.dropdown === "left") {
			orientation = "is-left";
		} else if (this.dropdown === "right") {
			orientation = "is-right";
		}

		return orientation;
	}

	public get isCurrent() {
		return this.$route.path === this.to;
	}

	private created() {
		if (this.to) {
			const routeInformation = this.$router.resolve(this.to);

			if (routeInformation && routeInformation.route.meta) {
				if (routeInformation.route.meta.roles) {
					this.rolesNeeded = routeInformation.route.meta.roles;
				}

				if (routeInformation.route.meta.secured) {
					this.authenticatedNeeded = routeInformation.route.meta.secured;
				}
			}
		}

		if (this.authenticatedNeeded || this.rolesNeeded.length > 0) {
			this.hasAccess = false;
			this.updateHasAccess();
		}
	}

	@Watch("login.roles")
	@Watch("login.authenticated")
	private updateHasAccess() {
		if (this.authenticatedNeeded) {
			if (this.login.authenticated) {
				if (this.rolesNeeded.length > 0) {
					this.hasAccess = this.hasRoles(this.rolesNeeded);
					this.$forceUpdate();
				} else {
					this.hasAccess = true;
					this.$forceUpdate();
				}
			} else {
				this.hasAccess = false;
				this.$forceUpdate();
			}
		}
	}
}
</script>
