<template>
	<div class="box p-0">
		<neko-data-list ref="dataList" :options="options" :data="getData">
			<template #actions>
				<router-link :to="{ name: 'controlExcludeProfiles.create' }" class="button is-small is-primary">
					<span class="icon">
						<span class="fa fa-plus"></span>
					</span>
					<span v-t="'actions.create'"></span>
				</router-link>
			</template>
			<template #default="{ element }">
				<td>
					{{ element.name }}
					<span v-show="element.default" class="tag is-dark" v-t="'profile.default'"></span>
				</td>
				<td class="is-narrow">
					<button
						class="button is-small is-danger is-outlined"
						:disabled="element.default"
						@click="deleteModal.show(element)"
						@mousedown.stop
					>
						<span class="icon">
							<span class="fa fa-trash"></span>
						</span>
						<span v-t="'actions.delete'"></span>
					</button>
				</td>
			</template>
		</neko-data-list>

		<neko-ask-modal ref="deleteModal" @validate="deleteProfile" />
	</div>
</template>

<i18n>
{
	"fr": {
		"actions.create": "Créer",
		"actions.delete": "Supprimer",
		"actions.edit": "Editer",
		"delete.error": "Impossible de supprimer le profil.",
		"delete.success": "Le profil a été supprimé avec succés.",
		"profile.default": "Profil par défaut",
		"table.columns.name": "Nom du profil",
		"title": "Profils d'exclusion"
	}
}
</i18n>

<script lang="ts">
import { Component, Ref, Vue } from "vue-property-decorator";
import { DataListParams, DataListOptions } from "@emasofts/common-vuejs-datalist";

import { ControlExcludeProfileRepresentation } from "@/lib/apis/models";
import ControlExcludeProfilesResourceApi from "@/lib/apis/operations/ControlExcludeProfilesResourceApi";

import { DataList as NekoDataList } from "@/components/dataList";
import { AskModal as NekoAskModal } from "@/components/askModal";

@Component({
	components: {
		NekoAskModal,
		NekoDataList
	}
})
export default class ListController extends Vue {
	@Ref()
	private deleteModal!: NekoAskModal;

	@Ref()
	private dataList!: NekoDataList<ControlExcludeProfileRepresentation>;

	private options: DataListOptions = {
		table: true,
		infiniteScroll: true,
		columns: [{ name: this.$t("table.columns.name") as string }, { name: " " }],
		callbackClickRow: (element: ControlExcludeProfileRepresentation, event: MouseEvent) => {
			const route = this.$router.resolve({
				name: "controlExcludeProfiles.edit",
				params: { id: element.id.toString() }
			});

			if (event.ctrlKey || event.button === 1) {
				window.open(route.href, "_blank");
			} else {
				this.$router.push(route.location);
			}
		}
	};

	private getData(param: DataListParams) {
		return ControlExcludeProfilesResourceApi.list(
			param.draw,
			param.start,
			param.length,
			param.search,
			param.order,
			param.filter
		);
	}

	private async deleteProfile(profile: ControlExcludeProfileRepresentation) {
		try {
			await ControlExcludeProfilesResourceApi.delete(profile.id);
			this.$message.success(this.$t("delete.success") as string);
			this.dataList.refresh();
		} catch (e) {
			this.$message.error(this.$t("delete.error") as string);
		}
	}
}
</script>
