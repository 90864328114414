<template>
	<div class="suggestions-provider box has-background-white-bis p-1" :style="{ left: `${left}px`, top: `${top}px` }">
		<transition name="fade" mode="out-in">
			<span v-if="loading" class="icon"><span class="fas fa-circle-notch fa-spin"/></span>
			<ul v-else>
				<li
					v-for="(suggestion, suggestionIndex) in suggestions"
					:key="suggestion.code"
					class="suggestion field has-addons"
					:class="suggestionIndex === selectedIndex && 'is-focused'"
				>
					<span class="control is-expanded">
						<button class="button is-small is-fullwidth" @click.prevent.stop="$emit('input', suggestion)">
							<span v-if="suggestion.label">
								<span>{{ suggestion.label }}</span
								><br />
								<small class="has-text-grey">{{ suggestion.code }}</small>
							</span>
							<template v-else>{{ suggestion.code }}</template>
						</button>
					</span>

					<span v-if="!suggestion.last" class="control">
						<button class="button is-small" @click.prevent.stop="$emit('input', suggestion, true)">:</button>
					</span>
				</li>
			</ul>
		</transition>
	</div>
</template>

<style lang="sass" scoped>
@import "@/styles/variables.sass"

.suggestions-provider
	position: absolute
	transition-duration: 250ms
	transition-property: left, top
	transition-timing-function: ease-out
	z-index: 3

	.suggestion
		border-radius: $radius-rounded

		&.is-focused,
		button:focus
			box-shadow: 0 0 0 0.125em bulmaRgba($grey, 0.25)

		.control:nth-child(2)
			button
				height: 100%

		button
			background: transparent
			border: none
			height: auto
			width: 100%

			&:active,
			&.is-active
				background-color: $grey-lighter

			&:hover,
			&.is-hovered
				background-color: $grey-lightest
</style>

<script lang="ts">
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import { Suggestion } from "./typings";

@Component
export default class SuggestionsProvider extends Vue {
	@Prop({ required: true })
	private readonly suggestions!: Suggestion[];

	@Prop({ default: 0 })
	private readonly left!: number;

	@Prop({ default: 0 })
	private readonly top!: number;

	@Prop({ type: Boolean, default: false })
	private readonly loading!: boolean;

	private selectedIndex: number = -1;

	@Emit("focus")
	public focusNext(): Suggestion {
		this.selectedIndex++;

		if (this.selectedIndex >= this.suggestions.length) {
			this.selectedIndex = 0;
		}

		return this.suggestions[this.selectedIndex];
	}

	@Emit("focus")
	public focusPrevious(): Suggestion {
		this.selectedIndex--;

		if (this.selectedIndex < 0) {
			this.selectedIndex = this.suggestions.length - 1;
		}

		return this.suggestions[this.selectedIndex];
	}
}
</script>
