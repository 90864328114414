<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Model, Watch, Ref } from "vue-property-decorator";

import { filter } from "@/filters/DateFilter";

import {
	EmployeeDetailsValueRepresentation,
	InstitutionDetailsValueRepresentation,
	SuggestionRepresentation
} from "@/lib/apis/models";

import { SuggestionSelect } from "@/components/suggestionSelect";
import { Focusable } from "@/components/focusable/typings";
import { isFocusable } from "@/components/focusable";

import DetailsValueView from "./details/DetailsValueView.vue";
import EditButtons from "./details/EditButtons.vue";
import { Field as NekoField, Select as NekoSelect } from "../";
import DetailsSuggestionsResourceApi from "@/lib/apis/operations/DetailsSuggestionsResourceApi";

type ModelDetails = EmployeeDetailsValueRepresentation | InstitutionDetailsValueRepresentation;

@Component({
	components: {
		DetailsValueView,
		EditButtons,
		NekoField,
		NekoSelect,
		SuggestionSelect
	}
})
export default class DetailsComponent extends Vue {
	@Model("input", { required: true })
	public readonly detailsValues!: ModelDetails[];

	@Prop({ default: null })
	public readonly label!: string | null;

	@Prop({ default: null })
	public readonly description!: string | null;

	@Prop({ default: true })
	public readonly horizontal!: boolean;

	@Prop({ default: true })
	public readonly showDate!: boolean;

	@Prop({ default: true })
	public readonly editable!: boolean;

	@Prop({ default: true })
	public readonly autoValidate!: boolean;

	@Ref()
	public readonly inputs!: Array<HTMLInputElement | Focusable<HTMLElement>>;

	public detailsValuesEdited: ModelDetails[] = [];
	public fieldEditing: boolean = false;
	public lastDetailsValue: ModelDetails | null = null;
	public showPrevious: boolean = false;
	public date = filter;
	public suggestions: SuggestionRepresentation[] = [];
	public loadingSuggestions = false;

	private lastFocused: number = -1;

	/** Liste des premières données supplémentaires de valeurs identiques triées antéchronologiquement */
	public get mergedDetailsValues(): ModelDetails[] {
		return this.sortedDetailsValues.reduce<ModelDetails[]>((result, detailsValue, index) => {
			if (index === 0 || result[result.length - 1].value !== detailsValue.value) {
				result.push(detailsValue);
			}

			return result;
		}, [])
		.reverse();
	}

	/** Liste des données supplémentaires triées antéchronologiquement */
	public get reversedDetailsValues(): ModelDetails[] {
		return this.sortedDetailsValues.slice().reverse();
	}

	/** Liste des données supplémentaires triées chronologiquement */
	public get sortedDetailsValues(): ModelDetails[] {
		return this.detailsValues.slice().sort((a, b) => {
			if (a.datePeriod && b.datePeriod) { return new Date(a.datePeriod).getTime() - new Date(b.datePeriod).getTime(); }
			if (a.datePeriod && !b.datePeriod) { return -1; }
			if (!a.datePeriod && b.datePeriod) { return 1; }

			return 0;
		});
	}

	public created() {
		this.onModelUpdated();
	}

	public updated() {
		if (this.inputs && this.inputs.length) {
			const hasActiveElement = this.inputs.some(i =>
				isFocusable(i) ? i.activeElement === document.activeElement : i === document.activeElement
			);

			if (!hasActiveElement) {
				let focusIndex = this.inputs.length - 1;

				if (this.lastFocused > -1) {
					focusIndex = this.lastFocused;
				}

				this.inputs[focusIndex].focus();
			}
		}
	}

	public formatDateForInput(value: string | null): string {
		if (!value) {
			return "";
		}

		const [day, month, year] = value.split("/");
		return `${year}-${month}-${day}`;
	}

	public getTypeInput(details: ModelDetails) {
		let type = "text";

		switch (details.type) {
			case "NUMERIC":
			case "PERCENTAGE":
				type = "number";
				break;
			case "DATE":
				type = "date";
				break;
		}

		return type;
	}

	public onCopy(from: "DSN" | "PAY_SLIP", detailsValue: ModelDetails): void {
		if (!this.editable) {
			return;
		}

		switch (from) {
			case "DSN":
				detailsValue.value = detailsValue.dsnValue;
				detailsValue.source = from;
				break;
			case "PAY_SLIP":
				detailsValue.value = detailsValue.paySlipValue;
				detailsValue.source = from;
				break;
			default:
				break;
		}

		this.detailsValuesEdited = [detailsValue];
		this.onValidate();
	}

	public onDelete(detailsValue: ModelDetails) {
		const index = this.detailsValuesEdited.findIndex(d => d.id === detailsValue.id);

		if (index !== -1) {
			this.detailsValuesEdited.splice(index, 1);
		}

		this.lastFocused--;
	}

	public onDeleteAll() {
		this.detailsValuesEdited.splice(0);
	}

	public async onFieldEdit(edit: boolean) {
		this.fieldEditing = edit;

		if (!edit) {
			this.detailsValuesEdited = [];
			this.lastFocused = -1;
		} else {
			this.detailsValuesEdited = this.reversedDetailsValues.map(d => ({ ...d }));

			try {
				this.suggestions = [];
				this.loadingSuggestions = true;
				this.suggestions = await DetailsSuggestionsResourceApi.get(
					this.detailsValues[0].detailsId,
					(this.detailsValues[0] as EmployeeDetailsValueRepresentation).payslipId
				);
			} finally {
				this.loadingSuggestions = false;
			}
		}
	}

	public onFocus(index: number) {
		this.lastFocused = index;
	}

	public onInput(detailsValue: ModelDetails, event: InputEvent | string, validate: boolean = false) {
		let value: string | null = event as string;

		if (event instanceof Event) {
			const target = event.target as HTMLInputElement;
			const detailsIsNumeric = detailsValue.type === "NUMERIC" || detailsValue.type === "PERCENTAGE";
			const detailsIsDate = detailsValue.type === "DATE";

			value = target.value;

			if ((detailsIsNumeric && target.valueAsNumber == null) || (detailsIsDate && target.valueAsDate == null)) {
				return;
			} else if (detailsIsDate && target.valueAsDate) {
				value = this.formatDateFromInput(target.value);
			}
		}

		const index = this.detailsValuesEdited.findIndex(d => d.id === detailsValue.id);
		const newDetailsValue: ModelDetails = { ...detailsValue };

		newDetailsValue.source = "USER";
		newDetailsValue.value = value;

		if (index === -1) {
			this.detailsValuesEdited.push(newDetailsValue);
		} else {
			this.detailsValuesEdited.splice(index, 1, newDetailsValue);
		}

		if (validate) {
			this.onValidate();
		}
	}

	public onValidate() {
		this.fieldEditing = false;
		this.$emit("input", this.detailsValuesEdited);
	}

	public toggleShowPrevious(): void {
		if (!this.fieldEditing) {
			this.showPrevious = !this.showPrevious;
		}
	}

	@Watch("detailsValues")
	private onModelUpdated() {
		if (this.detailsValues.some(d => !d.id)) {
			this.onFieldEdit(true);
		}

		if (this.mergedDetailsValues.length) {
			this.lastDetailsValue = this.mergedDetailsValues[0];
		} else {
			this.lastDetailsValue = null;
		}
	}

	private formatDateFromInput(value: string): string {
		const [year, month, day] = value.split("-");
		return `${day}/${month}/${year}`;
	}
}
</script>

<i18n src="../i18n/Details.json"></i18n>

<template>
	<div class="details-data" :class="{ 'has-label-hidden': !label }">
		<div v-if="label" class="details-label">
			<span
				v-if="description && description !== label"
				class="description has-tooltip-right is-tooltip-multiline mr-2"
				:data-tooltip="description"
			>
				<span class="fas fa-info-circle"></span>
			</span>
			<label class="label mb-0 is-line-limited-2" :title="label">{{ label }}</label>
			<edit-buttons
				v-if="editable"
				:field-editing="fieldEditing"
				@start-edit="onFieldEdit(true)"
				@end-edit="onFieldEdit(false)"
				@validate="onValidate()"
			/>
		</div>

		<div class="details-value-container">
			<div
				class="is-details-value"
				:class="{
					'has-siblings': !fieldEditing && mergedDetailsValues.length > 1,
					'is-empty': fieldEditing && !detailsValuesEdited.length,
					'is-extended': showPrevious
				}"
				@click="toggleShowPrevious"
			>
				<template v-if="!fieldEditing">
					<details-value-view
						:details-value="lastDetailsValue"
						:show-date="mergedDetailsValues.length > 1"
						@copy="onCopy"
					/>
					<div v-if="mergedDetailsValues.length > 1 && showPrevious" class="details-value-siblings">
						<template v-for="(detailsValue, index) in mergedDetailsValues">
							<details-value-view
								v-if="detailsValue !== lastDetailsValue"
								:key="index"
								:detailsValue="detailsValue"
								@copy="onCopy"
							/>
						</template>
					</div>
				</template>

				<span v-else-if="fieldEditing && detailsValuesEdited.length === 0" v-t="'no.data'"></span>

				<template v-else>
					<div v-if="detailsValuesEdited.length > 1" class="buttons is-right mb-2 mt-4">
						<button class="button is-danger is-outlined is-small" type="button" @click="onDeleteAll()">
							<span class="icon"><span class="fas fa-trash"></span></span>
							<span v-t="'action.deleteAll'"></span>
						</button>
					</div>

					<div v-for="(detailsValue, index) in detailsValuesEdited" :key="'editing-' + index" class="field">
						<div class="field has-addons mb-0">
							<div
								class="control is-expanded"
								:class="{ 'has-icons-right': detailsValue.type === 'PERCENTAGE', 'is-loading': loadingSuggestions }"
							>
								<neko-select
									v-if="detailsValue.type === 'BOOLEAN'"
									ref="inputs"
									:value="detailsValue.value != null ? parseInt(detailsValue.value, 10) : null"
									@focus="onFocus(index)"
									@input="onInput(detailsValue, $event, autoValidate)"
									@press-esc="onFieldEdit(false)"
								>
									<option :value="1" v-t="'boolean.true'"></option>
									<option :value="0" v-t="'boolean.false'"></option>
								</neko-select>
								<suggestion-select
									v-else-if="suggestions.length > 0"
									ref="inputs"
									:loading="loadingSuggestions"
									:suggestions="suggestions"
									:show-key="detailsValue.code === 'idcc' || detailsValue.code === 'cog'"
									:value="detailsValue.value"
									@focus="onFocus(index)"
									@input="onInput(detailsValue, $event, autoValidate)"
									@press-esc="onFieldEdit(false)"
								/>
								<input
									v-else
									:placeholder="detailsValue.label"
									class="input"
									:disabled="loadingSuggestions"
									ref="inputs"
									step="0.0001"
									:type="getTypeInput(detailsValue)"
									:value="detailsValue.type !== 'DATE' ? detailsValue.value : formatDateForInput(detailsValue.value)"
									@focus="onFocus(index)"
									@input="onInput(detailsValue, $event)"
									@keypress.enter="onValidate"
									@keyup.esc="onFieldEdit(false)"
								/>
								<span v-if="detailsValue.type === 'PERCENTAGE'" class="icon is-right">
									<span class="fas fa-percent"></span>
								</span>
							</div>
							<div class="control">
								<button @click.prevent="onDelete(detailsValue)" class="button is-outlined is-danger">
									<span class="icon"><span class="fas fa-trash"></span></span>
								</button>
							</div>
						</div>
						<p
							v-if="showDate"
							class="help"
							v-t="{
								path: 'date.period',
								args: { date: date(detailsValue.datePeriod, { day: 'numeric', month: 'long' }) }
							}">
						</p>
					</div>
				</template>
			</div>

			<edit-buttons
				v-if="editable && !label"
				:field-editing="fieldEditing"
				@start-edit="onFieldEdit(true)"
				@end-edit="onFieldEdit(false)"
				@validate="onValidate()"
			/>
		</div>
	</div>
</template>
