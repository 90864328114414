/* tslint:disable */

import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { axios } from "@/lib/axios";
import { keycloak } from "@/lib/keycloak";
import message from "@/lib/message";
import i18n from "@/lib/i18n";
import router from "@/lib/router";
import app from "@/main";


import {
	ListResponseAuditWithInformationRepresentation,
	AuditRepresentation,
	AuditParams,
	InstitutionMissingFilesRepresentation,
	ListResponseUploadRepresentation,
	ScanslipElementResultSimpleRepresentation,
	ListResponseAuditInstitutionRepresentation,
	InstitutionsAuditParams,
	ListResponseMissingDatasByEmployeePaySlipRepresentation,
	ListResponseMissingDataByInstitutionRepresentation,
	ListResponsePaySlipLineRepresentation,
	ListResponsePaySlipRepresentation
} from "../models";

class AuditsResourceApi { 
	public async list(
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ListResponseAuditWithInformationRepresentation> {
		return (await this.listWrap(draw, start, length, search, order, filter, requestConfig, errorManagement)).data;
	}

	public async listWrap(
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ListResponseAuditWithInformationRepresentation>> {
		let url = "/audits";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";

		const params: {[key: string] : any} = {
			'draw': draw,
			'start': start,
			'length': length,
			'search': search,
			'order[]': order,
			'filter[]': filter
		};

		for (const key in params) {
			if (params.hasOwnProperty(key) && key.search(/\[\]/) !== -1) {
				params[key.replace(/\[|\]/gi, "")] = params[key];
				delete params[key];
			}
		}
		requestConfig.params = requestConfig.params || params;
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async create(
		body: AuditParams,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AuditRepresentation> {
		return (await this.createWrap(body, requestConfig, errorManagement)).data;
	}

	public async createWrap(
		body: AuditParams,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<AuditRepresentation>> {
		let url = "/audits";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";

		const formData = new FormData();
		formData.append("clientId", body.clientId.toString());

		if (body.closed != null) {
			formData.append("closed", body.closed.toString());
		}


		if (body.name != null) {
			formData.append("name", body.name.toString());
		}


		if (body.resetName != null) {
			formData.append("resetName", body.resetName.toString());
		}

		formData.append("type", body.type.toString());
		formData.append("periodStart", body.periodStart.toString());
		formData.append("currentCycleStart", body.currentCycleStart.toString());
		formData.append("cycleLength", body.cycleLength.toString());
		formData.append("recurring", body.recurring.toString());
		formData.append("status", body.status.toString());

		if (body.forced != null) {
			formData.append("forced", body.forced.toString());
		}


		if (body.forcedReason != null) {
			formData.append("forcedReason", body.forcedReason.toString());
		}


		if (body.dateRestored != null) {
			formData.append("dateRestored", body.dateRestored.toString());
		}


		if (body.comment != null) {
			formData.append("comment", body.comment.toString());
		}


		if (body.estimationPaySlipMonthlyBilled != null) {
			formData.append("estimationPaySlipMonthlyBilled", body.estimationPaySlipMonthlyBilled.toString());
		}

		try {
			return await axios.post(url, formData, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async get(
		id: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AuditRepresentation> {
		return (await this.getWrap(id, requestConfig, errorManagement)).data;
	}

	public async getWrap(
		id: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<AuditRepresentation>> {
		let url = "/audits/{id}";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async update(
		id: number /* Format : int64 */,
		body: AuditParams,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AuditRepresentation> {
		return (await this.updateWrap(id, body, requestConfig, errorManagement)).data;
	}

	public async updateWrap(
		id: number /* Format : int64 */,
		body: AuditParams,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<AuditRepresentation>> {
		let url = "/audits/{id}";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());
		try {
			return await axios.put(url, body, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async delete(
		id: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<void> {
		return (await this.deleteWrap(id, requestConfig, errorManagement)).data;
	}

	public async deleteWrap(
		id: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<void>> {
		let url = "/audits/{id}";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		url = url.replace("{id}", id.toString());
		try {
			return await axios.delete(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async getBilling(
		id: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<{ [key:string]: number /* Format : int32 */ }> {
		return (await this.getBillingWrap(id, requestConfig, errorManagement)).data;
	}

	public async getBillingWrap(
		id: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<{ [key:string]: number /* Format : int32 */ }>> {
		let url = "/audits/{id}/billing";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async getMissingFiles(
		id: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<InstitutionMissingFilesRepresentation[]> {
		return (await this.getMissingFilesWrap(id, requestConfig, errorManagement)).data;
	}

	public async getMissingFilesWrap(
		id: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<InstitutionMissingFilesRepresentation[]>> {
		let url = "/audits/{id}/files/missing";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async getUploads(
		id: number /* Format : int64 */,
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ListResponseUploadRepresentation> {
		return (await this.getUploadsWrap(id, draw, start, length, search, order, filter, requestConfig, errorManagement)).data;
	}

	public async getUploadsWrap(
		id: number /* Format : int64 */,
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ListResponseUploadRepresentation>> {
		let url = "/audits/{id}/uploads";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());

		const params: {[key: string] : any} = {
			'draw': draw,
			'start': start,
			'length': length,
			'search': search,
			'order[]': order,
			'filter[]': filter
		};

		for (const key in params) {
			if (params.hasOwnProperty(key) && key.search(/\[\]/) !== -1) {
				params[key.replace(/\[|\]/gi, "")] = params[key];
				delete params[key];
			}
		}
		requestConfig.params = requestConfig.params || params;
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async listForLabel(
		id: number /* Format : int64 */,
		label: string,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ScanslipElementResultSimpleRepresentation[]> {
		return (await this.listForLabelWrap(id, label, requestConfig, errorManagement)).data;
	}

	public async listForLabelWrap(
		id: number /* Format : int64 */,
		label: string,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ScanslipElementResultSimpleRepresentation[]>> {
		let url = "/audits/{id}/paySlipLines/{label}";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());
		url = url.replace("{label}", label.toString());
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async listInstitutions(
		id: number /* Format : int64 */,
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ListResponseAuditInstitutionRepresentation> {
		return (await this.listInstitutionsWrap(id, draw, start, length, search, order, filter, requestConfig, errorManagement)).data;
	}

	public async listInstitutionsWrap(
		id: number /* Format : int64 */,
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ListResponseAuditInstitutionRepresentation>> {
		let url = "/audits/{id}/institutions";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());

		const params: {[key: string] : any} = {
			'draw': draw,
			'start': start,
			'length': length,
			'search': search,
			'order[]': order,
			'filter[]': filter
		};

		for (const key in params) {
			if (params.hasOwnProperty(key) && key.search(/\[\]/) !== -1) {
				params[key.replace(/\[|\]/gi, "")] = params[key];
				delete params[key];
			}
		}
		requestConfig.params = requestConfig.params || params;
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async updateInstitutions(
		id: number /* Format : int64 */,
		body: InstitutionsAuditParams,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<void> {
		return (await this.updateInstitutionsWrap(id, body, requestConfig, errorManagement)).data;
	}

	public async updateInstitutionsWrap(
		id: number /* Format : int64 */,
		body: InstitutionsAuditParams,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<void>> {
		let url = "/audits/{id}/institutions";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		url = url.replace("{id}", id.toString());
		try {
			return await axios.put(url, body, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async listMissingData(
		id: number /* Format : int64 */,
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ListResponseMissingDatasByEmployeePaySlipRepresentation> {
		return (await this.listMissingDataWrap(id, draw, start, length, search, order, filter, requestConfig, errorManagement)).data;
	}

	public async listMissingDataWrap(
		id: number /* Format : int64 */,
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ListResponseMissingDatasByEmployeePaySlipRepresentation>> {
		let url = "/audits/{id}/missingData";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());

		const params: {[key: string] : any} = {
			'draw': draw,
			'start': start,
			'length': length,
			'search': search,
			'order[]': order,
			'filter[]': filter
		};

		for (const key in params) {
			if (params.hasOwnProperty(key) && key.search(/\[\]/) !== -1) {
				params[key.replace(/\[|\]/gi, "")] = params[key];
				delete params[key];
			}
		}
		requestConfig.params = requestConfig.params || params;
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async listMissingDataByInstitution(
		id: number /* Format : int64 */,
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ListResponseMissingDataByInstitutionRepresentation> {
		return (await this.listMissingDataByInstitutionWrap(id, draw, start, length, search, order, filter, requestConfig, errorManagement)).data;
	}

	public async listMissingDataByInstitutionWrap(
		id: number /* Format : int64 */,
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ListResponseMissingDataByInstitutionRepresentation>> {
		let url = "/audits/{id}/missingDataByInstitution";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());

		const params: {[key: string] : any} = {
			'draw': draw,
			'start': start,
			'length': length,
			'search': search,
			'order[]': order,
			'filter[]': filter
		};

		for (const key in params) {
			if (params.hasOwnProperty(key) && key.search(/\[\]/) !== -1) {
				params[key.replace(/\[|\]/gi, "")] = params[key];
				delete params[key];
			}
		}
		requestConfig.params = requestConfig.params || params;
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async listPaySlipLines(
		id: number /* Format : int64 */,
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ListResponsePaySlipLineRepresentation> {
		return (await this.listPaySlipLinesWrap(id, draw, start, length, search, order, filter, requestConfig, errorManagement)).data;
	}

	public async listPaySlipLinesWrap(
		id: number /* Format : int64 */,
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ListResponsePaySlipLineRepresentation>> {
		let url = "/audits/{id}/paySlipLines";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());

		const params: {[key: string] : any} = {
			'draw': draw,
			'start': start,
			'length': length,
			'search': search,
			'order[]': order,
			'filter[]': filter
		};

		for (const key in params) {
			if (params.hasOwnProperty(key) && key.search(/\[\]/) !== -1) {
				params[key.replace(/\[|\]/gi, "")] = params[key];
				delete params[key];
			}
		}
		requestConfig.params = requestConfig.params || params;
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async listPaySlips(
		id: number /* Format : int64 */,
		label: string,
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ListResponsePaySlipRepresentation> {
		return (await this.listPaySlipsWrap(id, label, draw, start, length, search, order, filter, requestConfig, errorManagement)).data;
	}

	public async listPaySlipsWrap(
		id: number /* Format : int64 */,
		label: string,
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ListResponsePaySlipRepresentation>> {
		let url = "/audits/{id}/paySlipLines/{label}/paySlips";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());
		url = url.replace("{label}", label.toString());

		const params: {[key: string] : any} = {
			'draw': draw,
			'start': start,
			'length': length,
			'search': search,
			'order[]': order,
			'filter[]': filter
		};

		for (const key in params) {
			if (params.hasOwnProperty(key) && key.search(/\[\]/) !== -1) {
				params[key.replace(/\[|\]/gi, "")] = params[key];
				delete params[key];
			}
		}
		requestConfig.params = requestConfig.params || params;
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async sendFiles(
		id: number /* Format : int64 */,
		body: { files?: File /* Format : binary */[];
},
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<void> {
		return (await this.sendFilesWrap(id, body, requestConfig, errorManagement)).data;
	}

	public async sendFilesWrap(
		id: number /* Format : int64 */,
		body: { files?: File /* Format : binary */[];
},
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<void>> {
		let url = "/audits/{id}/files";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		url = url.replace("{id}", id.toString());

		const formData = new FormData();

		if (body.files != null) {
			Array.prototype.forEach.call(body.files, p => formData.append("files[]", p));
		}

		try {
			return await axios.post(url, formData, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

}
export default new AuditsResourceApi();
