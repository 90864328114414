import router from "@/lib/router";
import menu from "@/lib/menu";
import i18n from "@/lib/i18n";
import breadcrumb from "@/lib/breadcrumb";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"menu.title": "Recherches complexes",
			"breadcrumb.complex.searches": "Recherches complexes",
			"breadcrumb.complex.searches.create": "Création d'une recherche complexe",
			"breadcrumb.complex.searches.get": "Consultation d'une recherche complexe",
			"menu.complex.searches": "Recherche complexe"
		});

		breadcrumb.add([
			{
				path: "/complex/searches",
				name: "home>complex.searches"
			},
			{
				path: "/complex/searches/create",
				name: "home>complex.searches>complex.searches.create"
			},
			{
				path: "/complex/searches/([0-9]+)",
				replacePath: "/complex/searches/{1}",
				name: "home>complex.searches>complex.searches.get"
			}
		]);

		router.addRoutes([
			{
				path: "/complex/searches",
				name: "complex.searches",
				component: () => import("./vues/List.vue"),
				meta: {
					secured: true,
					roles: ["RESPONSIBLE"]
				}
			},
			{
				path: "/complex/searches/create",
				name: "complex.searches.create",
				component: () => import("./vues/Create.vue"),
				meta: {
					secured: true,
					roles: ["RESPONSIBLE"]
				}
			},
			{
				path: "/complex/searches/:id",
				name: "complex.searches.get",
				component: () => import("./vues/View.vue"),
				props: true,
				meta: {
					secured: true,
					roles: ["RESPONSIBLE"]
				}
			}
		]);

		menu.addEntry({
			label: i18n.t("menu.complex.searches") as string,
			icon: "fab fa-searchengin",
			location: { name: "complex.searches" },
			roles: ["RESPONSIBLE"],
			order: 6
		});
	}
};
