import router from "@/lib/router";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.templates.pay": "Liste modèle de bulletin",
			"breadcrumb.templates.pay.create": "Créer un modèle de bulletin",
			"breadcrumb.templates.pay.edit": "Editer un modèle de bulletin",
			"menu.templates.pay": "Modèle de bulletin"
		});

		breadcrumb.add([
			{
				path: "/database/templatesPay",
				name: "home>database>templates.pay"
			},
			{
				path: "/database/templatesPay/create",
				name: "home>database>templates.pay>templates.pay.create"
			},
			{
				path: "/database/templatesPay/([0-9]+)",
				replacePath: "/database/teamplatesPay/{1}",
				name: "home>database>templates.pay>templates.pay.edit"
			}
		]);
		router.addRoutes([
			{
				path: "/database/templatesPay",
				name: "templatesPay",
				component: () => import("./vues/List.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/templatesPay/create",
				name: "templatesPay.add",
				component: () => import("./vues/AddEdit.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/templatesPay/:id",
				name: "templatesPay.edit",
				component: () => import("./vues/AddEdit.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			}
		]);
	}
};
