<template>
	<div>
		<neko-message class="message-application" />

		<div class="menu-container">
			<router-link :to="{ path: '/' }">
				<img class="p-3" src="/pictogramme_alone.svg" />
			</router-link>

			<neko-menu v-if="login.authenticated" />
		</div>
		<neko-navbar />

		<vue-progress-bar />

		<neko-breadcrumb v-if="login.authenticated" />

		<div class="app-container">
			<slot></slot>
		</div>
	</div>
</template>

<style lang="sass">
@import "@/styles/variables.sass"

$app-container-width: calc(100% - #{$menu-container-width})

.message-application
	display: flex
	flex-flow: column
	align-items: center
	position: fixed
	top: 4.2rem
	left: 0
	margin: auto
	z-index: 41
	width: 100%
	height: 0 // Pour éviter les problème de superposition avec le fil d'ariane

	.notification
		margin: 0
		padding: 0.5rem 2.75rem 0.5rem 0.75rem
		box-shadow: none

		& + .notification, &:not(:last-child)
			margin-bottom: 0.5rem

		.delete
			font-size: 0.75rem

.menu-container
	width: $menu-container-width
	min-height: 100vh
	max-height: 100vh
	height: 100vh
	position: fixed
	top: 0
	left: 0
	z-index: 39

	.menu
		margin-top: 50px
		max-height: calc(100% - 116px) // 116px c'est la hauteur de l'image, bon c'est triché mais pas grave.
		// overflow: auto

.navbar.is-fixed-top
	left: $menu-container-width !important
	z-index: 39 !important
	background-color: $scheme-main

.breadcrumb
	margin-left: $menu-container-width
	position: fixed

.app-container
	margin-left: $menu-container-width
	width: $app-container-width
	height: calc(100vh - #{$navbar-height} - #{$breadcrumb-height})
	overflow-y: scroll
	padding: $app-container-padding
	position: relative
</style>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";

import NekoBreadcrumb from "./breadcrumb/vues/Breadcrumb.vue";
import NekoNavbar from "./navbar/vues/Navbar.vue";
import NekoMessage from "./message/vues/Message.vue";
import NekoMenu from "./menu/vues/Menu.vue";
import { LoginState } from "../lib/keycloak/typings/LoginState";

@Component({
	components: {
		NekoBreadcrumb,
		NekoNavbar,
		NekoMessage,
		NekoMenu
	}
})
export default class DefaultLayoutComponent extends Vue {
	@State("login")
	public login!: LoginState;
}
</script>
