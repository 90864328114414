import { Module } from "vuex";

import { NekoState } from "@/lib/vuex/typings";

import { AuditContext, Context, ContextState } from "../typings";
import { AuditRepresentation } from "@/lib/apis/models";
import AuditsResourceApi from "@/lib/apis/operations/AuditsResourceApi";

export const localStorageKey = "audit-context";
export const prefixLocalStorageAudit = "audit-id-";
export const prefixLocalStorageYear = "year-";

function storeContext(context: Context) {
	if (!context) {
		return;
	}

	if (context instanceof AuditContext) {
		localStorage.setItem(localStorageKey, prefixLocalStorageAudit + context.audit.id);
	} else {
		localStorage.setItem(localStorageKey, prefixLocalStorageYear + context.year);
	}
}

export default {
	namespaced: true,

	state: {
		selected: undefined
	},

	getters: {
		year(state) {
			return state.selected?.year;
		},
		audit(state) {
			if (!(state.selected instanceof AuditContext)) {
				return null;
			}

			return state.selected.audit;
		}
	},

	mutations: {
		chooseAudit(state: ContextState, audit: AuditRepresentation) {
			state.selected = new AuditContext(audit);
			storeContext(state.selected);
		},

		chooseYear(state: ContextState, year: number) {
			state.selected = {
				year
			};
			storeContext(state.selected);
		},

		deleteContext(state: ContextState) {
			state.selected = undefined;
			localStorage.removeItem(localStorageKey);
		}
	},

	actions: {
		async refreshAudit(context) {
			context.commit("chooseAudit", await AuditsResourceApi.get(context.getters["audit"].id));
		}
	}
} as Module<ContextState, NekoState>;
