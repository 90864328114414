<template>
	<div id="app">
		<neko-default-layout>
			<div v-if="!login.authenticated" class="is-flex is-flex-direction-column is-align-items-center">
				<h1
					v-show="!login.authenticating"
					class="title is-1 has-text-centered has-text-grey-light mt-6"
					v-t="'title.not.authenticated'"
				></h1>
				<h1
					v-show="login.authenticating"
					class="title is-1 has-text-centered has-text-grey-light mt-6"
					v-t="'title.authenticating'"
				></h1>

				<router-link
					to="/login"
					class="button is-primary is-large mt-6"
					:disabled="login.authenticating"
					:class="{ 'is-loading': login.authenticating }"
					v-t="'action.login'"
				/>
			</div>

			<div v-else-if="!login.cabinet" class="columns">
				<div class="column is-one-third is-offset-4">
					<article class="message is-warning">
						<div class="message-body" v-t="'no.cabinet.selected'"></div>
					</article>

					<div class="box p-0">
						<neko-cabinet-selector />
					</div>
				</div>
			</div>
			<transition v-else name="fade-fast" mode="out-in">
				<router-view v-if="reloadRouterView" />
			</transition>
		</neko-default-layout>
	</div>
</template>

<style lang="sass">
@import "@/styles/main.sass"
</style>

<i18n>
{
	"fr": {
		"action.login": "Se connecter",
		"title.not.authenticated": "Veuillez-vous authentifier pour accèder à l'application.",
		"no.cabinet.selected": "Vous devez être affilier à un cabinet pour continuer à utiliser cette application. Si vous n'avez pas la possibilité de choisir, veuillez contacter le support.",
		"title.authenticating": "Authentification en cours veuillez patienter."
	}
}
</i18n>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";

import NekoDefaultLayout from "@/layouts/Default.vue";

import { LoginState } from "@/lib/keycloak/typings/LoginState";

import { CabinetSelector as NekoCabinetSelector } from "@/components/cabinetSelector";
import { ContextState, DeleteContext } from "@/components/contextSelector/typings";

import { Cabinet } from "./lib/keycloak/typings/EmaSoftsToken";

@Component({
	components: {
		NekoCabinetSelector,
		NekoDefaultLayout
	}
})
export default class AppVue extends Vue {
	@State("login")
	public login!: LoginState;

	@State("context")
	public contextState!: ContextState;

	@Mutation("context/deleteContext")
	public deleteContext!: DeleteContext;

	public reloadRouterView: boolean = true;

	private async created() {
		this.$router.beforeEach((to, from, next) => {
			this.$Progress.start();
			next();
		});

		this.$router.afterEach((to, from) => {
			this.$Progress.finish();
		});

		this.$router.onError(() => {
			this.$Progress.fail();
		});
	}

	private get currentUser() {
		return this.login.currentUser;
	}

	private get authenticated() {
		return this.login.authenticated;
	}

	@Watch("login.cabinet")
	private async watchCabinet(value: Cabinet, oldValue: Cabinet) {
		if (value?.id === oldValue?.id) {
			return;
		}

		if (oldValue && this.$route.name !== "audits") {
			this.deleteContext();
			this.$router.push({ name: "audits" });
		}
	}
}
</script>
