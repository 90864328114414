import i18n from "../i18n";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"request.network.error": "Impossible d'accèder au serveur.",
			"request.not.authorized": "Vous n'êtes pas autorisés à effectuer cette action.",
			"request.not.found": "Nous ne trouvons pas l'action que vous souhaitez effectuer.",
			"request.bad.request": "Nous manquons d'informations pour effectuer votre action.",
			"request.server.error": "Impossible d'effectuer cette action.",
			"request.no.response": "Nous n'avons reçu aucune réponse du serveur."
		});
	}
};
