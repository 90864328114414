import Vue from "vue";
import i18n from "@/lib/i18n";

function filter(value: number | null, minDigit: number | null = null) {
	if (value == null || Number.isNaN(value)) {
		return "";
	}

	if (minDigit === null) {
		const valueStr = value.toString().split(".");

		if (valueStr.length > 1) {
			minDigit = valueStr[1].length;

			if (minDigit === 1 || minDigit >= 5) {
				minDigit = 2;
			}
		} else {
			minDigit = 2;
		}

		if (minDigit > 4) {
			minDigit = 4;
		}
	}

	return value.toLocaleString(i18n.fallbackLocale as string, {
		minimumFractionDigits: minDigit,
		maximumFractionDigits: minDigit
	});
}

export default {
	install() {
		Vue.filter("number", filter);
	}
};

export { filter };
