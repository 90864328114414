<i18n src="../i18n/Boolean.json"></i18n>

<template>
	<neko-select
		v-if="theme === 'default'"
		:for="id"
		:label="label"
		:name="name"
		:horizontal="horizontal"
		:required="required"
		:sending="sending"
		:disabled="disabled"
		:value="value"
		@input="$emit('input', $event)"
	>
		<template>
			<option
				v-for="booleanValue in [true, false]"
				:key="booleanValue"
				:value="booleanValue"
				v-t="'boolean.' + booleanValue.toString().toUpperCase()"
			></option>
		</template>

		<slot v-for="(slot, name) in $slots" :name="name" :slot="name"></slot>
	</neko-select>
	<neko-chooser v-else-if="theme === 'radio'"
		:for="id"
		:label="label"
		:name="id"
		type="radio"
		:horizontal="horizontal"
		:required="required"
		:sending="sending"
		:disabled="disabled"
		:value="value"
		@input="$emit('input', $event)"
		:values="valuesChooser"
	/>
	<neko-field
		v-else
		:for="id"
		:name="name"
		:horizontal="horizontal"
		:sending="sending"
		:disabled="disabled"
	>
		<input
			:checked="value === true"
			class="switch"
			:id="id"
			:name="name"
			:required="required"
			type="checkbox"
			@input="$emit('input', $event.target.checked)"
		/>
		<label :for="id" class="label">
			{{ label }}
			<small v-if="!!label && required">&nbsp;({{ $t("required") }})</small>
		</label>
	</neko-field>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { HtmlInputForm } from "@emasofts/common-vuejs-form";

import NekoSelect from "./Select.vue";
import NekoField from "./Field.vue";
import NekoChooser from "./Chooser.vue";

@Component({
	components: {
		NekoSelect,
		NekoField,
		NekoChooser
	}
})
export default class BooleanComponent extends HtmlInputForm {
	@Prop({
		type: Boolean
	})
	declare public readonly required: boolean;

	@Prop({
		default : "default"
	})
	public readonly theme!: "default" | "switch" | "radio";

	public get valuesChooser() {
		return {
			[this.$t("boolean.TRUE") as string]: true,
			[this.$t("boolean.FALSE") as string]: false,
		};
	}
}
</script>
