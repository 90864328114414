<i18n src="../i18n/Field.json"></i18n>

<script lang="tsx">
import Vue, { VNode, VNodeChildren } from "vue";
import { hash } from "@emasofts/common-vuejs-form";
import { Component, Prop, Watch } from "vue-property-decorator";
import { NoCache } from "@/decorators/NoCache";

@Component
export default class FieldComponent extends Vue {
	@Prop({ default: false, type: Boolean })
	private readonly disabled!: boolean;

	@Prop({ default: "" })
	private readonly label!: string;

	@Prop({ default: "" })
	private readonly for!: string;

	@Prop({ default: false, type: Boolean })
	private readonly required!: boolean;

	@Prop({ default: false, type: Boolean })
	private readonly horizontal!: boolean;

	@Prop({ default: false, type: Boolean })
	private readonly addonsLeft!: boolean;

	@Prop({ default: false, type: Boolean })
	private readonly addonsRight!: boolean;

	@Prop({ default: "" })
	private readonly error!: string;

	@Prop({ default: false, type: Boolean })
	private readonly reset!: boolean;

	@Prop({ default: false, type: Boolean })
	private readonly loading!: boolean;

	@Prop({ default: false, type: Boolean })
	private readonly toggleable!: boolean;

	@Prop({ default: false, type: Boolean })
	private readonly toggled!: boolean;

	private errorMessage: VNodeChildren = "";

	@NoCache
	private get leftAddons() {
		return Object.keys(this.$slots).filter(key => key.startsWith("addons-left"));
	}

	@NoCache
	private get rightAddons() {
		return Object.keys(this.$slots).filter(key => key.startsWith("addons-right"));
	}

	@NoCache
	private get hasAddons(): boolean {
		const hasAddonSlots = Object.keys(this.$slots)
		.some(key => key.startsWith("addons-left") || key.startsWith("addons-right"));

		return this.reset || hasAddonSlots;
	}

	private get shouldDisplayContent(): boolean {
		return !this.toggleable || this.toggled;
	}

	private get shouldDisplayLabel(): boolean {
		return !!(this.label || this.$slots.label) || this.toggleable;
	}

	public render(): VNode | VNode[] {
		const help: VNode[] = [];

		if (this.errorMessage) {
			help.push(<p class="help is-danger" key="error-help">{ this.errorMessage }</p>);
		}

		this.$slots.help?.forEach((node, index) => {
			node.key = `help-${index}`;
			help.push(node);
		});

		if (this.horizontal) {
			return <div class="field is-horizontal">
				{ this.shouldDisplayLabel && <div class="field-label is-normal">{ this.renderLabel() }</div> }
				{ this.shouldDisplayContent &&
				<div class="field-body">
					<div class="field">
						{ this.renderContent() }
						{ this.shouldDisplayContent && help }
					</div>
				</div> }
			</div>;
		}

		if (this.shouldDisplayLabel || !this.hasAddons) {
			return <div class="field">
				{ this.shouldDisplayLabel && this.renderLabel() }
				{ this.shouldDisplayContent && this.renderContent() }
				{ this.shouldDisplayContent && help }
			</div>;
		}

		if (this.shouldDisplayContent) {
			return <div class="field">
				{ this.renderContent() }
				{ help }
			</div>;
		}

		return this.renderContent();
	}

	private renderAddon(name: string, index: number, position: "left" | "right"): VNode {
		const key = `${position}-addon-${index}`;
		return <div class="control" key={ key }>{ this.$slots[name] }</div>;
	}

	private renderContent(): VNode {
		const className = [
			"control is-expanded",
			{
				"is-loading": this.loading,
				"has-icons-left": this.$slots["icons-left"],
				"has-icons-right": this.$slots["icons-right"]
			}
		];

		const fieldControl = <div class={ className }>
			{ this.$slots.default }
			{ this.$slots["icons-left"] }
			{ this.$slots["icons-right"] }
		</div>;

		if (this.hasAddons || this.required && !this.shouldDisplayLabel) {
			return <div class="field has-addons">
				{ this.leftAddons.map((name, index) => this.renderAddon(name, index, "left")) }
				{ fieldControl }
				{ this.rightAddons.map((name, index) => this.renderAddon(name, index, "right")) }
				{ this.reset && !this.disabled && <div class="control">
					<button type="button" class="button is-outlined is-danger" onClick={ this.onReset }>
						<span class="icon"><span class="fa fa-undo-alt" /></span>
					</button>
				</div> }
				{ this.required && !this.shouldDisplayLabel && <div class="control">
					<a class="button is-static" data-tooltip={this.$t("form.input.required")}>
						<span class="icon"><span class="fas fa-exclamation" /></span>
					</a>
				</div> }
			</div>;
		}

		return fieldControl;
	}

	private renderLabel(): VNode {
		const label = <label class="label" for={ this.for } key="label">
			{ this.$slots.label || this.label }
			&nbsp;
			<transition name="fade">
				{ this.shouldDisplayContent && this.required && <small class="is-unbreakable">
					<span v-t="form.input.required" />
				</small> }
			</transition>
		</label>;

		if (this.toggleable && label.data?.attrs) {
			label.data.attrs.for = hash(`${this.label}-${Date.now()}-${Math.random() * 100}`);

			return <div>
				<input
					type="checkbox"
					id={label.data.attrs.for}
					class="switch"
					checked={this.toggled}
					onChange={() => this.$emit("toggle", !this.toggled)}
				/>
				{ label }
			</div>;
		}

		return label;
	}

	@Watch("error")
	private updateErrorMessage() {
		if (!this.error) {
			this.errorMessage = "";
		} else if (this.error.startsWith("constraints")) {
			this.updateConstraintsErrorMessage();
		} else if (this.error.startsWith("variables")) {
			this.updateVariableErrorMessage();
		} else if (this.error.startsWith("additionalData")) {
			this.updateAdditionalDataErrorMessage();
		} else if (this.error.startsWith("details")) {
			this.updateDetailsErrorMessage();
		} else if (this.error.startsWith("functions")) {
			this.updateFunctionsErrorMessage();
		}
	}

	private updateAdditionalDataErrorMessage(): void {
		const matchNotFound = this.error.match(/additionalData.notFound\((\w+)\)/);

		if (matchNotFound) {
			this.errorMessage = [<i18n path="error.additionalData.notFound" tag="span">
				<code>{matchNotFound[1]}</code>
			</i18n>];
			return;
		}

		const matchNoValue = this.error.match(/additionalData.noValue\(([\w:]+),\s*(\d+),\s*([-\d]+)\)/);

		if (matchNoValue) {
			this.errorMessage = [<i18n path="error.additionalData.noValue" tag="span">
				<code>{matchNoValue[1]}</code>
				<code>{this.$options.filters?.date(matchNoValue[3])}</code>
				<code>{matchNoValue[2]}</code>
			</i18n>];
		}
	}

	private updateConstraintsErrorMessage(): void {
		const matchesSize = this.error.match(/constraints.size\(([0-9]+),([0-9]+)?\)/);
		const matchesMin = this.error.match(/constraints.min\(([0-9]+)\)/);
		const matchesMax = this.error.match(/constraints.max\(([0-9]+)\)/);
		const matchesPattern = this.error.match(/constraints.pattern\((.+)\)/);

		if (matchesSize) {
			if (!matchesSize[2]) {
				this.errorMessage = this.$t("constraints.size.min", {
					min: matchesSize[1]
				}) as string;
			} else {
				this.errorMessage = this.$t("constraints.size.min.max", {
					min: matchesSize[1],
					max: matchesSize[2]
				}) as string;
			}
		} else if (matchesMin) {
			this.errorMessage = this.$t("constraints.min", { value: matchesMin[1] }) as string;
		} else if (matchesMax) {
			this.errorMessage = this.$t("constraints.max", { value: matchesMax[1] }) as string;
		} else if (matchesPattern) {
			this.errorMessage = this.$t("constraints.pattern", { pattern: matchesPattern[1] }) as string;
		} else {
			this.errorMessage = this.$t(this.error) as string;
		}
	}

	private updateDetailsErrorMessage(): void {
		const matchNotFound = this.error.match(/details.notFound\((employee|institution):([\w:]+)\)/);

		if (matchNotFound) {
			this.errorMessage = [<i18n path={`error.details.${matchNotFound[1]}.notFound`} tag="span">
				<code>{matchNotFound[2]}</code>
			</i18n>];
		}
	}

	private onReset() {
		if (!this.disabled) {
			this.$emit("reset");
		}
	}

	private updateFunctionsArgumentsErrorMessage(): void {
		const regExp = /functions.arguments.parse.dateTime\((\w+),\s*(\d+),\s*(.+),\s*(.+)\)/;
		const matchParseDateTime = this.error.match(regExp);

		if (matchParseDateTime) {
			const index = parseInt(matchParseDateTime[2], 10);

			this.errorMessage = [<i18n path="error.functions.arguments.parse.dateTime" tag="span">
				<code slot="function">{matchParseDateTime[1]}()</code>
				<template slot="index">{index + 1}</template>
				<sup slot="abbr">{this.$t(`abbr.rank[${Math.min(index, 2)}]`)}</sup>
				<code slot="formula">{matchParseDateTime[3]}</code>
				<code slot="value">{matchParseDateTime[4]}</code>
			</i18n>];
		} else {
			const argumentsRegExp = /functions.arguments\((\w+),\s*(\d+),\s*(.+),\s*(.+)\)/;
			const matchArguments = this.error.match(argumentsRegExp) as RegExpMatchArray;
			const index = parseInt(matchArguments[2], 10);

			this.errorMessage = [<i18n path="error.functions.arguments" tag="span">
				<code slot="function">{matchArguments[1]}()</code>
				<template slot="index">{index + 1}</template>
				<sup slot="abbr">{this.$t(`abbr.rank[${Math.min(index, 2)}]`)}</sup>
				<code slot="formula">{matchArguments[3]}</code>
			</i18n>];
		}
	}

	private updateFunctionsErrorMessage(): void {
		const matchNotFound = this.error.match(/functions.notFound\((\w+)\)/);
		const matchArguments = this.error.startsWith("functions.arguments");

		if (matchNotFound) {
			this.errorMessage = [<i18n path="error.functions.notFound" tag="span">
				<code>{matchNotFound[1]}()</code>
			</i18n>];
		} else if (matchArguments) {
			this.updateFunctionsArgumentsErrorMessage();
		} else {
			this.errorMessage = [<i18n path="error.functions.unknown" tag="span">
				<code>{(this.error.match(/functions\((\w+)\)/) as RegExpMatchArray)[1]}()</code>
			</i18n>];
		}
	}

	private updateVariableErrorMessage(): void {
		const matchNotFound = this.error.match(/variables.notFound\((\w+)\)/);

		if (matchNotFound) {
			this.errorMessage = [<i18n path="error.variables.notFound" tag="span">
				<code>{matchNotFound[1]}</code>
			</i18n>];
		}
	}
}
</script>
