<script lang="ts">
import { Component, Ref, Vue } from "vue-property-decorator";

import UniversalSearchApi from "@/lib/apis/operations/UniversalSearchResourceApi";

@Component
export default class NavbarSearch extends Vue {
	@Ref() public readonly input?: HTMLInputElement;
	@Ref() public readonly routerLinks?: ReadonlyArray<Vue>;

	public search: string = "";
	public searching: boolean = false;
	public institutions: any[] = [];
	public employees: any[] = [];
	public resultsVisible: boolean = false;

	private searchTimeout: number | null = null;

	//#region Lifecycle hooks
	public mounted(): void {
		document.addEventListener("click", this.onFocusout);
	}

	public destroyed(): void {
		document.removeEventListener("click", this.onFocusout);
	}
	//#endregion Lifecycle hooks

	public clearSearch(): void {
		if (this.input && this.input.value) {
			this.input.value = "";
			this.input.dispatchEvent(new InputEvent("input", { inputType: "deleteContentBackward" }));
		}
	}

	public onSearch() {
		if (this.searchTimeout) {
			clearTimeout(this.searchTimeout);
		}

		if (this.search) {
			this.searching = true;

			this.searchTimeout = setTimeout(async () => {
				this.institutions = (await UniversalSearchApi.search(["INSTITUTION"], 1, 0, 5, this.search, null, null)).data;
				this.employees = (await UniversalSearchApi.search(["EMPLOYEE"], 1, 0, 5, this.search, null, null)).data;

				this.searching = false;
			}, 250);
		} else {
			this.institutions = [];
			this.employees = [];
		}
	}

	public onFocus() {
		this.resultsVisible = true;
	}

	public onFocusout() {
		this.clearSearch();
		this.resultsVisible = false;
	}

	public onKeydown(event: KeyboardEvent): void {
		if (!this.routerLinks || !this.institutions.length && !this.employees.length) {
			return;
		}

		const pressArrowDown = event.key === "ArrowDown";
		const pressArrowUp = event.key === "ArrowUp";

		if (pressArrowDown || pressArrowUp) {
			const links = this.routerLinks.map(link => link.$el as HTMLLinkElement);
			const lastLinkIndex = links.length - 1;
			const focusedIndex = links.indexOf(document.activeElement as HTMLLinkElement);

			let link = links[0];

			if (pressArrowDown && focusedIndex > -1 && focusedIndex !== lastLinkIndex) {
				link = links[focusedIndex + 1];
			} else if (pressArrowUp && focusedIndex <= 0) {
				link = links[lastLinkIndex];
			} else if (pressArrowUp) {
				link = links[focusedIndex - 1];
			}

			link.focus();
		} else if (event.key === "Escape") {
			this.clearSearch();
			this.input?.focus();
		}
	}
}
</script>

<i18n>
{
	"fr": {
		"description": "La recherche est effectuée sur l'ensemble des établissements et salariés.",
		"actions.search": "Rechercher un salarié ou un établissement",
		"type.employee": "Salariés",
		"type.institution": "Établissements",
		"employee.archived": "Salarié archivé",
		"institution.archived": "Établissement archivé",
		"no.results": "Aucun résultat",
		"searching": "Recherche en cours"
	}
}
</i18n>

<template>
	<div class="navbar-item is-relative" @click.stop @keydown="onKeydown">
		<div class="control has-icons-left" :class="{ 'is-loading': searching }">
			<span class="icon is-small is-left">
				<span class="fas fa-search"></span>
			</span>
			<input
				class="input is-normal is-shadow"
				:placeholder="$t('actions.search')"
				ref="input"
				type="search"
				v-model="search"
				@input="onSearch"
				@focus="onFocus"
				@keydown.escape.stop
			/>
		</div>
		<div v-show="resultsVisible" class="results">
			<template v-if="searching">
				<span class="icon"><span class="fas fa-circle-notch fa-spin"></span></span>
				{{ $t("searching") }}&hellip;
			</template>
			<template v-else-if="!search && !institutions.length && !employees.length">
				{{ $t("description") }}
			</template>
			<template v-else-if="institutions.length === 0 && employees.length === 0">
				{{ $t("no.results") }}
			</template>
			<template v-else>
				<template v-if="institutions.length">
					<h5 class="result-header" v-t="'type.institution'"></h5>

					<div v-for="institution in institutions" :key="institution.id" class="result-item">
						<router-link
							ref="routerLinks"
							:to="{ name: 'institutions.get', params: { id: institution.id } }"
							@click.native.stop="onFocusout"
							@keydown.enter.native.stop="$event.target.click()"
						>
							<span class="icon">
								<span class="fa fa-building"></span>
							</span>
							<span :data-tooltip="$t('institution.archived')" class="is-pulled-right has-tooltip-top">
								<span class="icon">
									<span v-show="institution.dateArchived" class="fa fa-archive"></span>
								</span>
							</span>

							{{ institution.name }}
						</router-link>
					</div>
				</template>
				<template v-if="employees.length">
					<h5 class="result-header" v-t="'type.employee'"></h5>

					<div v-for="employee in employees" :key="employee.id" class="result-item">
						<router-link
							ref="routerLinks"
							:to="{ name: 'employees.get', params: { institutionId: employee.institutionId, id: employee.id } }"
							@click.native.stop="onFocusout"
							@keydown.enter.native.stop="$event.target.click()"
						>
							<span class="icon">
								<span class="fa fa-user"></span>
							</span>
							<span :data-tooltip="$t('employee.archived')" class="is-pulled-right">
								<span class="icon">
									<span v-show="employee.dateArchived" class="fa fa-archive"></span>
								</span>
							</span>

							{{ employee.name }}
						</router-link>
					</div>
				</template>
			</template>
		</div>
	</div>
</template>
