import Vue from "vue";

import { DsnRepresentation } from "@/lib/apis/models";
import i18n from "@/lib/i18n";

function filter(dsn: DsnRepresentation): string {
	if (dsn.fileName) {
		return dsn.fileName;
	}

	i18n.mergeLocaleMessage("fr", {
		noName: "DSN sans nom (#{id})"
	});

	return i18n.t("noName", dsn) as string;
}

export default {
	install() {
		Vue.filter("dsn", filter);
	}
};

export { filter };
