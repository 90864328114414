import { render, staticRenderFns } from "./PageTitle.vue?vue&type=template&id=45cd2c86&functional=true"
import script from "./PageTitle.vue?vue&type=script&lang=ts"
export * from "./PageTitle.vue?vue&type=script&lang=ts"
import style0 from "./PageTitle.vue?vue&type=style&index=0&id=45cd2c86&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports