import { Breadcrumb as BreadcrumbObject } from "./core/Breadcrumb";

interface Breadcrumb {
	path: string;
	replacePath?: string;
	name: string;
}

export default class VueBreadcrumb {
	private breadcrumbsIntern: BreadcrumbObject[] = [];

	constructor(breadcrumbs?: Breadcrumb[]) {
		if (breadcrumbs) {
			for (const b of breadcrumbs) {
				this.breadcrumbsIntern.push(this.buildBreadcrumb(b.path, b.replacePath, b.name));
			}
		}
	}

	get breadcrumbs() {
		return this.breadcrumbsIntern;
	}

	public add(breadcrumbs: Breadcrumb[]) {
		for (const breadcrumb of breadcrumbs) {
			this.breadcrumbsIntern.push(this.buildBreadcrumb(breadcrumb.path, breadcrumb.replacePath, breadcrumb.name));
		}
	}

	public fromPath(path: string) {
		let currentBreadcrumb = null;

		for (const breadcrumb of this.breadcrumbs) {
			if (path.search(new RegExp(breadcrumb.pathPattern.source + "$")) !== -1) {
				currentBreadcrumb = breadcrumb;
				break;
			}
		}

		return currentBreadcrumb;
	}

	private buildBreadcrumb(matchingPath: string, replacePath: string | undefined, name: string) {
		const pathPattern = new RegExp("^" + matchingPath);
		const finalReplacePath = replacePath || matchingPath;
		let finalName = name;
		let parentsName = "";

		if (name.search(">") !== -1) {
			const parentsPart = name.split(">");
			parentsName = parentsPart.slice(0, parentsPart.length - 1).join(">");
			finalName = parentsPart[parentsPart.length - 1];
		}

		return new BreadcrumbObject(finalName, pathPattern, finalReplacePath, parentsName);
	}
}
