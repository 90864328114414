import router from "@/lib/router";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.details.institution": "Données supplémentaires établissement",
			"breadcrumb.details.employee": "Données supplémentaires salarié",
			"breadcrumb.details.institution.create": "Création d'une donnée établissement",
			"breadcrumb.details.institution.edit": "Edition d'une donnée établissement",
			"breadcrumb.details.employee.create": "Création d'une donnée salarié",
			"breadcrumb.details.employee.edit": "Edition d'une donnée salarié"
		});

		breadcrumb.add([
			{
				path: "/database/details/institution",
				name: "home>database>details.institution"
			},
			{
				path: "/database/details/employee",
				name: "home>database>details.employee"
			},
			{
				path: "/database/details/institution/create",
				name: "home>database>details.institution>details.institution.create"
			},
			{
				path: "/database/details/institution/([0-9]+)/edit",
				replacePath: "/database/details/{1}/edit",
				name: "home>database>details.institution>details.institution.edit"
			},
			{
				path: "/database/details/employee/create",
				name: "home>database>details.employee>details.employee.create"
			},
			{
				path: "/database/details/employee/([0-9]+)/edit",
				replacePath: "/database/details/{1}/edit",
				name: "home>database>details.employee>details.employee.edit"
			}
		]);

		router.addRoutes([
			{
				path: "/database/details/institution",
				name: "details.institution",
				component: () => import("./vues/InstitutionList.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/details/employee",
				name: "details.employee",
				component: () => import("./vues/EmployeeList.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/details/institution/create",
				name: "details.institution.create",
				component: () => import("./vues/InstitutionAddEdit.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/details/institution/:id/edit",
				name: "details.institution.edit",
				component: () => import("./vues/InstitutionAddEdit.vue"),
				props: true,
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/details/employee/create",
				name: "details.employee.create",
				component: () => import("./vues/EmployeeAddEdit.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/details/employee/:id/edit",
				name: "details.employee.edit",
				component: () => import("./vues/EmployeeAddEdit.vue"),
				props: true,
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			}
		]);
	}
};
