import router from "@/lib/router";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.referential.pay": "Référentiels de paie",
			"breadcrumb.referential.pay.add": "Créer un référentiel de paie",
			"breadcrumb.referential.pay.edit": "Editer un référentiel de paie",
			"menu.referential.pay": "Référentiels de paie"
		});

		breadcrumb.add([
			{
				path: "/database/referentialPay",
				name: "home>database>referential.pay"
			},
			{
				path: "/database/referentialPay/create",
				name: "home>database>referential.pay>referential.pay.add"
			},
			{
				path: "/database/referentialPay/([0-9]+)/edit",
				replacePath: "/database/referentialPay/{1}/edit",
				name: "home>database>referential.pay>referential.pay.edit"
			}
		]);

		router.addRoutes([
			{
				path: "/database/referentialPay",
				name: "referentialPay",
				component: () => import("./vues/List.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/referentialPay/create",
				name: "referentialPay.create",
				component: () => import("./vues/AddEdit.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/referentialPay/:id/edit",
				name: "referentialPay.edit",
				component: () => import("./vues/AddEdit.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			}
		]);
	}
};
