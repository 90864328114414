import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import { NekoState } from "./typings";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		version: "0.0.0"
	}
} as StoreOptions<NekoState>);
