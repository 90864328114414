<script lang="ts">
import Component from "vue-class-component";
import { PaginationComponent } from "@emasofts/common-vuejs-pagination";
import { Prop } from "vue-property-decorator";

import { Size } from "../typings";

@Component
export default class Pagination extends PaginationComponent {
	@Prop({
		default: false
	})
	protected readonly disabled!: boolean;

	@Prop({
		default: "normal"
	})
	protected readonly size!: Size;
}
</script>

<template>
	<nav class="pagination is-centered" :class="{ [`is-${size}`]: size !== 'normal' }">
		<a class="pagination-previous" :disabled="disabled || currentPage === 1" @click.prevent="currentPage = 1"
			><span class="fa fa-angle-double-left"></span
		></a>
		<a
			class="pagination-previous"
			:disabled="disabled || currentPage === 1"
			@click.prevent="currentPage = currentPage - 1"
			><span class="fa fa-angle-left"></span
		></a>
		<a
			class="pagination-next"
			:disabled="disabled || currentPage === max"
			@click.prevent="currentPage = currentPage + 1"
			><span class="fa fa-angle-right"></span
		></a>
		<a class="pagination-next" :disabled="disabled || currentPage === max" @click.prevent="currentPage = max"
			><span class="fa fa-angle-double-right"></span
		></a>

		<ul class="pagination-list">
			<li v-for="page in pages" :key="page">
				<a
					class="pagination-link"
					:disabled="disabled"
					:class="{ 'is-current': currentPage === page }"
					@click.prevent="currentPage = page"
					>{{ page }}</a
				>
			</li>
		</ul>
	</nav>
</template>
