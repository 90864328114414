import Vue from "vue";

import { InstitutionRepresentation } from "@/lib/apis/models";

function filter(institution: InstitutionRepresentation) {
	if (!institution) {
		return "-";
	}

	return institution.name ?? `(${institution.siret})`;
}

export default {
	install() {
		Vue.filter("institution", filter);
	}
};
export { filter };
