import { CoordinatesRepresentation } from "@/lib/apis/models";
import Vue from "vue";

function filter(coordinates?: CoordinatesRepresentation | null): string | null {
	if (coordinates) {
		return `${coordinates.x}, ${coordinates.y}`;
	}

	return null;
}

export default {
	install() {
		Vue.filter("coordinates", filter);
	}
};

export { filter };
