import Axios, { AxiosInstance } from "axios";
import Keycloak from "keycloak-js";

/**
 * Classe de base d'accés aux ressources de l'API REST Keycloak.
 */
export default abstract class BaseEndpoint {
	protected readonly axios: AxiosInstance;
	protected readonly keycloak: Keycloak;
	protected abstract readonly url: string;

	/**
	 * Crée une nouvelle instance de la classe avec une instance
	 * `KeycloakInstance`.
	 * @param keycloak
	 */
	protected constructor(keycloak: Keycloak) {
		const headers: HeadersInit = {
			Accept: "application/json",
			Authorization: `Bearer ${keycloak.token}`
		};

		this.axios = Axios.create({
			baseURL: `${keycloak.authServerUrl}/admin/realms/${keycloak.realm}`,
			headers
		});

		this.keycloak = keycloak;
	}

	protected get baseUrl() {
		return this.keycloak.authServerUrl;
	}
}
