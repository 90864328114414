import router from "@/lib/router";
import i18n from "@/lib/i18n";
import breadcrumb from "@/lib/breadcrumb";
import menu from "@/lib/menu";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.extractors": "Extraction de bulletin",
			"menu.extractors": "Données brutes"
		});

		breadcrumb.add([
			{
				path: "/extractors",
				name: "home>extractors"
			}
		]);

		router.addRoutes([
			{
				path: "/extractors",
				name: "extractors",
				component: () => import("./vues/Index.vue"),
				meta: {
					secured: true,
					roles: ["RESPONSIBLE"]
				}
			}
		]);

		menu.addEntry({
			label: i18n.t("breadcrumb.extractors") as string,
			icon: "fa fa-file-excel",
			location: { name: "extractors" },
			roles: ["RESPONSIBLE"],
			order: 7
		});
	}
};
