import router from "@/lib/router";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.templates.mapping": "Modèles correspondance de ligne",
			"breadcrumb.templates.mapping.create": "Création d'un modèle de correspondances",
			"breadcrumb.templates.mapping.edit": "Edition d'un modèle de correspondances",
		});

		breadcrumb.add([
			{
				path: "/database/templatesMapping",
				name: "home>database>templates.mapping"
			},
			{
				path: "/database/templatesMapping/create",
				name: "home>database>templates.mapping>templates.mapping.create"
			},
			{
				path: "/database/templatesMapping/edit/([0-9]+)",
				replacePath: "/database/templatesMapping/edit/{1}",
				name: "home>database>templates.mapping>templates.mapping.edit"
			}
		]);

		router.addRoutes([
			{
				path: "/database/templatesMapping",
				name: "templatesMapping",
				component: () => import("./vues/List.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/templatesMapping/create",
				name: "templatesMapping.create",
				component: () => import("./vues/AddEdit.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/templatesMapping/edit/:id",
				name: "templatesMapping.edit",
				component: () => import("./vues/AddEdit.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			}
		]);
	}
};
