import { Menu } from "@/layouts/menu/core/Menu";
import Vue, { VueConstructor } from "vue";

const menu = new Menu();

Vue.use({
	install(constructor: VueConstructor) {
		Object.defineProperty(constructor.prototype, "$menu", {
			// tslint:disable-next-line
			get: function() {
				return this.$root.$options.menu;
			}
		});
	}
});

export default menu;
