import { Route, NavigationGuardNext } from "vue-router";

import AuditsResourceApi from "@/lib/apis/operations/AuditsResourceApi";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";
import router from "@/lib/router";
import vuex from "@/lib/vuex";
import menu from "@/lib/menu";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.audits": "Audits",
			"breadcrumb.audits.create": "Création d'audit",
			"breadcrumb.audits.get": "Page d'audit",
			"breadcrumb.audits.edit": "Edition d'un audit",
			"breadcrumb.audits.institutions": "Sélection d'établissement",
			"breadcrumb.audits.missing.files": "Fichiers manquants",
			"breadcrumb.audits.payslip.lines": "Lignes sans correspondance",
			"breadcrumb.audits.payslip.missing.data": "Données manquantes"
		});

		breadcrumb.add([
			{
				name: "audits",
				path: "/audits"
			},
			{
				name: "audits>audits.create",
				path: "/audits/create"
			},
			{
				name: "audits>audits.get>audits.edit",
				path: "/audits/([0-9]+)/edit",
				replacePath: "/audits/{1}/edit"
			},
			{
				name: "audits>audits.get",
				path: "/audits/([0-9]+)",
				replacePath: "/audits/{1}"
			},
			{
				name: "audits>audits.get>audits.institutions",
				path: "/audits/([0-9]+)/institutions",
				replacePath: "/audits/{1}/institutions"
			},
			{
				name: "audits>audits.get>audits.missing.files",
				path: "/audits/([0-9]+)/missing/files",
				replacePath: "/audits/{1}/missing/files"
			},
			{
				name: "audits>audits.get>audits.payslip.lines",
				path: "/audits/([0-9]+)/payslip/lines",
				replacePath: "/audits/{1}/payslip/lines"
			},
			{
				name: "audits>audits.get>audits.payslip.lines>audits.payslip.lines.view",
				path: "/audits/([0-9]+)/payslip/lines/(.*)",
				replacePath: "/audits/{1}/payslip/lines/{2}"
			},
			{
				name: "audits>audits.get>audits.payslip.missing.data",
				path: "/audits/([0-9]+)/payslip/missing/data",
				replacePath: "/audits/{1}/payslip/missing/data"
			}
		]);

		menu.addEntry({
			icon: "fa fa-clipboard-list",
			label: i18n.t("breadcrumb.audits") as string,
			location: { name: "audits" },
			order: 0
		});

		router.addRoutes([
			{
				path: "/",
				redirect: "/audits"
			},
			{
				path: "/audits",
				name: "audits",
				component: () => import("./vues/List.vue"),
				meta: {
					secured: true
				}
			},
			{
				path: "/audits/create",
				name: "audits.create",
				component: () => import("./vues/AddEdit.vue"),
				meta: {
					secured: true
				}
			},
			{
				path: "/audits/:id/edit",
				name: "audits.edit",
				component: () => import("./vues/AddEdit.vue"),
				props: true,
				meta: {
					secured: true
				}
			},
			{
				path: "/audits/:id",
				name: "audits.get",
				component: () => import("./vues/View.vue"),
				props: true,
				meta: {
					secured: true
				}
			},
			{
				path: "/audits/:id/institutions",
				name: "audits.institutions",
				component: () => import("./vues/steps/Institutions.vue"),
				props: true,
				meta: {
					secured: true
				}
			},
			{
				path: "/audits/:id/missing/files",
				name: "audits.missing.files",
				component: () => import("./vues/steps/MissingFiles.vue"),
				props: true,
				meta: {
					secured: true
				}
			},
			{
				path: "/audits/:id/payslip/lines",
				name: "audits.payslip.lines",
				component: () => import("./vues/steps/payslipLines/PaySlipLines.vue"),
				props: true,
				meta: {
					secured: true
				}
			},
			{
				path: "/audits/:id/payslip/lines/:label",
				name: "audits.payslip.lines.view",
				component: () => import("./vues/steps/payslipLines/PaySlipLineView.vue"),
				props: true,
				meta: {
					secured: true
				}
			},
			{
				path: "/audits/:id/payslip/missing/data",
				name: "audits.missing.data",
				component: () => import("./vues/steps/missingData/List.vue"),
				props: true,
				meta: {
					secured: true
				}
			}
		]);

		router.beforeEach(async (to: Route, _: Route, next: NavigationGuardNext<Vue>) => {
			if (!to.path.startsWith("/audits/") || to.name === "audits.get") {
				next();
				return;
			}

			if (to.params.id) {
				const audit = await AuditsResourceApi.get(parseInt(to.params.id, 10));
				vuex.commit("context/chooseAudit", audit);
			}

			next();
		});
	}
};
