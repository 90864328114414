import { NavigationGuardNext, Route } from "vue-router";

import vuex from "@/lib/vuex";
import { axios } from "@/lib/axios";
import storeVuex from "@/lib/vuex";
import router from "@/lib/router";
import message from "@/lib/message";
import i18n from "@/lib/i18n";
import AuditsResourceApi from "@/lib/apis/operations/AuditsResourceApi";

import store, { localStorageKey, prefixLocalStorageAudit, prefixLocalStorageYear } from "./store";
import { Context, AuditContext } from "./typings";

async function getAuditContextStored(): Promise<Context | undefined> {
	const contextStored = localStorage.getItem(localStorageKey);

	if (!contextStored) {
		return undefined;
	}

	if (contextStored.startsWith(prefixLocalStorageAudit)) {
		return new AuditContext(
			await AuditsResourceApi.get(parseInt(contextStored.substring(prefixLocalStorageAudit.length)))
		);
	} else if (contextStored.startsWith(prefixLocalStorageYear)) {
		return {
			year: parseInt(contextStored.substring(prefixLocalStorageYear.length))
		};
	}

	return undefined;
}

let contextLoaded = false;

export default {
	install() {
		if (axios.defaults.transformRequest instanceof Array) {
			axios.defaults.transformRequest.push((data: any, headers: any) => {
				const audit = storeVuex.getters["context/audit"];
				const year = storeVuex.getters["context/year"];

				if (audit) {
					headers["Audit-Context-Id"] = audit.id;
				}

				if (year) {
					headers["Audit-Context-Year"] = year;
				}

				return data;
			});
		}

		i18n.mergeLocaleMessage("fr", {
			"context.is.missing": "Un contexte est nécessaire pour accèder à cette page."
		});

		router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
			if (!to.meta || !to.meta.needContext) {
				next();
				return;
			}

			if (!contextLoaded) {
				storeVuex.state.context.selected = await getAuditContextStored();
				contextLoaded = true;
			}

			if (!storeVuex.state.context.selected) {
				message.warning(i18n.t("context.is.missing") as string);
				next("/audits");
				return;
			}

			next();
		});

		vuex.registerModule("context", store);
	}
};
export * from "./typings";
export { default as ContextSelector } from "./vues/ContextSelector.vue";
