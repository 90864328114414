import { Getter, Mutation, State } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { DataListOptions, DataListParams, DataListResponse } from "@emasofts/common-vuejs-datalist";

import { Cabinet } from "@/lib/keycloak/typings/EmaSoftsToken";
import { LoginState } from "@/lib/keycloak/typings/LoginState";

import { DataList as NekoDataList } from "@/components/dataList";

@Component({
	components: {
		NekoDataList
	}
})
export default class CabinetSelector extends Vue {
	@State("login")
	public login!: LoginState;

	@Getter("login/cabinets")
	public cabinets!: Cabinet[];

	@Mutation("login/setCabinet")
	public setCabinet!: (cabinet: Cabinet) => void;

	public cabinetsDataListOptions: DataListOptions = {
		callbackClickRow: (cabinet: Cabinet) => {
			this.setCabinet(cabinet);
		},
		displayLengthDefault: 10,
		refreshShow: false
	};

	public async getCabinetsData(params: DataListParams): Promise<DataListResponse<Cabinet>> {
		const data: Cabinet[] =
			this.cabinets
				.filter(cabinet => cabinet.name.toUpperCase().includes(params.search.toUpperCase()))
				.sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1)) || [];

		return {
			draw: params.draw,
			recordsTotal: data.length,
			data: data.slice(params.start, params.start + params.length)
		};
	}
}
