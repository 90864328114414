<template>
	<div class="details-value-view">
		<span class="details-value-content">
			<span
				v-if="detailsValue.source === 'DSN'"
				class="icon has-tooltip-right"
				:data-tooltip="$t('tooltip.source.dsn')"
			>
				<span class="fas fa-folder"></span>
			</span>

			<span
				v-else-if="detailsValue.source === 'PAY_SLIP'"
				class="icon has-tooltip-right"
				:data-tooltip="$t('tooltip.source.paySlip')"
			>
				<span class="fas fa-file-invoice"></span>
			</span>

			<span
				v-else-if="detailsValue.source === 'USER'"
				class="icon has-tooltip-right"
				:data-tooltip="$t('tooltip.source.user')"
			>
				<span class="fas fa-user-tie"></span>
			</span>

			<span
				v-else-if="detailsValue.source === 'MAPPING'"
				class="icon has-tooltip-right"
				:data-tooltip="$t('tooltip.source.mapping')"
			>
				<span class="fas fa-draw-polygon"></span>
			</span>

			<span
				v-if="isEmployeeDetailsValue(detailsValue) && detailsValue.previousMonth"
				:data-tooltip="$t('tooltip.previousMonth')"
				class="icon has-tooltip-right has-text-warning"
			>
				<span class="fas fa-exclamation-triangle" />
			</span>

			<span v-if="!detailsValue.reasonNotComplete" :title="detailsValue | readableDetailsValue">
				{{ detailsValue | readableDetailsValue }}
			</span>

			<span v-else class="tags has-addons">
				<span class="tag is-warning">
					{{ $t("not.complete") }}
				</span>
				<span class="tag is-light is-warning has-tooltip-right" :data-tooltip="detailsValue.reasonNotComplete">
					<span class="icon"><span class="fas fa-comment"></span></span>
				</span>
			</span>
		</span>

		<span
			v-if="copyDsn || copyPaySlip"
			class="details-value-actions"
			:class="showDate && 'mr-2'"
		>
			<button
				v-if="copyDsn"
				class="button is-small mb-0 has-tooltip-top has-tooltip-left"
				:data-tooltip="$t('tooltip.copyDsn', { value: detailsValue.dsnValue })"
				@click="$emit('copy', 'DSN', detailsValue)"
			>
				<span class="icon"><span class="fas fa-copy"></span></span>
				<span v-t="'action.copyDsn'"></span>
			</button>
			<button
				v-if="copyPaySlip"
				class="button is-small mb-0 has-tooltip-top has-tooltip-left"
				:data-tooltip="$t('tooltip.copyPaySlip', { value: detailsValue.paySlipValue })"
				@click="$emit('copy', 'PAY_SLIP', detailsValue)"
			>
				<span class="icon"><span class="fas fa-copy"></span></span>
				<span v-t="'action.copyPaySlip'"></span>
			</button>
		</span>

		<small
			v-if="showDate"
			class="details-value-date"
			v-t="{
				path: 'date.period',
				args: { date: $options.filters && $options.filters.date(detailsValue.datePeriod, { day: 'numeric', month: 'long' }) }
			}"
		></small>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { EmployeeDetailsValueRepresentation, InstitutionDetailsValueRepresentation } from "@/lib/apis/models";

type ModelDetails = EmployeeDetailsValueRepresentation | InstitutionDetailsValueRepresentation;

@Component
export default class DetailsValueView extends Vue {
	@Prop({ required: true })
	public readonly detailsValue!: ModelDetails;

	@Prop({ type: Boolean, default: true })
	public readonly showDate!: boolean;

	public get copyDsn(): boolean {
		return !!this.detailsValue.dsnValue && this.detailsValue.dsnValue !== this.detailsValue.value;
	}

	public get copyPaySlip(): boolean {
		return !!this.detailsValue.paySlipValue && this.detailsValue.paySlipValue !== this.detailsValue.value;
	}

	public isEmployeeDetailsValue(detailsValue: ModelDetails): detailsValue is EmployeeDetailsValueRepresentation {
		return (detailsValue as EmployeeDetailsValueRepresentation).previousMonth !== undefined;
	}
}
</script>

<i18n src="../../i18n/Details.json"></i18n>
