<template>
	<neko-field>
		<label class="checkbox">
			<button class="button is-small" type="button" @click.prevent="input && input.click()">
				<span class="icon">
					<canvas ref="canvas" width="1" height="1"></canvas>
				</span>
			</button>
			<input ref="input" type="color" :value="value" @input.prevent="onInput" />
			{{ label }}
		</label>
	</neko-field>
</template>

<script lang="ts">
import { HtmlInputForm } from "@emasofts/common-vuejs-form";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";

import NekoField from "./Field.vue";

@Component({
	components: {
		NekoField
	}
})
export default class Color extends HtmlInputForm {
	@Prop({ type: String })
	declare public value: string;

	@Ref()
	public readonly canvas?: HTMLCanvasElement;

	@Ref()
	public readonly input?: HTMLInputElement;

	public mounted(): void {
		this.onValueChanged(this.value);
	}

	public onInput(event: Event): void {
		const input = event.currentTarget as HTMLInputElement;
		this.$emit("input", input.value);
	}

	@Watch("value")
	public onValueChanged(value?: string): void {
		const context = this.canvas?.getContext("2d");

		if (!context) {
			return;
		}

		if (value) {
			context.fillStyle = value;
		} else if (this.input) {
			context.fillStyle = this.input.value;
		} else {
			context.fillStyle = "#000000";
		}

		context.fillRect(0, 0, 1, 1);
	}
}
</script>

<style lang="sass" scoped>
.checkbox
	position: relative

	.button
		vertical-align: middle

		.icon canvas
			border-radius: 1.5em
			height: 100%
			width: 100%

	input
		bottom: 0
		height: 0
		left: 0
		opacity: 0
		position: absolute
		width: 0
</style>
