import router from "@/lib/router";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.controls.consistency.global": "Contrôles de cohérence globale",
			"breadcrumb.controls.consistency.global.create": "Création de contrôles de cohérence globale",
			"breadcrumb.controls.consistency.global.edit": "Modification d'un contrôle de cohérence globale",
			"breadcrumb.controls.consistency.line": "Contrôles de cohérence de référentiel",
			"breadcrumb.controls.consistency.line.create": "Création de contrôles de cohérence de référentiel",
			"breadcrumb.controls.consistency.line.edit": "Modification d'un contrôle de cohérence de référentiel",
		});

		breadcrumb.add([
			{
				path: "/database/controls/consistency/global",
				name: "home>database>controls.consistency.global"
			},
			{
				path: "/database/controls/consistency/global/create",
				name: "home>database>controls.consistency.global>controls.consistency.global.create"
			},
			{
				path: "/database/controls/consistency/global/edit/([0-9]+)",
				name: "home>database>controls.consistency.global>controls.consistency.global.edit"
			},
			{
				path: "/database/controls/consistency/line",
				name: "home>database>controls.consistency.line"
			},
			{
				path: "/database/controls/consistency/line/create",
				name: "home>database>controls.consistency.line>controls.consistency.line.create"
			},
			{
				path: "/database/controls/consistency/line/edit/([0-9]+)",
				name: "home>database>controls.consistency.line>controls.consistency.line.edit"
			}
		]);

		router.addRoutes([
			{
				name: "controls.consistency.global",
				path: "/database/controls/consistency/global",
				component: () => import("./vues/GlobalConsistencyControlList.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.consistency.global.create",
				path: "/database/controls/consistency/global/create",
				component: () => import("./vues/GlobalConsistencyControlAdd.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.consistency.global.edit",
				path: "/database/controls/consistency/global/edit/:id(\\d+)",
				props: route => ({ id: parseInt(route.params.id, 10) }),
				component: () => import("./vues/GlobalConsistencyControlEdit.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.consistency.line",
				path: "/database/controls/consistency/line",
				component: () => import("./vues/LineConsistencyControlList.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.consistency.line.create",
				path: "/database/controls/consistency/line/create",
				component: () => import("./vues/LineConsistencyControlAdd.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				name: "controls.consistency.line.edit",
				path: "/database/controls/consistency/line/edit/:id(\\d+)",
				props: route => ({ id: parseInt(route.params.id, 10) }),
				component: () => import("./vues/LineConsistencyControlEdit.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			}
		]);
	}
};
