import { Component, Ref, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import { DataListOptions, DataListParams } from "@emasofts/common-vuejs-datalist";

import {
	InstitutionRepresentation,
	ListResponseReportRepresentation,
	ReportDetailsRepresentation,
	ReportRepresentation
} from "@/lib/apis/models";

import InstitutionsResourceApi from "@/lib/apis/operations/InstitutionsResourceApi";
import ReportsResourceApi from "@/lib/apis/operations/ReportsResourceApi";

import { DataList as NekoDataList } from "@/components/dataList";
import { DownloadButton as NekoDownloadButton } from "@/components/downloadButton";
import { AskModal as NekoAskModal } from "@/components/askModal";
import { PageTitle as NekoPageTitle } from "@/components/pageTitle";

import ErrorModal from "@/views/audits/vues/parts/reports/vues/ErrorModal.vue";
import { Mutation } from "vuex-class";
import { DeleteContext } from "@/components/contextSelector";

@Component({
	components: {
		ErrorModal,
		NekoAskModal,
		NekoDataList,
		NekoDownloadButton,
		NekoPageTitle
	}
})
export default class ReportList extends Vue {
	@Ref()
	public readonly dataList?: NekoDataList<ReportRepresentation>;

	@Ref()
	public readonly deleteModal?: NekoAskModal;

	@Ref()
	public readonly errorModal?: ErrorModal;

	public readonly deletingReports: number[] = [];
	public readonly institutions: InstitutionRepresentation[] = [];
	public readonly dataListOptions: DataListOptions = {
		columns: [
			{ name: this.$t("dataList.column.type").toString() },
			{ name: this.$t("dataList.column.institutions").toString() },
			{ name: this.$t("dataList.column.start").toString(), data: "startMonth", orderable: true },
			{ name: this.$t("dataList.column.end").toString(), data: "endMonth", orderable: true },
			{ name: this.$t("dataList.column.title").toString(), data: "title", orderable: true },
			{ name: this.$t("dataList.column.subject").toString(), data: "label", orderable: true },
			{ name: this.$t("dataList.column.multiReport").toString() },
			{ name: this.$t("dataList.column.dateCreated").toString(), data: "dateCreated", orderable: true, dir: "desc" },
			{ name: this.$t("dataList.column.report").toString() },
			{ name: this.$t("dataList.column.details").toString() },
			{ name: " " }
		],
		displayLengthDefault: 20,
		filters: [
			{
				data: "type",
				name: this.$t("dataList.column.type").toString(),
				value: [],
				multiple: true,
				options: [
					{ name: this.$t("dataList.filter.type.audit").toString(), value: "AUDIT" },
					{ name: this.$t("dataList.filter.type.institution").toString(), value: "INSTITUTION" }
				]
			},
			{
				data: "institutions",
				name: this.$t("dataList.column.institutions").toString(),
				value: [],
				multiple: true,
				options: []
			}
		],
		select: true
	};
	public loadingDetails: number[] = [];
	public loadingInstitutions = false;
	public loadingReports: number[] = [];

	@Mutation("context/deleteContext")
	private readonly deleteContext!: DeleteContext;

	private puller: number = 0;

	public get institutionById(): InstitutionById {
		return this.institutions.reduce<InstitutionById>(
			(result, institution) => ({
				...result,
				[institution.id]: institution
			}),
			{}
		);
	}

	//#region Lifecycle hooks
	public created() {
		this.pageTitle = this.$t("breadcrumb.reports") as string;
		this.deleteContext();
	}

	public async mounted(): Promise<void> {
		await this.fetchInstitutions();
	}

	public destroyed(): void {
		if (this.puller) {
			clearTimeout(this.puller);
			this.puller = 0;
		}
	}
	//#endregion

	public async createDetails(report: ReportRepresentation): Promise<void> {
		try {
			if (!this.loadingDetails.some(id => id === report.id)) {
				this.loadingDetails.push(report.id);
			}

			await ReportsResourceApi.createDetails(report.id);
		} finally {
			const index = this.loadingDetails.indexOf(report.id);

			if (index > -1) {
				this.loadingDetails.splice(index, 1);
			}
		}

		this.dataList?.refresh();
	}

	public async deleteReports(reports: ReportRepresentation[]): Promise<void> {
		const ids = reports.map(report => report.id);

		try {
			this.deletingReports.push(...ids);
			await ReportsResourceApi.bulkDelete(ids);
		} finally {
			for (let index = this.deletingReports.length - 1; index >= 0; index--) {
				if (ids.some(id => id === this.deletingReports[index])) {
					this.deletingReports.splice(index, 1);
				}
			}

			this.dataList?.refresh();
		}
	}

	public download(id: number): () => Promise<AxiosResponse<any>> {
		return () => ReportsResourceApi.getWrap(id, { responseType: "blob" });
	}

	public downloadDetails(id: number): () => Promise<AxiosResponse<any>> {
		return () => ReportsResourceApi.getDetailsWrap(id, { responseType: "blob" });
	}

	public formatYearMonth(yearMonth: string): string {
		const date = new Date(`${yearMonth}-01`);
		const options: Intl.DateTimeFormatOptions = { month: "long", year: "numeric" };
		return this.$options.filters?.date(date, options) || yearMonth;
	}

	public getCellClass(report: ReportRepresentation, detailsOnly?: boolean): string | undefined {
		const deleting = this.isDeleting(report);
		const errored = this.isError(report) || (!!detailsOnly && this.isError(report.details));

		if (deleting && errored) {
			return "has-background-white has-text-danger-light";
		} else if (deleting) {
			return "has-text-grey-light";
		} else if (errored) {
			return "has-background-danger-light has-text-danger";
		}
	}

	public async getData(params: DataListParams): Promise<ListResponseReportRepresentation> {
		const response = await ReportsResourceApi.list(
			params.draw,
			params.start,
			params.length,
			params.search,
			params.order,
			params.filter
		);

		const institutionIds: number[] = [];
		let shouldPullReports = false;
		let shouldPullDetails = false;

		response.data.forEach(report => {
			report.institutions.forEach(institution => {
				if (!institutionIds.some(id => id === institution)) {
					institutionIds.push(institution);
				}
			});

			if (!shouldPullReports && (this.isReady(report) || this.isCreating(report))) {
				shouldPullReports = true;
			}

			if (!shouldPullDetails && (this.isReady(report.details) || this.isCreating(report.details))) {
				shouldPullDetails = true;
			}
		});

		if (shouldPullReports || shouldPullDetails) {
			this.puller = setTimeout(() => this.dataList?.refresh(), 2000);
		}

		return response;
	}

	public getTagClass(report: ReportRepresentation): string | undefined {
		const deleting = this.isDeleting(report);
		const errored = this.isError(report);

		if (deleting && errored) {
			return "is-danger is-light";
		} else if (deleting) {
			return "has-text-grey-light is-light";
		} else if (errored) {
			return "is-danger";
		}
	}

	public isCreating(element: ReportRepresentation | ReportDetailsRepresentation | null): boolean {
		return !!element && element.status === "CREATING";
	}

	public isDeleting(report: ReportRepresentation): boolean {
		return this.deletingReports.some(id => id === report.id);
	}

	public isError(element: ReportRepresentation | ReportDetailsRepresentation | null): boolean {
		return !!element && element.status === "ERROR";
	}

	public isFinished(element: ReportRepresentation | ReportDetailsRepresentation | null): boolean {
		return !!element && element.status === "FINISHED";
	}

	public isLoading(report: ReportRepresentation): boolean {
		return this.loadingReports.some(id => id === report.id);
	}

	public isLoadingDetails(report: ReportRepresentation): boolean {
		return this.loadingDetails.some(id => id === report.id);
	}

	public isReady(element: ReportRepresentation | ReportDetailsRepresentation | null): boolean {
		return !!element && element.status === "READY";
	}

	public async recreate(element: ReportRepresentation): Promise<void> {
		try {
			if (!this.loadingReports.some(id => id === element.id)) {
				this.loadingReports.push(element.id);
			}

			await ReportsResourceApi.recreate(element.id);
		} finally {
			const index = this.loadingReports.indexOf(element.id);

			if (index > -1) {
				this.loadingReports.splice(index, 1);
			}
		}

		this.dataList?.refresh();
	}

	private async fetchInstitutions(): Promise<void> {
		try {
			this.loadingInstitutions = true;

			const institutions = await InstitutionsResourceApi.listAll();

			this.institutions.splice(0);

			const institutionsFilter = this.dataList?.findFilters("institutions");

			if (institutionsFilter?.options) {
				institutionsFilter.options.splice(0);
			}

			institutions
				.sort((a, b) => {
					if (a.name && b.name) {
						return a.name.localeCompare(b.name);
					} else if (a.name) {
						return -1;
					} else if (b.name) {
						return 1;
					} else {
						return a.siret.localeCompare(b.siret);
					}
				})
				.forEach(institution => {
					this.institutions.push(institution);

					if (institutionsFilter?.options) {
						const name = this.$options.filters?.institution(institution);
						institutionsFilter.options.push({ name, value: institution.id });
					}
				});
		} finally {
			this.loadingInstitutions = false;
		}
	}
}

interface InstitutionById {
	[id: number]: InstitutionRepresentation;
}
