import Vue from "vue";
import { UserRepresentation } from "@/lib/apis/models";
import i18n from "@/lib/i18n";

i18n.mergeLocaleMessage("fr", {
	"user.deleted": "Utilisateur supprimé"
});

function filter(user: UserRepresentation) {
	if (!user) {
		return i18n.t("user.deleted");
	}

	let name = user.username;

	if (user.firstName || user.lastName) {
		name = user.firstName || "";
		name += " " + user.lastName || "";
	}

	return name;
}

export default {
	install() {
		Vue.filter("user", filter);
	}
};

export { filter };
