import router from "@/lib/router";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";
import menu from "@/lib/menu";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.cabinet.preferences": "Préférences de mon cabinet",
			"menu.cabinet": "Mon cabinet"
		});
		breadcrumb.add([
			{
				path: "/cabinetPreferences",
				name: "home>cabinet.preferences"
			}
		]);

		router.addRoutes([
			{
				path: "/cabinetPreferences",
				name: "cabinet.preferences",
				component: () => import("./vues/Index.vue"),
				meta: {
					secured: true,
					roles: ["USER"]
				}
			}
		]);

		menu.addEntry({
			label: i18n.t("menu.cabinet") as string,
			icon: "fa fa-sliders-h",
			location: { name: "cabinet.preferences" },
			roles: ["USER"],
			order: 5
		});
	}
};
