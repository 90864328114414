import { PluginObject } from "vue";
import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";
import router from "@/lib/router";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.controls.dsn.definitions": "Contrôles de syntaxe de DSN",
			"breadcrumb.controls.dsn.definitions.fields.view": "Définition de rubrique de DSN",
			"breadcrumb.controls.dsn.definitions.view": "Définition de DSN",
		});

		breadcrumb.add([
			{
				path: "/database/controls/dsn/definitions",
				name: "home>database>controls.dsn.definitions"
			},
			{
				path: "/database/controls/dsn/definitions/([0-9]+)",
				replacePath: "/database/controls/dsn/definitions/{1}",
				name: "home>database>controls.dsn.definitions>controls.dsn.definitions.view"
			},
			{
				path: "/database/controls/dsn/definitions/([0-9]+)/(S[0-9]{2}.G[0-9]{2}.[0-9]{2}).([0-9]{3})",
				name: "home>database>controls.dsn.definitions>controls.dsn.definitions.view>controls.dsn.definitions.fields.view"
			}
		]);

		router.addRoutes([
			{
				path: "/database/controls/dsn/definitions",
				name: "controls.dsn.definitions",
				component: () => import("./vues/DsnDefinitionList.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/controls/dsn/definitions/:id(\\d+)",
				name: "controls.dsn.definitions.view",
				props: route => ({ id: parseInt(route.params.id, 10) }),
				component: () => import("./vues/DsnDefinitionView.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/database/controls/dsn/definitions/:dsnDefinitionId(\\d+)/:blockCode(S\\d{2}.G\\d{2}.\\d{2}).:fieldCode(\\d{3})",
				name: "controls.dsn.definitions.fields.view",
				props: route => ({
					dsnDefinitionId: parseInt(route.params.dsnDefinitionId, 10),
					blockCode: route.params.blockCode,
					fieldCode: route.params.fieldCode
				}),
				component: () => import("./vues/DsnFieldDefinitionView.vue"),
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			}
		]);
	}
};
