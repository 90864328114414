import { VueConstructor } from "vue";
import VueI18n from "vue-i18n";
import * as _ from "lodash";

import rootVueI18n from "@/lib/i18n";

const i18n = new VueI18n({
	messages: {
		fr: {
			"readable.boolean.true": "Oui",
			"readable.boolean.false": "Non"
		}
	}
});

function filter(
	value: any,
	type: "boolean" | "number" | "numeric" | null = null,
	defaultValue: string | null = null
): string {
	let valueAsString = value;

	if (type === "boolean") {
		value = Boolean(value);
	} else if (type === "number" || type === "numeric") {
		value = parseFloat(value);
	}

	if ("boolean" === typeof value || value instanceof Boolean) {
		valueAsString = i18n.t("readable.boolean." + value, rootVueI18n.locale) as string;
	} else if ("number" === typeof value) {
		valueAsString = "" + value;
	} else if ("object" === typeof value) {
		valueAsString = _.entries(value)
			.map(entry => entry.join(": "))
			.join(", ");
	}

	if (!valueAsString && defaultValue) {
		valueAsString = defaultValue;
	}

	return valueAsString;
}

export default {
	install(Vue: VueConstructor) {
		Vue.filter("readable", filter);
	}
};

export { filter };
