import { AuditParams, AuditRepresentation } from "@/lib/apis/models";
import { AuditStatus, Warning } from "../typings";

export function createParam(audit: AuditRepresentation): AuditParams {
	return {
		clientId: audit.clientId,
		closed: audit.closed,
		name: audit.name,
		type: audit.type,
		periodStart: audit.periodStart ?? "",
		currentCycleStart: audit.currentCycleStart,
		cycleLength: audit.cycleLength,
		status: audit.status,
		recurring: audit.recurring,
		dateRestored: audit.dateRestored,
		comment: audit.comment,
		estimationPaySlipMonthlyBilled: audit.estimationPaySlipMonthlyBilled
	};
}

export function getWarnings(audit: AuditRepresentation) {
	if (!audit) {
		return [];
	}

	const warnings: Warning[] = [];
	const auditStatus = AuditStatus[audit.status];

	if (auditStatus < AuditStatus.RESTORABLE && audit.institutions.length === 0) {
		warnings.push(Warning.MissingInstitutions);
	}

	if (auditStatus >= AuditStatus.WAITING_FILES && auditStatus < AuditStatus.RESTORABLE && audit.missingFiles) {
		warnings.push(Warning.MissingFiles);
	}

	if (
		auditStatus >= AuditStatus.WAITING_MAPPING &&
		auditStatus < AuditStatus.RESTORABLE &&
		audit.missingMappings
	) {
		warnings.push(Warning.MissingMapping);
	}

	if (
		auditStatus >= AuditStatus.WAITING_MISSING_DATA &&
		auditStatus < AuditStatus.RESTORABLE &&
		audit.missingDatas
	) {
		warnings.push(Warning.MissingData);
	}

	if (auditStatus >= AuditStatus.IN_PROGRESS && auditStatus <= AuditStatus.RESTORABLE) {
		if (audit.havePaySlipInErrorNotVisited) {
			warnings.push(Warning.HavePaySlipInErrorNotVisited);
		}

		if (audit.havePaySlipMissingData) {
			warnings.push(Warning.HavePaySlipMissingData);
		}
	}

	if (auditStatus >= AuditStatus.RESTORABLE) {
		if (audit.cycleLength > 1 && !audit.dateRestored) {
			warnings.push(Warning.MissingDateRestored);
		}

		if (!audit.comment) {
			warnings.push(Warning.MissingComment);
		}

		if (audit.missingReports) {
			warnings.push(Warning.MissingReports);
		}
	}

	return warnings;
}