/* tslint:disable */

import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { axios } from "@/lib/axios";
import { keycloak } from "@/lib/keycloak";
import message from "@/lib/message";
import i18n from "@/lib/i18n";
import router from "@/lib/router";
import app from "@/main";


import {
	TestFormulaParams
} from "../models";

class FormulasResourceApi { 
	public async test(
		body: TestFormulaParams,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<void> {
		return (await this.testWrap(body, requestConfig, errorManagement)).data;
	}

	public async testWrap(
		body: TestFormulaParams,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<void>> {
		let url = "/formulas/test";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		try {
			return await axios.post(url, body, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

}
export default new FormulasResourceApi();
