import { AxiosRequestConfig } from "axios";
import Keycloak from "keycloak-js";

import BaseEndpoint from "./BaseEndpoint";
import { GroupRepresentation } from "../models/GroupRepresentation";

export default class GroupsEndpoint extends BaseEndpoint {
	protected readonly url: string = "/groups";

	constructor(keycloak: Keycloak) {
		super(keycloak);
	}

	public async search(query: string): Promise<GroupRepresentation[]> {
		const config: AxiosRequestConfig = {};

		if (query) {
			config.params = {
				q: query
			};
		}

		return (await this.axios.get(this.url, config)).data;
	}

	public async get(id: string): Promise<GroupRepresentation> {
		const url = `${this.url}/${id}`;
		return (await this.axios.get(url)).data;
	}
}
