<i18n src="../i18n/Submit.json"></i18n>

<template>
	<div class="field is-grouped">
		<div class="control">
			<button
				@click="onClick($event)"
				class="button is-primary"
				:class="{ 'is-loading': sending }"
				:disabled="disabled || sending"
				:form="form"
			>
				<slot>{{ value }}</slot>
			</button>
		</div>

		<div class="control">
			<input v-if="reset" @click="onClick($event)" type="reset" :value="$t('reset')" class="button" />
		</div>
	</div>
</template>

<script lang="ts">
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { SubmitComponent } from "@emasofts/common-vuejs-form";

@Component
export default class Submit extends SubmitComponent {
	@Prop({
		default: false
	})
	private readonly disabled!: boolean;
}
</script>


