import Vue from "vue";

/**
 * Retourne la date et/ou l'heure sous forme de chaîne de caractères formatée selon la valeur du paramètre `format`.
 *
 * @param value La date en tant qu'objet {@link Date}, en millisecondes depuis le 1<sup>er</sup> janvier 1970 (époque
 * ECMAScript) ou sous forme de chaîne de caractères reconnue par la méthode {@link Date#parse()}.
 * @param format Le format de date attendu
 *
 * @example
 * filter(null);                                  // "-"
 * filter(517795200000, "date");                  // "30/05/1986"
 * filter(517795200000, "datetime");              // "30/05/1986, 02:00:00"
 * filter(517795200000, "time");                  // "02:00:00 GMT+0200 (heure d’été d’Europe centrale)"
 * filter(517795200000, "isoshort");              // "1986-05-30"
 * filter(517795200000, "friendly");              // "Il y a 35 ans"
 * filter(517795200000, { dateStyle: "medium" }); // "30 mai 1986"
 */
function filter(
	value: number | string | Date | null,
	format: "date" | "datetime" | "isoshort" | "time" | "friendly" | "yearmonth" | Intl.DateTimeFormatOptions = "date"
): string {
	if (!value) {
		return "-";
	}

	const date = new Date(value);
	let formattingDate;

	switch (format) {
		case "date":
			formattingDate = date.toLocaleDateString();
			break;

		case "datetime":
			formattingDate = date.toLocaleString();
			break;

		case "time":
			formattingDate = date.toTimeString();
			break;

		case "isoshort":
			formattingDate = date.toISOString().substr(0, 10);
			break;

		case "friendly":
			const now = new Date();
			const yearsAgo = new Date(now);
			yearsAgo.setFullYear(yearsAgo.getFullYear() - 1);

			let years = 0;

			while (yearsAgo.getTime() > date.getTime()) {
				yearsAgo.setFullYear(yearsAgo.getFullYear() - 1);
				years++;
			}

			formattingDate = years >= 2 ? `Il y a ${years} ans` : "L'année dernière";

			if (date.toLocaleDateString() === yearsAgo.toLocaleDateString()) {
				formattingDate = `🎂 ${formattingDate}`;
			} else if (years === 0) {
				const monthsAgo = new Date(now);
				monthsAgo.setMonth(monthsAgo.getMonth() - 1);

				let months = 0;

				while (monthsAgo.getTime() > date.getTime()) {
					monthsAgo.setMonth(monthsAgo.getMonth() - 1);
					months++;
				}

				formattingDate = months >= 6 ? "Il y a plus de 6 mois" : `Il y a ${months} mois`;

				if (months <= 1) {
					const seconds = Math.round((now.getTime() - date.getTime()) / 1000);

					if (seconds < 1) {
						formattingDate = "À l'instant";
					} else {
						const minutes = Math.round(seconds / 60);
						const hours = Math.round(minutes / 60);
						const days = Math.round(hours / 24);
						const weeks = Math.round(days / 7);

						formattingDate =
							seconds < 2
								? "Il y a une seconde"
								: seconds <= 30
								? `Il y a ${seconds} secondes`
								: seconds < 60
								? "Il y a moins d'une minute"
								: minutes < 2
								? "Il y a une minute"
								: minutes <= 30
								? `Il y a ${minutes} minutes`
								: minutes < 60
								? "Il y a moins d'une heure"
								: hours < 2
								? "Il y a une heure"
								: hours <= 12
								? `Il y a ${hours} heures`
								: hours < 24
								? "Il y a moins d'un jour"
								: days < 2
								? "Hier"
								: days <= 3
								? `Il y a ${days} jours`
								: days < 7
								? "Il y a moins d'une semaine"
								: weeks < 2
								? "La semaine dernière"
								: weeks <= 3
								? `Il y a ${weeks} semaines`
								: months < 1
								? "Il y a moins d'un mois"
								: "Le mois dernier";
					}
				}
			}

			break;

		case "yearmonth":
			const month = ("0" + (date.getMonth() + 1)).slice(-2);
			formattingDate = `${date.getFullYear()}-${month}`;
			break;

		default:
			formattingDate = date.toLocaleDateString([], format);
	}

	return formattingDate;
}

export default {
	install() {
		Vue.filter("date", filter);
	}
};
export { filter };
