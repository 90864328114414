import { VueConstructor } from "vue";

function romanCharacterToInt(r: string) {
	if (r === "I") {
		return 1;
	}
	if (r === "V") {
		return 5;
	}
	if (r === "X") {
		return 10;
	}
	if (r === "L") {
		return 50;
	}
	if (r === "C") {
		return 100;
	}
	if (r === "D") {
		return 500;
	}
	if (r === "M") {
		return 1000;
	}
	return -1;
}

// Finds decimal value of a given romal numeral
function romanToDecimal(str: string) {
	// Initialize result
	let res = 0;

	for (let i = 0; i < str.length; i++) {
		// Getting value of symbol s[i]
		const s1 = romanCharacterToInt(str.charAt(i));

		// Getting value of symbol s[i+1]
		if (i + 1 < str.length) {
			const s2 = romanCharacterToInt(str.charAt(i + 1));

			// Comparing both values
			if (s1 >= s2) {
				// Value of current symbol is greater
				// or equalto the next symbol
				res = res + s1;
			} else {
				res = res + s2 - s1;
				i++; // Value of current symbol is
				// less than the next symbol
			}
		} else {
			res = res + s1;
			i++;
		}
	}

	return res;
}

function decimalToRoman(num: number) {
	const m: { [key: number]: string } = { 1: "I", 5: "V", 10: "X", 50: "L", 100: "C", 500: "D", 1000: "M" };
	const numDigits = Math.log(num) + 1;
	let numeral = "";

	for (let i = 0; i < numDigits; i++) {
		const place = Math.pow(10, numDigits - i - 1);
		const firstNum = num / place;

		if (firstNum === 4) {
			numeral += m[place];
			numeral += m[place * 5];
		} else if (firstNum === 9) {
			numeral += m[place];
			numeral += m[place * 10];
		} else {
			if (firstNum > 4) {
				numeral += m[place * 5];
			}
			for (let j = 0; j < firstNum % 5; j++) {
				numeral += m[place];
			}
		}
		num -= firstNum * place;
	}
	return numeral;
}

function filter(value: string | number) {
	if ("string" === typeof value) {
		return romanToDecimal(value);
	}
}

export default {
	install(Vue: VueConstructor) {
		Vue.filter("romanInt", filter);
	}
};

export { romanToDecimal };
