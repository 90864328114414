<template functional>
	<a
		v-if="props.coordinates"
		:href="`https://www.openstreetmap.org/#map=16/${props.coordinates.x}/${props.coordinates.y}`"
		target="_blank"
	>
		<span class="icon"><span class="fas fa-external-link-alt" /></span>
		{{ props.coordinates | props.coordinates }}
	</a>
</template>

<script lang="ts">
import { CoordinatesRepresentation } from "@/lib/apis/models";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Coordinates extends Vue {
	@Prop({ required: true })
	private readonly coordinates!: CoordinatesRepresentation;
}
</script>
