import breadcrumb from "@/lib/breadcrumb";
import i18n from "@/lib/i18n";
import router from "@/lib/router";

import AddEdit from "./vues/AddEdit.vue";
import List from "./vues/List.vue";

export default {
	install() {
		i18n.mergeLocaleMessage("fr", {
			"breadcrumb.controlExcludeProfiles": "Profils d'analyse",
			"breadcrumb.controlExcludeProfiles.create": "Création d'un profil d'analyse",
			"breadcrumb.controlExcludeProfiles.edit": "Edition d'un profil d'analyse"
		});

		breadcrumb.add([
			{
				path: "/controlExcludeProfiles",
				name: "home>database>controlExcludeProfiles"
			},
			{
				path: "/controlExcludeProfiles/create",
				name: "home>database>controlExcludeProfiles>controlExcludeProfiles.create"
			},
			{
				path: "/controlExcludeProfiles/([0-9]+)/edit",
				replacePath: "/controlExcludeProfiles/{1}",
				name: "home>database>controlExcludeProfiles>controlExcludeProfiles.edit"
			}
		]);

		router.addRoutes([
			{
				path: "/controlExcludeProfiles",
				name: "controlExcludeProfiles",
				component: List,
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/controlExcludeProfiles/:id/edit",
				name: "controlExcludeProfiles.edit",
				component: AddEdit,
				props: true,
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			},
			{
				path: "/controlExcludeProfiles/create",
				name: "controlExcludeProfiles.create",
				component: AddEdit,
				props: true,
				meta: {
					secured: true,
					roles: ["DATABASE_ADMINISTRATOR"]
				}
			}
		]);
	}
};
