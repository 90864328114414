/* tslint:disable */

import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { axios } from "@/lib/axios";
import { keycloak } from "@/lib/keycloak";
import message from "@/lib/message";
import i18n from "@/lib/i18n";
import router from "@/lib/router";
import app from "@/main";


import {
	ListResponseReferentialPayRepresentation,
	ReferentialPayRepresentation,
	ReferentialPayParam,
	ReferentialPaySimpleRepresentation
} from "../models";

class ReferentialPayResourceApi { 
	public async list(
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ListResponseReferentialPayRepresentation> {
		return (await this.listWrap(draw, start, length, search, order, filter, requestConfig, errorManagement)).data;
	}

	public async listWrap(
		draw: number | null /* Format : int32 */,
		start: number | null /* Format : int32 */,
		length: number | null /* Format : int32 */,
		search: string | null,
		order: string[] | null,
		filter: string[] | null,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ListResponseReferentialPayRepresentation>> {
		let url = "/referentialPay";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";

		const params: {[key: string] : any} = {
			'draw': draw,
			'start': start,
			'length': length,
			'search': search,
			'order[]': order,
			'filter[]': filter
		};

		for (const key in params) {
			if (params.hasOwnProperty(key) && key.search(/\[\]/) !== -1) {
				params[key.replace(/\[|\]/gi, "")] = params[key];
				delete params[key];
			}
		}
		requestConfig.params = requestConfig.params || params;
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async create(
		body: ReferentialPayParam,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ReferentialPayRepresentation> {
		return (await this.createWrap(body, requestConfig, errorManagement)).data;
	}

	public async createWrap(
		body: ReferentialPayParam,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ReferentialPayRepresentation>> {
		let url = "/referentialPay";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		try {
			return await axios.post(url, body, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async get(
		id: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ReferentialPayRepresentation> {
		return (await this.getWrap(id, requestConfig, errorManagement)).data;
	}

	public async getWrap(
		id: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ReferentialPayRepresentation>> {
		let url = "/referentialPay/{id}";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async update(
		id: number /* Format : int64 */,
		body: ReferentialPayParam,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ReferentialPayRepresentation> {
		return (await this.updateWrap(id, body, requestConfig, errorManagement)).data;
	}

	public async updateWrap(
		id: number /* Format : int64 */,
		body: ReferentialPayParam,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ReferentialPayRepresentation>> {
		let url = "/referentialPay/{id}";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		url = url.replace("{id}", id.toString());
		try {
			return await axios.put(url, body, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async delete(
		id: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<void> {
		return (await this.deleteWrap(id, requestConfig, errorManagement)).data;
	}

	public async deleteWrap(
		id: number /* Format : int64 */,
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<void>> {
		let url = "/referentialPay/{id}";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		url = url.replace("{id}", id.toString());
		try {
			return await axios.delete(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async listAll(
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ReferentialPayRepresentation[]> {
		return (await this.listAllWrap(requestConfig, errorManagement)).data;
	}

	public async listAllWrap(
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ReferentialPayRepresentation[]>> {
		let url = "/referentialPay/all";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async listAllWithArchived(
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ReferentialPayRepresentation[]> {
		return (await this.listAllWithArchivedWrap(requestConfig, errorManagement)).data;
	}

	public async listAllWithArchivedWrap(
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ReferentialPayRepresentation[]>> {
		let url = "/referentialPay/allWithArchived";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async listById(
		body: number /* Format : int64 */[],
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<ReferentialPaySimpleRepresentation[]> {
		return (await this.listByIdWrap(body, requestConfig, errorManagement)).data;
	}

	public async listByIdWrap(
		body: number /* Format : int64 */[],
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<ReferentialPaySimpleRepresentation[]>> {
		let url = "/referentialPay/byId";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		try {
			return await axios.post(url, body, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

	public async listCollectingOrganizations(
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<string[]> {
		return (await this.listCollectingOrganizationsWrap(requestConfig, errorManagement)).data;
	}

	public async listCollectingOrganizationsWrap(
		requestConfig: AxiosRequestConfig = {},
		errorManagement: 'redirect' | 'notification' | 'none' = 'notification'
	): Promise<AxiosResponse<string[]>> {
		let url = "/referentialPay/collectingOrganizations";
		requestConfig = requestConfig || {};
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers.Authorization = "Bearer " + keycloak.token;
		requestConfig.headers.accept = "application/json";
		try {
			return await axios.get(url, requestConfig)
		} catch (error) {
			const axiosError = error as AxiosError;
			app.$Progress.fail();
			if (errorManagement !== 'none') {
				if (axiosError.message === "Network Error") {
					message.error(i18n.t("request.network.error") as string);
				}
				if (!axiosError.response) {
					message.error(i18n.t("request.no.response") as string);
				} else if (errorManagement === 'redirect') {
					if (axiosError.response.status === 403) {
						router.replace("/errors/notAuthorized");
					}
					else if (axiosError.response.status === 404) {
						router.replace("/errors/notFound");
					}
					else if (axiosError.response.status === 500) {
						router.replace("/errors/internalError");
					}
				} else if (errorManagement === 'notification') {
					if (axiosError.response.status === 403) {
						message.error(i18n.t("request.not.authorized") as string);
					}
					else if (axiosError.response.status === 404) {
						message.error(i18n.t("request.not.found") as string);
					}
					else if (axiosError.response.status === 400) {
						message.error(i18n.t("request.bad.request") as string);
					}
					else if (axiosError.response.status === 500) {
						message.error(i18n.t("request.server.error") as string);
					}
				}
			}
			throw axiosError.response?.data;
		}
	}

}
export default new ReferentialPayResourceApi();
