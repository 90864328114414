import * as _ from "lodash";

import { MenuEntry } from "../typings";

export class Menu {
	private entries: { [key: string]: MenuEntry } = {};

	public addEntry(entry: MenuEntry) {
		if (this.entries.hasOwnProperty(entry.label)) {
			// tslint:disable-next-line
			console.warn(`Entry with label ${entry.label} already exists. The old one was removed.`);
		}

		this.entries[entry.label] = entry;
	}

	public getEntries() {
		return _.values(this.entries);
	}
}
